export type NotifPriority = ValueUnion<typeof NotifPriority>
export const NotifPriority = { HIGH: 10, LOW: 1 } as const

// NOTE: Currently not in use, so excluding
//export type NotifsIcon = ValueUnion<typeof NotifsIcon>
//export const NotifsIcon = {
//	EXLAM_INFO: 11,
//	EXLAM_WARN: 12,
//	EXLAM_ALERT: 13,
//	QUEST_INFO: 21,
//	QUEST_WARN: 22,
//	QUEST_ALERT: 23,
//	UPDATE_INFO: 31,
//	UPDATE_WARN: 32,
//	UPDATE_ALERT: 33,
//	APP_INSTALLED: 51,
//	GEOFENCE_ENTRY: 61,
//	GEOFENCE_EXIT: 62,
//} as const

export type RawNotif = {
	readonly account_id: string
	readonly content: RawContentNotif
	readonly details: NotifDetails
	readonly notif_id: string
	readonly notif_ts: number
	readonly type: NotifType
}

/**
 * Notification updates can:
 *
 * - Mark as 'read'. Currently has no meaning, but in the future
 *     this will probably involve change the appearance of read vs unread
 *     notifications
 *
 * - Mark as 'shown'. Notifications that are 'shown' do not contribute
 *     to the notification count in the alert bubble.
 *
 * - Mark as 'hidden'. Hidden notifications are basically 'dismissed', and will
 *     not be returned from future calls. This will probably never be used by
 *     clients because the action of dismissing a notification will end up being
 *     a delete call. Notifications that have follow up on the server side are
 *     marked as hidden until the follow up is performed, and they are filtered
 *     out from the client requests.
 */
export type NotifUpdate = {
	readonly action?: string
	readonly hidden?: boolean
	readonly notif_id: string
	readonly read?: boolean
	readonly shown?: boolean
}

type NotifUpdateActionRes = {
	readonly res: NotifActionRes
	readonly status: 'ERROR' | 'OK' | 'UNKNOWN'
}

export type NotifUpdateRes = {
	readonly action_res: NotifUpdateActionRes
	readonly flag_updated?: boolean
	readonly notif_id: string
}

export type NotifAction = {
	readonly id: string
	readonly label: string
	readonly type: string // Enum later, when more values exist
}

/**
 * Arbitrary content based on the notification type.
 * NOTE: Notification content is already localized / translated
 */
export type RawContentNotif = {
	// Actionable notifications (required, optional) are separated to the top
	readonly actionable: NotifActionable

	// Actions are based on type
	readonly actions: Maybe<NotifAction[]>

	// Detail refers to the detail view, ie, when the notification is clicked
	readonly detail_title: Maybe<string>
	readonly detail_subtitle: Maybe<string>
	readonly detail_body: Maybe<string>
	readonly fallback_type: Maybe<NotifType>

	// Enum that represents the icon and style modifier that are to be shown
	// NOTE: Currently not in use, so commented out
	//readonly icon: NotifsIcon

	// List refers to the list view, ie, when the notification bubble clicked
	readonly list_title: Maybe<string>
	readonly list_subtitle: Maybe<string>
	readonly list_datetime: string

	readonly priority: NotifPriority
}

/**
 * Normalized (non-raw) content to conform to interface
 */
export type NotifContent = RawContentNotif & {
	readonly detail_title: string
	readonly detail_subtitle: string
	readonly detail_body: string
	readonly list_title: string
	readonly list_subtitle: string
}

export type ActionableNotifContent = NotifContent & {
	readonly actions: NotifAction[]
}

/**
 * Details, many of which change based on the the notification type
 * However, all should have these properties (although they may be null)
 */
export type NotifDetails = {
	readonly hidden: Maybe<boolean>
	readonly read_ts: Maybe<number>
	readonly shown_ts: Maybe<number>
}

/**
 * Enum of actionable states. Many notifications are just 'informational',
 * and thus aren't actionable (e.g. geofence_entry). But some may require
 * actions to be taken, even if it's just acknowledgment (e.g. 'device_unmanaged')
 */
export type NotifActionable =
	/* Action was completed*/
	'done' | /* Not actionable */ 'no' | 'optional' | 'required'

/**
 * Currently defined notification types. The type will determine things like
 * whether a notification is actionable, it's content, and it's TTL.
 */
export type NotifType =
	| 'android_perm_required'
	| 'android_update_available'
	| 'android_update_required'
	| 'android_update_required_osq'
	| 'app_installed'
	| 'app_permissions_announce'
	| 'apporg_info'
	| 'apporg_promo'
	| 'connect_action_required'
	| 'default_warning_icon'
	| 'device_not_seen'
	| 'device_unmanaged'
	| 'educational_msg'
	| 'email_verification_required'
	| 'geofence_entry'
	| 'geofence_exit'
	| 'location_disabled'
	| 'location_unknown'
	| 'marketing_msg'
	| 'opj_themes_announce'
	| 'pairing_reminder'
	| 'upcoming_block_announce'

export type NotifActionRes = 'email_verification_sent'

export const ACTIONABLE_VALUES = new Set<NotifActionable>(['optional', 'required'])

// Default icon is WarningInverseIcon which is used by android_perm
const DEFAULT_ICON_TYPE = 'default_warning_icon'
export const KNOWN_NOTIF_ICON_TYPES = new Set<NotifType>([
	'android_update_required',
	'android_update_available',
	'app_installed',
	'app_permissions_announce',
	'apporg_info',
	'apporg_promo',
	'device_not_seen',
	'location_unknown',
	'geofence_entry',
	'geofence_exit',
	'opj_themes_announce',
	'pairing_reminder',
	'upcoming_block_announce',
	'android_perm_required',
	'connect_action_required',
	'email_verification_required',
])

export type ActionableNotifType<T extends RawNotif> = T & {
	readonly content: ActionableNotifContent
}
export type InfoNotifType<T extends RawNotif> = T & { readonly content: NotifContent }
export type ActionableNotif = ActionableNotifType<RawNotif>
export type InfoNotif = InfoNotifType<RawNotif>
export type Notif = ActionableNotif | InfoNotif

export const mapRawToNotif = (rawNotif: RawNotif): Notif => {
	const c = rawNotif.content

	return {
		...rawNotif,
		content: {
			actionable: c.actionable || 'no',
			actions: c.actions,
			detail_body: c.detail_body || '',
			detail_subtitle: c.detail_subtitle || '',
			detail_title: c.detail_title || '',
			fallback_type: c.fallback_type || null,
			list_datetime: c.list_datetime || '',
			list_subtitle: c.list_subtitle || '',
			list_title: c.list_title || '',
			priority: c.priority || NotifPriority.LOW,
		},
	}
}

export const isActionableNotif = <T extends RawNotif>(notif: T): notif is ActionableNotifType<T> =>
	ACTIONABLE_VALUES.has(notif.content.actionable)

export const isInfoNotif = <T extends RawNotif>(notif: T): notif is InfoNotifType<T> =>
	!isActionableNotif(notif)

export const isHighPriorityNotif = <T extends RawNotif>(notif: Maybe<T>) =>
	notif?.content.priority === NotifPriority.HIGH

export const isLowPriorityNotif = <T extends RawNotif>(notif: Maybe<T>) =>
	notif?.content.priority === NotifPriority.LOW

export const getNotifIconType = (type: NotifType, fallback: Maybe<NotifType>): NotifType =>
	KNOWN_NOTIF_ICON_TYPES.has(type) ? type : fallback ?? DEFAULT_ICON_TYPE
