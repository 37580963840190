import { useWindowEvent } from '@eturi/react'
import cls from 'classnames'
import type { ReactNode } from 'react'
import { useContext } from 'react'
import { useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'
import { __RouterContext } from 'react-router'
import { useHistory } from 'react-router-dom'
import { manageLink$ } from '../../compound-selectors/app-misc'
import { ActionBtn, Glyph } from '../../widgets'
import { OPLink } from '../OPLink'
import { useNav } from './useNav'

export const NavBar = ({ children }: { readonly children: ReactNode }) => {
	const { isMobile, isOpen, setNavBar, toggle } = useNav()
	const routerCtx = useContext(__RouterContext)

	// Toggle mobile menu closed on click
	useWindowEvent('click', toggle, undefined, isMobile && isOpen)
	// Wrap the NavBar in router context to preserve route info
	useLayoutEffect(() =>
		setNavBar(<__RouterContext.Provider value={routerCtx}>{children}</__RouterContext.Provider>),
	)
	useLayoutEffect(() => () => setNavBar(null), [])

	return null
}

// -------------------------
type NavBarBackBtnProps = {
	readonly goBack?: boolean
}

const NavBarBackBtn = ({ goBack = false }: NavBarBackBtnProps) => {
	const history = useHistory()
	const manageLink = useSelector(manageLink$)

	if (goBack) {
		return <ActionBtn icon="chevron-left" invert onClick={() => history.goBack()} />
	}

	return (
		<OPLink className="action-btn btn-teal-invert" replace to={manageLink}>
			<Glyph name="chevron-left" />
		</OPLink>
	)
}

NavBar.BackBtn = NavBarBackBtn

// -------------------------
type DefaultNavBarProps = {
	readonly center?: boolean
	readonly children: string
	readonly goBack?: boolean
}

const DefaultNavBar = ({ center, children, goBack }: DefaultNavBarProps) => (
	<NavBar>
		<NavBarBackBtn goBack={goBack} />
		<div className="flex-auto lg:hidden" />
		<NavBarTitle center={center}>{children}</NavBarTitle>
		<div className="flex-auto lg:hidden" />
		<div className={cls('btn invisible', !center && 'lg:hidden')} />
	</NavBar>
)

NavBar.Default = DefaultNavBar

// -------------------------
type NavBarTitleProps = {
	readonly center?: boolean
	readonly children: string
}

const NavBarTitle = ({ center, children }: NavBarTitleProps) => (
	<NavBarTitleWrap center={center}>
		<h2 className="text-xl truncate uppercase">{children}</h2>
	</NavBarTitleWrap>
)

NavBar.Title = NavBarTitle

// -------------------------
type NavBarTitleWrapProps = {
	readonly center?: boolean
	readonly children: ReactNode
	readonly className?: string
	// Root view has no left btn, so we don't give extra left margin at lg. We could
	// extend this if more generic views are required, but for now, all views other
	// than dashboard have a left btn
	readonly root?: boolean
}

const NavBarTitleWrap = ({ center, children, className, root = false }: NavBarTitleWrapProps) => (
	<header
		className={cls(
			'overflow-hidden',
			center ? 'mx-auto' : 'mx-3',
			!(root || center) && 'lg:ml-5',
			className,
		)}
	>
		{children}
	</header>
)

NavBar.TitleWrap = NavBarTitleWrap
