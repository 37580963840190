import { createPollingHook } from '@eturi/react'
import type { HttpExtra } from '@op/services'
import { createSelector } from '@reduxjs/toolkit'
import { hasBrowserFocus$, isNetworkOnline$ } from '../reducers/browser-info.slice'
import { hasHttpRetryError$ } from '../reducers/http-retry.slice'

const shouldPoll$ = /*@__PURE__*/ createSelector(
	hasBrowserFocus$,
	isNetworkOnline$,
	hasHttpRetryError$,
	(hasFocus, isOnline, hasRetryError) => hasFocus && isOnline && !hasRetryError,
)

export const [PollingProvider, usePolling, usePausePolling] = createPollingHook(shouldPoll$, {
	getArgs: (): HttpExtra => ({ force: 'soft' }),
	immediate: true,
})
