export const loadImg = (src: string): Promise<HTMLImageElement> =>
	new Promise((resolve, reject) => {
		const img = new Image()

		img.onload = () => {
			img.onload = img.onerror = null
			resolve(img)
		}

		img.onerror = (e) => {
			img.onload = img.onerror = null
			reject(e)
		}

		img.src = src
	})
