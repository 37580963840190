import './mixpanel-loader'

import { createPromise } from '@eturi/util'
import { SessionDeviceId } from '../env'
import type { RootState } from '../reducers'

// Enable dev logging of analytics to testing setup
const ENABLE_DEV_LOGGING = true

// Enable actual tracking in dev for testing events
const ENABLE_DEV_TRACKING = false

// Whether we should track. By default, we won't track in development mode, and always track
// in QA / Prod. Dev tracking can be enabled above for testing.
const SHOULD_TRACK =
	process.env.E2E != '1' && (ENABLE_DEV_TRACKING || process.env.NODE_ENV !== 'development')

const log = (name: string, ...args: any[]) =>
	ENABLE_DEV_LOGGING && console.log(`[Analytics ${name}]`, ...args)

type StdAnalyticsCtx = {
	readonly account_id?: string
	readonly app_version: string
	readonly created?: number
	readonly device_id?: string
}

const mp = () => window.mixpanel

const stdAnalyticsCtx$ = ({ account }: RootState): StdAnalyticsCtx => ({
	account_id: account.account?.account_id,
	app_version: process.env.APP_VERSION,
	created: account.account?.created,
	device_id: SessionDeviceId.get(),
})

const mixpanelLoadedPromiseObj = createPromise()

export const initAnalytics = () => {
	if (process.env.E2E == '1') return

	mp().init(process.env.OURPACT_MIXPANEL_TOKEN, {
		api_host: process.env.OURPACT_MIXPANEL_URL,
		debug: ENABLE_DEV_LOGGING && ENABLE_DEV_TRACKING,
		loaded: () => {
			mixpanelLoadedPromiseObj.resolve()
		},
		ignore_dnt: true,
		persistence: 'localStorage',
	})
}

export const trackWithState = (state: RootState, type: string, data?: Record<string, any>) => {
	try {
		if (!SHOULD_TRACK) return log('Track', type, data)

		mp().track(type, {
			...data,
			...stdAnalyticsCtx$(state),
		})
	} catch {
		// Nai
	}
}

export const getDistinctId = () =>
	mixpanelLoadedPromiseObj.promise.then((): string => mp().get_distinct_id())

export const analyticsLogout = () => {
	if (!SHOULD_TRACK) return log('Logout')

	mp().reset()
}

export const identify = async (accountId: string) => {
	if (!SHOULD_TRACK) return log('Identify')

	const id = await getDistinctId()
	return id === accountId ? undefined : mp().identify(accountId)
}

export const timeEvent = (name: string) => {
	if (!SHOULD_TRACK) return log('Timing', `: ${name}`)

	mp().time_event(name)
}
