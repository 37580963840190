import { IOS_DEBOUNCE_SCROLL, PASSIVE_CAPTURE, useDebounce, useWindowEvent } from '@eturi/react'
import { useMemo, useState } from 'react'
import { FixedPositionElement } from '../FixedPositonElement'
import { LoadComponent } from '../Loading'
import type { CarouselProps } from './Carousel'
import { Carousel } from './lazy'
import type { DetailedDims, SimpleDims } from './types'

type FullscreenCarouselProps<T> = Omit<CarouselProps<T>, 'dims'> & {
	readonly dims?: SimpleDims
}

export const FullscreenCarousel = <T,>({ dims, ...p }: FullscreenCarouselProps<T>) => {
	const [fullCarouselHeight, setFullCarouselHeight] = useState(() => window.innerHeight)
	const [fullCarouselWidth, setFullCarouselWidth] = useState(() => window.innerWidth)

	const normalizedDims: DetailedDims = useMemo(() => {
		return {
			itemH: dims?.height || fullCarouselHeight,
			itemW: dims?.width || fullCarouselWidth,
			canvasH: fullCarouselHeight,
			canvasW: fullCarouselWidth,
		}
	}, [dims])

	const measureFullScreenCarousel = () => {
		setFullCarouselWidth(window.innerWidth)
		setFullCarouselHeight(window.innerHeight)
	}

	// When resizing make sure we update the width
	useWindowEvent(
		'resize',
		useDebounce(measureFullScreenCarousel, IOS_DEBOUNCE_SCROLL),
		PASSIVE_CAPTURE,
	)

	return (
		<FixedPositionElement>
			<LoadComponent>
				<Carousel fullscreen {...p} dims={normalizedDims} />
			</LoadComponent>
		</FixedPositionElement>
	)
}
