import { arrayAddOrUpdate, assertNotNullish, notEmpty, setIfNotEqual } from '@eturi/util'
import { size, toParamsURL } from '@op/util'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import find from 'lodash/find'
import orderBy from 'lodash/orderBy'
import reject from 'lodash/reject'
import { createSliceTransformer } from 'rtk-slice-transformer'
import { resetAction } from '../actions'
import { bindCreateAsyncThunkToState } from '../bindCreateAsyncThunkToState'
import type { HttpExtra } from '../http'
import type {
	InitState,
	Notif,
	NotifDetails,
	NotifUpdate,
	NotifUpdateRes,
	RawNotif,
	SThunkState,
} from '../types'
import { isHighPriorityNotif, isLowPriorityNotif, mapRawToNotif } from '../types'

export type NotifState = InitState & {
	readonly notifs: readonly RawNotif[]
}

export type WithNotifState = {
	readonly notification: NotifState
}

const initialState: NotifState = {
	isInit: false,
	notifs: [],
}

export const notificationSlice = /*@__PURE__*/ createSlice({
	name: 'notification',
	initialState,
	reducers: {
		updateStoreNotifs(s, { payload: notifUpdates }: PayloadAction<RawNotif[]>) {
			for (const notifUpdate of notifUpdates) {
				s.notifs = arrayAddOrUpdate(s.notifs, notifUpdate, 'notif_id')
			}
		},
	},
	extraReducers: (builder) =>
		builder
			.addCase(resetAction, () => initialState)
			.addCase(fetchNotifs.fulfilled, (s, a) => {
				s.isInit = true
				setIfNotEqual(s, 'notifs', a.payload)
			}),
})

export const { updateStoreNotifs } = notificationSlice.actions

export const notificationSliceTransformer = /*@__PURE__*/ createSliceTransformer(
	notificationSlice,
	(s) => ({
		isInit: s.isInit,
		notifs: s.notifs.map((n) => ({
			read: Boolean(n.details?.read_ts),
			shown: Boolean(n.details?.shown_ts),
			type: n.type,
		})),
	}),
	(a) => (updateStoreNotifs.match(a) ? null : a),
)

////////// Thunks //////////////////////////////////////////////////////////////

export type NotifThunkState = SThunkState & WithNotifState

const createAsyncThunk = /*@__PURE__*/ bindCreateAsyncThunkToState<NotifThunkState>()

type FetchNotifsArg = HttpExtra & {
	readonly locale?: string
}

export const fetchNotifs = /*@__PURE__*/ createAsyncThunk(
	'notifs/fetch',
	async ({ locale, ...extra }: FetchNotifsArg = {}, { dispatch, extra: { http } }) => {
		// Request notifications w/ the passed locale if present.
		const notifs = await dispatch(
			http.get<Maybe<RawNotif[]>>(
				toParamsURL('/notifications', { locale, state: 'active' }),
				extra,
			),
		)
		assertNotNullish(notifs, 'RawNotif[]')

		return notifs
	},
	{
		condition: (arg, api) => {
			if (!arg?.force && isNotifsInit$(api.getState())) return false
		},
	},
)

export const markAllNotifsShown = /*@__PURE__*/ createAsyncThunk(
	'notifs/markAllShown',
	async (_: void, { dispatch, getState }) => {
		const notShownNotifs = notShownNotifs$(getState())

		// Don't update notifications if there aren't non-shown to update
		if (notShownNotifs.length === 0) return

		await dispatch(
			updateNotifs(notShownNotifs.map((n) => ({ notif_id: n.notif_id, shown: true }))),
		).unwrap()
	},
)

export const markNotifRead = /*@__PURE__*/ createAsyncThunk(
	'notifs/markRead',
	(notif_id: string, { dispatch }) => dispatch(updateNotifs([{ notif_id, read: true }])).unwrap(),
)

// FIXME: try / catch user impl
export const updateNotifs = /*@__PURE__*/ createAsyncThunk(
	'notifs/update',
	async (updates: NotifUpdate[], { dispatch, getState, extra: { http } }) => {
		const rollbackNotifs: RawNotif[] = []
		const updatedNotifs: RawNotif[] = []

		for (const notif of rawNotifs$(getState())) {
			const notifUpdate = find(updates, { notif_id: notif.notif_id })

			// Copy of original notifs for rollback
			rollbackNotifs.push(notif)

			// If we found this original notif in our list of updates, update it,
			// otherwise, add it unchanged, as it won't be updated.
			updatedNotifs.push(notifUpdate ? _updateNotif(notif, notifUpdate) : notif)
		}

		// Dispatch updates for all modified notifications
		dispatch(updateStoreNotifs(updatedNotifs))

		try {
			return await dispatch(http.put<NotifUpdateRes[]>('/notifications', { data: [updates] }))
		} catch (e) {
			dispatch(updateStoreNotifs(rollbackNotifs))
			throw e
		}
	},
)

const _markActionTriggered = <T extends RawNotif>(notif: T): T =>
	notif.content.actions ?
		{
			...notif,
			content: {
				...notif.content,
				actions: [],
				actionable: 'no',
			},
		}
	:	notif

const _updateNotif = (originalNotif: RawNotif, updatedNotif: NotifUpdate): RawNotif => {
	let notif = originalNotif
	// if (!notif) throw new Error(`Cannot find notification ${notif_id}`)

	const notifUpdate: Writable<Partial<NotifDetails>> = {}

	if (updatedNotif.action) notif = _markActionTriggered(notif)
	if (updatedNotif.hidden) notifUpdate.hidden = true
	if (updatedNotif.read) notifUpdate.read_ts = Date.now()
	if (updatedNotif.shown) notifUpdate.shown_ts = Date.now()

	notif = { ...notif, details: { ...notif.details, ...notifUpdate } }

	return notif
}

////////// Selectors ///////////////////////////////////////////////////////////

const state$ = <T extends WithNotifState>(s: T) => s.notification

export const isNotifsInit$ = /*@__PURE__*/ createSelector(state$, (s) => s.isInit)
export const rawNotifs$ = /*@__PURE__*/ createSelector(state$, (s) => s.notifs)

export const notifs$ = /*@__PURE__*/ createSelector(rawNotifs$, (notifs): Notif[] =>
	orderBy(notifs.map(mapRawToNotif), 'notif_ts', 'desc'),
)

export const highPriorityNotifs$ = /*@__PURE__*/ createSelector(notifs$, (n) =>
	n.filter(isHighPriorityNotif),
)
export const lowPriorityNotifs$ = /*@__PURE__*/ createSelector(notifs$, (n) =>
	n.filter(isLowPriorityNotif),
)
export const byPriorityNotifs$ = /*@__PURE__*/ createSelector(
	highPriorityNotifs$,
	lowPriorityNotifs$,
	(h, l) => [...h, ...l],
)
export const notShownNotifs$ = /*@__PURE__*/ createSelector(notifs$, (notifs): Notif[] =>
	reject(notifs, (n) => !!n.details.shown_ts),
)
export const totalNotShownNotifs$ = /*@__PURE__*/ createSelector(notShownNotifs$, size)
export const hasNotifs$ = /*@__PURE__*/ createSelector(notifs$, notEmpty)
