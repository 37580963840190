import { useConstant, useFn, useStateGetter, useTimeout } from '@eturi/react'
import { a, useSpring } from '@react-spring/web'
import cls from 'classnames'
import type { ReactNode } from 'react'
import { useEffect } from 'react'
import { LoadingSpinner } from '../Loading'

type BtnLoaderChildProps = {
	readonly children?: ReactNode
	readonly className?: string
}

export const useBtnLoader = (isLoading: boolean, className?: string) => {
	const [isSpinnerVisible, _setSpinnerVisibility] = useStateGetter(false)
	const [setLoadingTimeout, clearLoadingTimeout] = useTimeout()

	// Sets style visibility based on opacity.
	const interpolateVis = (o: number) => (o === 0 ? 'hidden' : 'visible')

	// Getter for main button content shown when not loading
	const getContentSpringProps = () =>
		({
			config: LOADING_SPRING_CONFIG,
			// Fade in / out based on loading state
			opacity: isSpinnerVisible() ? 0 : 1,
		}) as const

	const getSpinnerSpringProps = () =>
		({
			config: LOADING_SPRING_CONFIG,
			// Fade + scale in / out based on loading state
			opacity: isSpinnerVisible() ? 1 : 0,
			transform: `scale(${isSpinnerVisible() ? 0.66 : 2})`,
		}) as const

	const [contentSpringProps, contentSpringApi] = useSpring(getContentSpringProps)
	const [spinnerSpringProps, spinnerSpringApi] = useSpring(getSpinnerSpringProps)

	// Since we use a state getter, we can combine all state updates into one
	const setSpinnerVisibility = useFn((isSpinnerVisible: boolean) => {
		_setSpinnerVisibility(isSpinnerVisible)
		contentSpringApi.start(getContentSpringProps())
		spinnerSpringApi.start(getSpinnerSpringProps())
	})

	const contentSpringStyle = useConstant(() => ({
		...contentSpringProps,
		visibility: contentSpringProps.opacity.to(interpolateVis),
	}))

	const spinnerSpringStyle = useConstant(() => ({
		...spinnerSpringProps,
		visibility: spinnerSpringProps.opacity.to(interpolateVis),
	}))

	useEffect(() => {
		// Reset any timeouts every time loading changes. This has a debounce effect.
		clearLoadingTimeout()

		// Show spinner if we aren't loading any more but the spinner is visible
		if (!isLoading && isSpinnerVisible()) {
			return setSpinnerVisibility(false)
		}

		// Start the timer for showing the spinner if we are loading, but the
		// spinner is not yet visible.
		if (isLoading && !isSpinnerVisible()) {
			setLoadingTimeout(() => isLoading && setSpinnerVisibility(true), 200)
		}
	}, [isLoading])

	const BtnLoaderChild = useConstant(() => {
		const BtnLoaderChild = ({ children, className }: BtnLoaderChildProps) => (
			<>
				<a.span className={cls('flex-center relative z-10', className)} style={contentSpringStyle}>
					{children}
				</a.span>

				<a.span className="absolute fill flex-center opacity-0 z-20" style={spinnerSpringStyle}>
					<LoadingSpinner className="is-md" />
				</a.span>
			</>
		)

		BtnLoaderChild.displayName = 'BtnLoaderChild'

		return BtnLoaderChild
	})

	const btnCls = cls(
		'overflow-hidden relative',
		isSpinnerVisible() && '!pointer-events-none',
		className,
	)

	return [btnCls, BtnLoaderChild] as const
}

const LOADING_SPRING_CONFIG = {
	friction: 50,
	mass: 1,
	tension: 400,
}
