import './FormLabel.scss'

import cls from 'classnames'
import type { ReactNode } from 'react'
import { useMemo } from 'react'

type FormLabelProps = {
	readonly children: ReactNode
	readonly className?: string
	readonly isReversed?: boolean
	readonly isSpaced?: boolean
	readonly isStacked?: boolean
	readonly label: string
}

export const FormLabel = ({
	children,
	className,
	isReversed = false,
	isSpaced = true,
	isStacked = false,
	label,
}: FormLabelProps) => {
	const FormLabelCls = useMemo(
		() =>
			cls(
				className,
				'form-label flex flex-wrap font-bold items-center text-sm text-text uppercase',
				isReversed && 'flex-row-reverse',
				isReversed && 'is-reversed',
				isSpaced && 'is-spaced',
				isSpaced && 'justify-between',
				isStacked && 'is-stacked',
			),
		[className, isReversed, isSpaced, isStacked],
	)

	return (
		<div className={FormLabelCls} aria-label={label}>
			<span className={cls('form-label__text')}>{label}</span>
			<span className="form-label__content">{children}</span>
		</div>
	)
}
