import { resetAction } from '@op/services'
import { useEffect } from 'react'
import { useAppDispatch } from '../types'

export const LogoutPage = () => {
	const d = useAppDispatch()

	// On load, we run the logout action then re-route to login page
	useEffect(() => {
		d(resetAction('user_logout'))
	}, [])

	return null
}
