import { useT } from '@eturi/react-i18next'
import LOGO from '@op/assets/logos/logo.svg'
import type { CssGlyphName } from '@op/icons'
import { useHandleSynPrevent } from '@op/react-web'
import { isAuthenticated$ } from '@op/services'
import type { MouseEventHandler, ReactNode } from 'react'
import { memo } from 'react'
import { useSelector } from 'react-redux'
import { baseLink$ } from '../../compound-selectors/app-misc'
import { SUPPORT_LINK } from '../../constants/links'
import { MOBILE_MENU_WIDTH } from '../../constants/sizes'
import { ZTopNav } from '../../constants/z-index'
import { useNavTo } from '../../hooks'
import { createStyles } from '../../styles/createStyles'
import { canActivateAddChildAction } from '../../thunks/can-activate'
import { useAppDispatch } from '../../types'
import { Glyph, NAV_TRANSITION_MS, OPLink, useNav } from '../../widgets'

const MobileNav = () => {
	const [t] = useT('menu_items')
	const d = useAppDispatch()
	const baseLink = useSelector(baseLink$)
	const isAuthenticated = useSelector(isAuthenticated$)
	const navTo = useNavTo()
	const nav = useNav()

	// We use this as a guard for the route, if the user has reached max devices
	// we don't want to route to that page, but we want to show a modal. We use a
	// OPLink as this allows us to keep the activeLink styles without additional
	// logic for styles
	const handleAddChildClick = useHandleSynPrevent(async () => {
		const canActivate = await d(canActivateAddChildAction())

		if (canActivate) navTo('/connect')
	})

	if (!(isAuthenticated && nav.isMobile)) return null

	return (
		<nav
			className="flex flex-col pt-4 pb-2 fixed bottom-0 left-0 top-0 bg-teal text-white"
			style={nav.isOpen ? styles.menuVisible : styles.menuHidden}
		>
			<div className="flex-center mb-6">
				<OPLink className="flex" to={baseLink} aria-label={t.$('navigation.home_logo_label')}>
					<span className="bg-white flex-center pt-0.5 h-16 w-16 rounded-xl">
						<img className="h-16 w-16" alt="OurPact Logo" src={LOGO} />
					</span>
				</OPLink>
			</div>

			<div className="py-2 touch-scroll-y">
				<MobileMenuItem icon="add-device" link="/connect" onClick={handleAddChildClick}>
					{t('add_device')}
				</MobileMenuItem>

				<MobileMenuItem icon="people-outline" link="/my-family">
					{t('my_family')}
				</MobileMenuItem>

				<MobileMenuItem icon="user" link="/account">
					{t('account')}
				</MobileMenuItem>

				{process.env.APP_ENV === 'dev' && (
					<MobileMenuItem icon="layers" link="/demo">
						Demo
					</MobileMenuItem>
				)}

				<MobileMenuItem icon="help" link={SUPPORT_LINK}>
					{t('help')}
				</MobileMenuItem>

				<MobileMenuItem icon="power" link="/logout">
					{t('logout')}
				</MobileMenuItem>
			</div>
		</nav>
	)
}

// NOTE: We have to change visibility as well so keyboard users can't tab to
//  menu elements that are not visible.
const styles = createStyles({
	menuHidden: {
		transform: `translate3d(-${MOBILE_MENU_WIDTH}px, 0, 1px)`,
		transition: `transform ${NAV_TRANSITION_MS}ms linear, visibility ${NAV_TRANSITION_MS}ms linear`,
		visibility: 'hidden',
		width: `${MOBILE_MENU_WIDTH}px`,
		// Make sure this is over the top nav, otherwise we can't click the entire OP logo
		zIndex: ZTopNav,
	},

	menuVisible: {
		transform: `translate3d(0, 0, 1px)`,
		transition: `transform ${NAV_TRANSITION_MS}ms linear, visibility 0s linear`,
		visibility: 'visible',
		width: `${MOBILE_MENU_WIDTH}px`,
		zIndex: ZTopNav,
	},
})

export default /*@__PURE__*/ memo(MobileNav)

type MobileMenuItemProps = {
	readonly children?: ReactNode
	readonly icon: CssGlyphName
	readonly link: string
	readonly onClick?: MouseEventHandler<HTMLAnchorElement>
}

const MobileMenuItem = ({ children, icon, link, onClick }: MobileMenuItemProps) => (
	<OPLink
		className="flex items-center p-4 text-white whitespace-nowrap hover:text-gray-96"
		onClick={onClick}
		to={link}
	>
		<Glyph name={icon} />
		<span className="font-bold ml-5">{children}</span>
	</OPLink>
)
