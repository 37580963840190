import cls from 'classnames'
import type { MouseEventHandler } from 'react'
import { ZOverlay } from '../constants/z-index'

type BackdropProps = {
	readonly className?: string
	readonly fixed?: boolean
	readonly onClick?: MouseEventHandler<HTMLDivElement>
	readonly visible: boolean
	readonly zIndex?: number
}

export const Backdrop = ({
	className,
	fixed = true,
	onClick,
	visible,
	zIndex = ZOverlay,
}: BackdropProps) => (
	<div
		className={cls(
			'bg-overlay fill transition-opacity',
			visible ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none',
			fixed ? 'fixed' : 'absolute',
			className,
		)}
		onClick={onClick}
		style={{ zIndex }}
	/>
)
