import './StatusIndicator.scss'

import type { ViewBlockState } from '@op/services'
import cls from 'classnames'
import type { CSSProperties } from 'react'
import { useMemo } from 'react'

type StatusIndicatorProps = {
	readonly className?: string
	readonly status: ViewBlockState
	readonly style?: CSSProperties
}

export const StatusIndicator = (p: StatusIndicatorProps) => {
	const StatusCls = useMemo(() => {
		let statusTypeCls = ''
		const s = 'status-indicator'

		switch (p.status) {
			case 'allowed':
				statusTypeCls = `${s}--circle ${s}--allowed`
				break

			case 'blocked':
				statusTypeCls = `${s}--circle ${s}--blocked`
				break

			case 'paused':
				statusTypeCls = `${s}--circle ${s}--paused`
				break

			case 'pending':
				statusTypeCls = `${s}--pending`
				break

			case 'unmanaged':
				statusTypeCls = `${s}--unmanaged`
				break
		}

		return cls(s, p.className, statusTypeCls)
	}, [p.className, p.status])

	return <div className={StatusCls} style={p.style} />
}
