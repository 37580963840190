import AutoSizer from 'react-virtualized-auto-sizer'
import { LoadComponent } from '../Loading'
import type { CarouselProps } from './Carousel'
import { Carousel } from './lazy'
import type { SimpleDims } from './types'

type ContainedCarouselProps<T> = Omit<CarouselProps<T>, 'dims'> & {
	readonly className?: string
	readonly dims?: SimpleDims
}

export const ContainedCarousel = <T,>({ className, dims, ...p }: ContainedCarouselProps<T>) => (
	<section className={className}>
		<LoadComponent>
			<AutoSizer>
				{({ height, width }) => (
					<div style={{ height, width }}>
						<Carousel
							{...p}
							dims={{
								canvasH: height,
								canvasW: width,
								itemH: dims?.height || height,
								itemW: dims?.width || width,
							}}
						/>
					</div>
				)}
			</AutoSizer>
		</LoadComponent>
	</section>
)
