import { useBoolState } from '@eturi/react'
import type { PersistenceExtra } from '@op/services'
import type { Store } from '@reduxjs/toolkit'
import { type ReactNode, useEffect, useLayoutEffect } from 'react'

type PersistGateProps = {
	readonly children?: ReactNode
	readonly loading: ReactNode
	readonly store: Store & PersistenceExtra
}

export const PersistGate = ({ children, loading, store }: PersistGateProps) => {
	const [isReady, setReady] = useBoolState(false)

	// For whatever reason, E2E tests need some time to settle before the main stuff is rendered.
	// This is a HACK. The longer the timeout period, the more likely the tests will be completely
	// stable. However, this NEVER happens in the browser. No one has seen it happen even once.
	// Because this block is evaluated using environment variables that get inlined during build time,
	// this code will never show up in prod builds.
	if (process.env.E2E === '1') {
		useEffect(() => {
			store.onReady(() => {
				setTimeout(() => {
					document
						.querySelectorAll('.page-loading-spinner')
						.forEach((t) => console.log(window.getComputedStyle(t).margin))
					setReady()
				}, 100)
			})
		}, [])
	} else {
		useLayoutEffect(() => {
			store.onReady(setReady)
		}, [])
	}

	return <>{isReady ? children : loading}</>
}
