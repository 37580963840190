import type { InitState } from '@op/services'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSelector, createSlice } from '@reduxjs/toolkit'

export type ChatState = InitState & {
	readonly isEnabled: boolean
	readonly status: Zopim.Status
}

export type WithChatState = {
	readonly chat: ChatState
}

const initialState: ChatState = {
	isEnabled: false,
	isInit: false,
	status: 'offline',
}

export const chatSlice = /*@__PURE__*/ createSlice({
	name: 'chat',
	initialState,
	reducers: {
		initChat(s) {
			s.isInit = true
		},

		setChatEnabled(s, a: PayloadAction<boolean>) {
			s.isEnabled = a.payload
		},

		setChatStatus(s, a: PayloadAction<Zopim.Status>) {
			s.status = a.payload
		},
	},
})

////////// Selectors ///////////////////////////////////////////////////////////

const state$ = <T extends WithChatState>(s: T) => s.chat

export const isChatEnabled$ = /*@__PURE__*/ createSelector(state$, (s) => s.isEnabled)
export const isChatInit$ = /*@__PURE__*/ createSelector(state$, (s) => s.isInit)
export const isChatOnline$ = /*@__PURE__*/ createSelector(state$, (s) => s.status === 'online')
