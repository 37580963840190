import { createSelector } from '@reduxjs/toolkit'
import { rawRedirect$ } from '../reducers/app-misc.slice'
import { pathname$ } from '../reducers/browser-info.slice'
import { isAffiliate$ } from './affiliate'

export const hasPairPageStyles$ = /*@__PURE__*/ createSelector(
	pathname$,
	rawRedirect$,
	(pathname, redirect) =>
		Boolean(
			pathname.startsWith('/pair') ||
				(redirect?.startsWith('/pair') && pathname.startsWith('/login')),
		),
)

export const isAffiliateSignUpPage$ = /*@__PURE__*/ createSelector(
	isAffiliate$,
	pathname$,
	(isAffiliate, pathname) => isAffiliate && pathname.startsWith('/signup'),
)

export const isPromoPage$ = /*@__PURE__*/ createSelector(pathname$, (p) => p.startsWith('/promo'))
