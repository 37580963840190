import './RadioCheck.scss'

import { useFn } from '@eturi/react'
import { useKeyboardClick } from '@op/react-web'
import cls from 'classnames'
import type { InputHTMLAttributes } from 'react'
import { useContext, useEffect, useRef } from 'react'
import type { TestIDValue } from '../../TestID'
import type { RadioCheckType } from '../../types'
import { RadioGroupContext } from './RadioGroup'

export type RadioCheckSize = 'xs' | 'sm' | 'md'

type CheckProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'size'> & {
	readonly 'data-testid'?: TestIDValue
	readonly ellipsis?: boolean
	readonly isReversed?: boolean
	readonly isSpaced?: boolean
	readonly isIndeterminate?: boolean
	readonly size?: RadioCheckSize
}

const checkFactory = (isButton: boolean, displayName: string) => {
	const Check = (p: CheckProps) => {
		const [
			fakeInputRef,
			inputRef,
			handleFieldClickProps,
			{ children, ellipsis, testId },
			inputProps,
			MainCls,
		] = useRadioCheck('checkbox', isButton, p)

		return (
			<label className={MainCls}>
				<input className="sr-only" {...inputProps} ref={inputRef} tabIndex={-1} type="checkbox" />

				<span
					{...handleFieldClickProps}
					aria-checked={inputProps.checked}
					className="radio-check__elem"
					data-testid={testId}
					ref={fakeInputRef}
					role="checkbox"
				>
					<span className="radio-check__elem-inner">
						{isButton ? children : <i className="pakicon-check checkbox__check" />}
					</span>
				</span>

				{!isButton && children && (
					<span className={cls('radio-check__label', ellipsis && 'ellipsis')}>{children}</span>
				)}
			</label>
		)
	}

	Check.displayName = displayName

	return Check
}

type RadioProps = Omit<CheckProps, 'isIndeterminate'> & { readonly tabIdx?: number }

const radioFactory = (isButton: boolean, displayName: string) => {
	const Radio = (p: RadioProps) => {
		const [
			fakeInputRef,
			inputRef,
			handleFieldClickProps,
			{ children, ellipsis, tabIdx, testId },
			inputProps,
			MainCls,
		] = useRadioCheck('radio', isButton, p)
		const radioGroupCtx = useContext(RadioGroupContext)

		delete handleFieldClickProps.tabIndex

		// Ref that adds the fake radio to the radio group for keyboard accessibility
		const fakeInputRefCallback = useFn(($el: HTMLSpanElement | null) => {
			const prevLabelRef = fakeInputRef.current

			// If we had a different element remove it
			if (prevLabelRef) radioGroupCtx.remove(prevLabelRef)

			if ($el) radioGroupCtx.add($el, tabIdx)

			fakeInputRef.current = $el
		})

		useEffect(
			() => () => {
				const label = fakeInputRef.current
				if (label) radioGroupCtx.remove(label)
			},
			[],
		)

		return (
			<label className={MainCls}>
				<input className="sr-only" {...inputProps} ref={inputRef} tabIndex={-1} type="radio" />

				<span
					{...handleFieldClickProps}
					aria-checked={inputProps.checked}
					className="radio-check__elem"
					data-testid={testId}
					ref={fakeInputRefCallback}
					role="radio"
					tabIndex={inputProps.checked ? 0 : -1}
				>
					<span className="radio-check__elem-inner">{isButton ? children : null}</span>
				</span>

				{!isButton && children && (
					<span className={cls('radio-check__label', ellipsis && 'ellipsis')}>{children}</span>
				)}
			</label>
		)
	}

	Radio.displayName = displayName

	return Radio
}

export const Check /*@__PURE__*/ = checkFactory(false, 'Check')
export const CheckBtn /*@__PURE__*/ = checkFactory(true, 'CheckBtn')
export const Radio /*@__PURE__*/ = radioFactory(false, 'Radio')
export const RadioBtn /*@__PURE__*/ = radioFactory(true, 'RadioBtn')

/** Helper to handle shared logic for all radios and checkboxes */
const useRadioCheck = (
	type: RadioCheckType,
	isButton: boolean,
	{
		children,
		className,
		'data-testid': testId,
		ellipsis,
		isIndeterminate,
		isReversed,
		isSpaced,
		size = 'md',
		tabIdx,
		...inputProps
	}: CheckProps & { readonly tabIdx?: number },
) => {
	const fakeInputRef = useRef<HTMLSpanElement | null>(null)
	const inputRef = useRef<HTMLInputElement | null>(null)
	const handleKeyboardClickProps = useKeyboardClick((ev) => {
		// Click events will affect the input automatically. So we only need to invoke an artificial
		// click on keyboard selected labels. Also, if it's a click, the behavior is not to retain
		// focus according to my testing, so we don't need to put focus on the label here.
		if (ev.type === 'click') return fakeInputRef.current?.blur()

		inputRef.current?.click()
		fakeInputRef.current?.focus()
	}, inputProps.readOnly)

	const normalizedProps = {
		children,
		ellipsis,
		isIndeterminate,
		isReversed,
		isSpaced,
		size,
		tabIdx,
		testId,
	}

	return [
		fakeInputRef,
		inputRef,
		handleKeyboardClickProps,
		normalizedProps,
		inputProps,
		cls(
			className,
			type,
			'radio-check',
			`radio-check--${size}`,
			!isButton && 'radio-check--no-btn',
			inputProps.checked && 'is-checked',
			isIndeterminate && 'is-indeterminate',
			isReversed && 'is-reversed',
			isSpaced && 'is-spaced',
			inputProps.readOnly && 'pointer-events-none',
		),
	] as const
}
