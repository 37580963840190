import { IOS_DEBOUNCE_SCROLL, useBoolState, useFn, useThrottle, useWindowEvent } from '@eturi/react'
import { useKeyboardClick } from '@op/react-web'
import type { BlockStatus, ManagedUser } from '@op/services'
import { activeChildId$, blockStatusMap$ } from '@op/services'
import cls from 'classnames'
import type { CSSProperties, ReactNode } from 'react'
import { useEffect, useLayoutEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { getManageLink } from '../../compound-selectors/app-misc'
import { ZPopover } from '../../constants/z-index'
import { getMediaValue, useIsScreenLG, useMediaMatches, useMediaMemo, useNavTo } from '../../hooks'
import { manageFeature$ } from '../../reducers/app-misc.slice'
import { createStyles } from '../../styles/createStyles'
import { Glyph } from '../../widgets'
import { AvatarImage } from '../Avatar'
import { StatusIndicator } from '../StatusIndicator'
import {
	NAV_LIST_ITEM_GAP_LG,
	NAV_LIST_ITEM_GAP_SM,
	NAV_LIST_ITEM_LG,
	NAV_LIST_ITEM_SM,
} from './constants'
import { navChildListUsers$ } from './selectors'

type NavChildListProps = {
	readonly alignLeft?: boolean
}
/**
 * This component renders the child list, and manages the links based on the
 * active feature / route.
 */
export const NavChildList = ({ alignLeft }: NavChildListProps) => {
	const blockStatusMap = useSelector(blockStatusMap$)
	const activeChildId = useSelector(activeChildId$)
	const feature = useSelector(manageFeature$)
	const sortedUsers = useSelector(navChildListUsers$)
	const { pathname } = useLocation()
	const navTo = useNavTo()

	const [isNavOpen, openNav, closeNav] = useBoolState(false)

	useWindowEvent('click', closeNav)

	const isGalleryLink = pathname.includes('gallery')

	const handleSelect = useFn((userId: string) => {
		if (userId === activeChildId && !isNavOpen) return openNav()

		closeNav()
		navTo(isGalleryLink ? `/gallery/${userId}` : getManageLink(feature, userId))
	})

	return (
		<NavChildListContainer alignLeft={alignLeft} isOpen={isNavOpen}>
			{sortedUsers.map((u) => (
				<NavChildListItem
					blockStatus={blockStatusMap[u.user_id]}
					isActive={u.user_id === activeChildId && sortedUsers.length > 1}
					isOpen={isNavOpen}
					key={u.user_id}
					user={u}
					onClick={handleSelect}
				/>
			))}
		</NavChildListContainer>
	)
}

type NavChildListContainerProps = {
	readonly alignLeft?: boolean
	readonly children: ReactNode
	readonly isOpen: boolean
}

const NavChildListContainer = ({ alignLeft, children, isOpen }: NavChildListContainerProps) => {
	const mediaMatches = useMediaMatches()
	const $containerElRef = useRef<HTMLDivElement>(null)
	const $listElRef = useRef<HTMLDivElement>(null)

	const containerStyles = useMediaMemo(
		(matches): CSSProperties => ({
			height:
				// Height includes the gap, because we couldn't use a row-gap, since we want the
				// full height of an item to be clickable
				(matches.lg ?
					NAV_LIST_ITEM_LG + NAV_LIST_ITEM_GAP_LG
				:	NAV_LIST_ITEM_SM + NAV_LIST_ITEM_GAP_SM) + NAV_LIST_PADDING,
			// On dashboard, where there is no back arrow, we align to the left
			marginLeft: matches.lg && alignLeft ? -NAV_LIST_PADDING : undefined,
			zIndex: isOpen ? ZNavList : undefined,
		}),
		[alignLeft, isOpen],
	)

	const listStyles = useMediaMemo(
		(matches): CSSProperties => ({
			//  We need to make this conditional based on `isOpen`, because if we don't, the
			//  can get stuck at a certain scroll position. Clearing the maxHeight, automatically
			//  scrolls the list back to the top.
			maxHeight:
				isOpen ?
					matches.lg ?
						NAV_LIST_MAX_HT_LG
					:	NAV_LIST_MAX_HT_SM
				:	undefined,
			paddingBottom: NAV_LIST_PADDING,
			paddingLeft: NAV_LIST_PADDING,
			paddingTop: (matches.lg ? NAV_LIST_ITEM_GAP_LG : NAV_LIST_ITEM_GAP_SM) / 2,
			paddingRight: matches.md ? NAV_LIST_PADDING_RIGHT_MD : NAV_LIST_PADDING,
		}),
		[isOpen],
	)

	const setListMaxWidth = useThrottle(
		() => {
			const $containerEl = $containerElRef.current
			const $listEl = $listElRef.current

			if (!($containerEl && $listEl)) return

			// If we're below MD, set the maxWidth to the innerWidth - left pos of container - the
			// standard screen padding. If we're not open, we also take into account the caret and gap.
			// NOTE: We have to use the parent position relative container + offsetLeft, rather than
			//  getBoundingClientRect.x (for either), b/c when the mobile menu is open the x position
			//  is astronomical, and when switching views, the list becomes crushed.
			const maxWidth = Math.min(
				NAV_LIST_MAX_W_SM,
				window.innerWidth -
					$containerEl.offsetLeft -
					(isOpen ? SCREEN_EDGE_PAD : NAV_LIST_CARET_SM + NAV_LIST_ITEM_GAP_SM),
			)

			$listEl.style.maxWidth = `${maxWidth}px`
		},
		IOS_DEBOUNCE_SCROLL,
		{ leading: true, trailing: true },
	)

	useWindowEvent('resize', setListMaxWidth, undefined, !mediaMatches.md)

	useEffect(() => {
		const $listEl = $listElRef.current

		// Above md we set the max width based on break points. We do it here, b/c
		// we don't want a resize handler running when we don't need it
		if (!($listEl && mediaMatches.md)) return

		// Cancel any trailing-edge throttle call
		setListMaxWidth.cancel()
		$listEl.style.maxWidth = `${getMediaValue(NAV_LIST_MAX_W_LG, { xl: NAV_LIST_MAX_W_XL })}px`
	}, [mediaMatches])

	useLayoutEffect(() => {
		if (!mediaMatches.md) setListMaxWidth()

		const $el = $listElRef.current

		// Reset scroll position when toggling list
		if ($el) $el.scrollTop = 0
	}, [isOpen])

	return (
		<div
			className={cls('flex-1 relative', !isOpen && 'overflow-hidden')}
			ref={$containerElRef}
			style={containerStyles}
		>
			<div
				className={cls(
					'bg-white flex flex-col top-0 left-0',
					// 'absolute' needs to be conditional, otherwise, large names will squish into
					// right nav buttons in certain cases (gallery, locator)
					isOpen && 'absolute rounded-2xl shadow-2xl touch-scroll-y',
				)}
				ref={$listElRef}
				style={listStyles}
			>
				{children}
			</div>
		</div>
	)
}

NavChildList.Container = NavChildListContainer

type NavChildItemProps = {
	readonly blockStatus: Maybe<BlockStatus>
	readonly isActive: boolean
	readonly isOpen: boolean
	readonly user: ManagedUser
	onClick(userId: string): void
}

const NavChildListItem = ({ blockStatus, isActive, isOpen, onClick, user }: NavChildItemProps) => {
	const isLG = useIsScreenLG()
	// NOTE: The reason why we user keyboard clicks here instead of links is so we're
	//  able control when the items are accessible via keyboard, since the items are
	//  always there just hidden, then they're accessible via keyboard, which can be
	//  confusing when navigating with a closed list. Once the nav is opened then we
	//  want to allow them to toggle those items via the menu.
	const handleClick = useKeyboardClick(
		(ev) => {
			ev.stopPropagation()
			onClick(user.user_id)
		},
		isActive ? false : !isOpen,
	)

	return (
		<div
			{...handleClick}
			className={isOpen ? NAV_LIST_ITEM_CLS : NAV_LIST_ITEM_CLS_CLOSED}
			style={isLG ? NavListStyles.itemLG : NavListStyles.itemSM}
		>
			<div className="relative">
				<AvatarImage
					className={cls('rounded-full', isActive && 'border-2 border-teal')}
					style={isLG ? NavListStyles.avatarLG : NavListStyles.avatarSM}
					user={user}
				/>

				{blockStatus && (
					<StatusIndicator
						status={blockStatus.viewState}
						style={isLG ? NavListStyles.indicatorLG : NavListStyles.indicatorSM}
					/>
				)}
			</div>

			<p className="font-bold select-none text-xl truncate uppercase">{user.user_name}</p>

			{isActive && <Glyph className="text-teal" name={isOpen ? 'chevron-up' : 'chevron-down'} />}
		</div>
	)
}

NavChildList.Item = NavChildListItem

// Screen edge padding, for constraining width of dropdown
const SCREEN_EDGE_PAD = 12
const NAV_LIST_ITEM_CLS = 'flex items-center max-w-full'
const NAV_LIST_ITEM_CLS_CLOSED = `${NAV_LIST_ITEM_CLS} w-min`

// Caret size is just a hardcoded measurement of the `icon-md` caret size.
// We only care about the sm size caret, since at MD, the list never needs
// to be offset by this calculation, since it's constrained by MAX_W_LG/XL
const NAV_LIST_CARET_SM = 26
const NAV_LIST_MAX_W_SM = 400
const NAV_LIST_MAX_W_LG = 500
const NAV_LIST_MAX_W_XL = 650
const NAV_LIST_PADDING = 12
// Add a bit more breathing room on the right at MD
const NAV_LIST_PADDING_RIGHT_MD = 20
// We use a half item size, so it's obvious the user can scroll if they have 7+ kids
const NAV_LIST_MAX_HT_SM = 6.5 * NAV_LIST_ITEM_SM + 6 * NAV_LIST_ITEM_GAP_SM + NAV_LIST_PADDING
const NAV_LIST_MAX_HT_LG = 6.5 * NAV_LIST_ITEM_LG + 6 * NAV_LIST_ITEM_GAP_LG + NAV_LIST_PADDING

// This sets the nav above the feature popover (can test on disabled Gallery)
const ZNavList = ZPopover + 1

const NavListStyles = createStyles({
	avatarLG: {
		height: NAV_LIST_ITEM_LG,
		width: NAV_LIST_ITEM_LG,
		padding: 2,
	},

	avatarSM: {
		height: NAV_LIST_ITEM_SM,
		width: NAV_LIST_ITEM_SM,
		padding: 2,
	},

	indicatorLG: {
		height: 22,
		width: 22,
		bottom: 0,
		right: 0,
	},

	indicatorSM: {
		height: 16,
		width: 16,
		bottom: 0,
		right: 0,
	},

	itemLG: {
		columnGap: NAV_LIST_ITEM_GAP_LG,
		height: NAV_LIST_ITEM_LG + NAV_LIST_ITEM_GAP_LG,
		paddingTop: NAV_LIST_ITEM_GAP_LG / 2,
		paddingBottom: NAV_LIST_ITEM_GAP_LG / 2,
	},

	itemSM: {
		columnGap: NAV_LIST_ITEM_GAP_SM,
		height: NAV_LIST_ITEM_SM + NAV_LIST_ITEM_GAP_SM,
		paddingTop: NAV_LIST_ITEM_GAP_SM / 2,
		paddingBottom: NAV_LIST_ITEM_GAP_SM / 2,
	},
})
