import { activeChildId$, children$ } from '@op/services'
import { createSelector } from '@reduxjs/toolkit'

// Keep normal sorting, but move active child to first index
export const navChildListUsers$ = /*@__PURE__*/ createSelector(
	activeChildId$,
	children$,
	(activeChildId, users) =>
		[...users].sort((u1, u2) =>
			// We check both u1 and u2 for activeChildId since firefox doesn't compare in order
			// firefox bug https://bugzilla.mozilla.org/show_bug.cgi?id=1715486
			u1.user_id === activeChildId ? -1
			: u2.user_id === activeChildId ? 1
			: 0,
		),
)
