import './DeviceDetailsView.scss'

import { useBoolState, useFn } from '@eturi/react'
import { useT } from '@eturi/react-i18next'
import type { DeviceBlockStatus, ManagedDevice, RawManagedUser } from '@op/services'
import { isIOSDevice, removeDevice } from '@op/services'
import { useMemo } from 'react'
import { useAppDispatch } from '../../types'
import { ActionBtn, ModalFooter, useToasts } from '../../widgets'
import { ChildAppInstallBtn } from './ChildAppInstallBtn'
import { DeviceStatus } from './DeviceStatus'

type DeviceViewProps = {
	readonly device: ManagedDevice
	readonly deviceStatus: DeviceBlockStatus
	readonly onClose: () => void
	readonly onAndroidRemoveManagement: () => void
	readonly user: RawManagedUser
}

export const DeviceDetailsView = ({
	deviceStatus,
	device,
	onAndroidRemoveManagement,
	user,
	...p
}: DeviceViewProps) => {
	const d = useAppDispatch()
	const [t] = useT('devices.device')
	const [isDeletingDevice, setDeleting, cancelDeleting] = useBoolState(false)
	const { addConfirmToast, addErrorToast } = useToasts()

	const deviceName = device.displayName
	const isApple = device.make.toLowerCase() === 'apple'
	const isPending = deviceStatus.viewState === 'pending'
	const isManaged = deviceStatus.viewState !== 'unmanaged'
	const userName = user.user_name || ''

	const showPendingDetails = isManaged && isPending
	const showUnmanagedAppleDetails = !isManaged && isApple
	const showUnmanagedAndroidDetails = !isManaged && !isApple
	const showChildAppInstall = isManaged && device.os === 'iOS'

	// Once user has confirmed delete we go through and make the delete requests
	const handleConfirmDeleteDevice = useFn(async () => {
		const translationOpt = { child: userName, deviceName }

		try {
			await d(removeDevice(device.device_id)).unwrap()

			p.onClose()
		} catch {
			addErrorToast(t.$('messages.delete_device_error', translationOpt))
		}
	})

	// Initiates the "delete" flow by first prompting with a confirmation toast
	const handleDeleteDevice = useFn(() => {
		if (isDeletingDevice) return

		setDeleting()

		// Check to see if it is a deletable android device
		const cannotDeleteDevice =
			!isIOSDevice(device) &&
			!device.management_removed &&
			Number.parseInt(device.app_version, 10) < 4

		if (cannotDeleteDevice) {
			// If the device cannot be deleted (android management needs to be
			// removed first) then we show a modal with instructions. Since this
			// is currently displayed in a modal we must show that modal and
			// call close
			onAndroidRemoveManagement()
			return p.onClose()
		}

		addConfirmToast({
			msg: t.$('actions.delete_confirmation', { value: deviceName }),
			onConfirm: handleConfirmDeleteDevice,
			onCancel: cancelDeleting,
			onClose: cancelDeleting,
		})
	})

	const PendingDetails = useMemo(
		() =>
			showPendingDetails && (
				<div className="device-details-view__bottom">
					<header className="device-details-view__bottom-header">
						{t('pending_description_header')}
					</header>

					<ol className="list-decimal">
						<li>{t('pending_reason_1')}</li>
						<li>{t('pending_reason_2')}</li>
						<li>{t('pending_reason_3')}</li>
					</ol>
				</div>
			),
		[t, showPendingDetails],
	)

	// Shows unmanaged device text for iOS Devices
	const UnmanagedApple = useMemo(
		() =>
			showUnmanagedAppleDetails && (
				<div className="device-details-view__bottom">
					<header className="device-details-view__bottom-header">
						{t('unmanaged_apple_header', { deviceName })}
					</header>
				</div>
			),
		[t, deviceName, showUnmanagedAppleDetails],
	)

	// Shows unmanaged device text for Android Devices
	const UnmanagedAndroid = useMemo(
		() =>
			showUnmanagedAndroidDetails && (
				<div>
					<header className="mt-2 mb-4">{t('unmanaged_android_header')}</header>

					<ol className="list-decimal">
						<li>{t('repair_android_step_1', { childName: userName, deviceName })}</li>
						<li>{t('repair_android_step_2')}</li>
						<li>{t('repair_android_step_3')}</li>
						<li>{t('repair_android_step_4')}</li>
						<li>{t('repair_android_step_5')}</li>
					</ol>
				</div>
			),
		[t, deviceName, showUnmanagedAndroidDetails, userName],
	)

	// Shown when an iOS devices is managed but child app is not installed
	const InstallAppSection = useMemo(
		() =>
			showChildAppInstall && (
				<div className="device-details-view__bottom flex flex-col items-center mt-5 break-word">
					<h5 className="font-bold mb-3">{t('ourpact_jr')}</h5>
					<p className="text-sm sm:text-base">{t('install_child_app_description')}</p>
				</div>
			),
		[t, showChildAppInstall],
	)

	const InstallAppBtn = useMemo(
		() =>
			showChildAppInstall && (
				<ChildAppInstallBtn
					childId={user.user_id}
					deviceId={device.device_id}
					disabled={isDeletingDevice}
				/>
			),
		[showChildAppInstall, user.user_id, device.device_id, isDeletingDevice],
	)

	return (
		<>
			<section className="device-details-view">
				<DeviceStatus device={device} status={deviceStatus} />

				{PendingDetails}
				{UnmanagedApple}
				{UnmanagedAndroid}
				{InstallAppSection}
			</section>

			{InstallAppBtn ?
				<ModalFooter>
					{InstallAppBtn}
					<ActionBtn
						color="red"
						className="is-right"
						icon="trash"
						invert
						onClick={handleDeleteDevice}
					/>
				</ModalFooter>
			:	<ActionBtn
					color="red"
					className="absolute right-5 bottom-5"
					icon="trash"
					invert
					onClick={handleDeleteDevice}
				/>
			}
		</>
	)
}
