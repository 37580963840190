import { useBoolState, useFn, useInit, useWindowEvent } from '@eturi/react'
import { useT } from '@eturi/react-i18next'
import { useKeyboardClick } from '@op/react-web'
import type { BlockStatus, LocationHistory, ManagedDevice, ManagedUser } from '@op/services'
import {
	activeChildId$,
	blockStatusMap$,
	children$,
	locationHistory$,
	locationHistoryDate$,
	managedDevices$,
	nonRetiredManagedDevices$,
} from '@op/services'
import cls from 'classnames'
import keys from 'lodash/keys'
import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { NavChildList, navChildListUsers$ } from '../../components'
import {
	NAV_LIST_ITEM_GAP_LG,
	NAV_LIST_ITEM_GAP_SM,
	NAV_LIST_ITEM_LG,
	NAV_LIST_ITEM_SM,
} from '../../components/Navigation/constants'
import { selectedLocationDeviceId$ } from '../../compound-selectors/locator'
import { useIsScreenLG, useNavTo } from '../../hooks'
import {
	isLocationHistoryEnabled$,
	setActiveLocationDeviceId,
	setActiveLocationTS,
} from '../../reducers/geo-ui.slice'
import { createStyles } from '../../styles/createStyles'
import { setLocHistoryDateAndFetch } from '../../thunks/location-history'
import { useAppDispatch } from '../../types'

type ChildDeviceMap = {
	readonly [id: string]: ManagedDevice[]
}

export const LocatorNavChildList = () => {
	const d = useAppDispatch()
	const navTo = useNavTo()
	const activeChildId = useSelector(activeChildId$)
	const blockStatusMap = useSelector(blockStatusMap$)
	const isInit = useInit()
	const isLocationHistoryEnabled = useSelector(isLocationHistoryEnabled$)
	const locationHistory = useSelector(locationHistory$)
	const locationHistoryDate = useSelector(locationHistoryDate$)
	const managedDevices = useSelector(managedDevices$)
	const nonRetiredDevices = useSelector(nonRetiredManagedDevices$)
	const selectedLocationDeviceId = useSelector(selectedLocationDeviceId$)
	const sortedUsers = useSelector(navChildListUsers$)
	const users = useSelector(children$)

	const [isNavOpen, openNav, closeNav] = useBoolState(false)

	useWindowEvent('click', closeNav)

	// Create a map that links children to devices that have location history
	// Per AC if a child has more than 1 device then we show devices for all
	// children so as we create the map we keep track if one child has more than
	// one device listed
	const [deviceMap, hasMultiDeviceChild] = useMemo(() => {
		const deviceIdMap: Writable<ChildDeviceMap> = {}
		let hasMultiDevices = false

		for (const user of users) {
			const devices = getHistoryDevices(locationHistory, user, managedDevices, nonRetiredDevices)
			hasMultiDevices ||= devices.length > 1

			deviceIdMap[user.user_id] = devices
		}
		return [deviceIdMap, hasMultiDevices]
	}, [users, locationHistory, managedDevices, nonRetiredDevices])

	// If current child is selected then we just close and don't modify
	// history or change active device
	const handleChildSelect = useFn((userId: string) => {
		if (userId === activeChildId && !isNavOpen) return openNav()

		closeNav()
		navTo(`/locator/${userId}`)
		d(setActiveLocationDeviceId(null))
		d(setActiveLocationTS(null))
	})

	const handleDeviceClick = useFn((userId: string, deviceId: string) => {
		if (activeChildId !== userId) {
			navTo(`/locator/${userId}`)
		}

		d(setActiveLocationDeviceId(deviceId))
		d(setActiveLocationTS(null))
		closeNav()
	})

	useWindowEvent('click', closeNav)

	useEffect(() => {
		if (!isLocationHistoryEnabled || !locationHistoryDate || !isInit()) return
		// This protects against the case where a device is re-paired and nav shows two identical
		// devices (one for the new device obj and one for the location history of the old device).
		// On a re-pair a new device object is created and if there was the old device obj had
		// location history then it's updated to point to the new device obj by the server.
		// At which point we need to pull down a fresh copy of location history to have accurate
		// data
		d(setLocHistoryDateAndFetch(locationHistoryDate))
	}, [managedDevices.length])

	return (
		<NavChildList.Container isOpen={isNavOpen}>
			{sortedUsers.map((u) => (
				<LocatorNavChildItem
					blockStatus={blockStatusMap[u.user_id]}
					devices={deviceMap[u.user_id]}
					hasMultiDeviceChild={hasMultiDeviceChild}
					isActive={u.user_id === activeChildId}
					isOpen={isNavOpen}
					key={u.user_id}
					onDeviceClick={handleDeviceClick}
					onUserClick={handleChildSelect}
					selectedDeviceId={selectedLocationDeviceId}
					user={u}
				/>
			))}
		</NavChildList.Container>
	)
}

type LocatorNavChildItemProps = {
	readonly blockStatus: Maybe<BlockStatus>
	readonly devices: ManagedDevice[]
	readonly hasMultiDeviceChild: boolean
	readonly isActive: boolean
	readonly isOpen: boolean
	readonly selectedDeviceId: Maybe<string>
	readonly user: ManagedUser
	onDeviceClick(userId: string, deviceId: string): void
	onUserClick(userId: string): void
}

const LocatorNavChildItem = ({
	blockStatus,
	devices,
	hasMultiDeviceChild,
	isActive,
	isOpen,
	onDeviceClick,
	onUserClick,
	selectedDeviceId,
	user,
}: LocatorNavChildItemProps) => {
	const isLG = useIsScreenLG()
	const [t] = useT()

	const Devices = useMemo(() => {
		if (!devices.length) {
			return <p className="my-0.5">{t('locator.map.children_selector.no_device')}</p>
		}

		if (!hasMultiDeviceChild) return null

		return devices.map((d) => (
			<NavDeviceItem
				key={d.device_id}
				device={d}
				isOpen={isOpen}
				isSelected={selectedDeviceId === d.device_id}
				onClick={onDeviceClick}
				userId={user.user_id}
			/>
		))
	}, [t, devices, isOpen, user.user_id])

	return (
		<>
			<NavChildList.Item
				blockStatus={blockStatus}
				isActive={isActive}
				isOpen={isOpen}
				onClick={onUserClick}
				user={user}
			/>

			<div className="flex flex-col" style={isLG ? styles.deviceAlignLG : styles.deviceAlignSM}>
				{Devices}
			</div>
		</>
	)
}

type NavDeviceItemProps = {
	readonly device: ManagedDevice
	readonly isOpen: boolean
	readonly isSelected: boolean
	readonly userId: string
	onClick(userId: string, deviceId: string): void
}

const NavDeviceItem = ({ device, isOpen, isSelected, onClick, userId }: NavDeviceItemProps) => {
	const handleClick = useKeyboardClick((ev) => {
		ev.stopPropagation()
		onClick(userId, device.device_id)
	}, !isOpen)

	return (
		<div
			key={device.device_id}
			className={cls('py-1.5 text-teal text-lg truncate', isSelected && 'font-bold')}
			{...handleClick}
		>
			{device.displayName}
		</div>
	)
}

// Gets Devices assigned to given user, including those that have
// been retired but have location history
const getHistoryDevices = (
	locationHistory: LocationHistory,
	user: ManagedUser,
	devices: ManagedDevice[],
	nonRetiredDevices: ManagedDevice[],
) => {
	// Current devices that are managed and assigned to the child
	const childDevices = nonRetiredDevices.filter((device) => device.users.includes(user.user_id))

	// Go through each device in history and add it to device list if it's not already in there
	for (const userId of keys(locationHistory?.[user.user_id])) {
		// If device is already in the list we skip
		if (childDevices.some((d) => d.device_id === userId)) continue

		const device = devices.find((d) => d.device_id === userId)

		if (device) childDevices.push(device)
	}

	return childDevices
}

const styles = createStyles({
	deviceAlignLG: {
		marginLeft: NAV_LIST_ITEM_LG + NAV_LIST_ITEM_GAP_LG,
	},

	deviceAlignSM: {
		marginLeft: NAV_LIST_ITEM_SM + NAV_LIST_ITEM_GAP_SM,
	},
})
