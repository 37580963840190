import { getSessionStorage } from '@op/react-web'
import throttle from 'lodash/throttle'
import { v4 } from 'uuid'

export type ServerSub = 'hfbk' | 'sfsh'

export const getPrefixedEndpoint = (prefix: string, sub: ServerSub) => {
	if (process.env.APP_ENV !== 'dev') return ''

	const prefixedSub = prefix ? prefix + '-' + sub : sub

	return `https://${prefixedSub}.ourpact.com`
}

export const normalizePrefixUrl = (prefix: string, sub: ServerSub) =>
	process.env.APP_ENV !== 'dev' ?
		''
	:	getPrefixedEndpoint(parseUrlPrefix(getPrefixedEndpoint(prefix, sub)), sub)

export const parseUrlPrefix = (url: string): string => {
	if (process.env.APP_ENV !== 'dev') return ''

	try {
		const parsedUrl = new URL(url)
		// FIXME: When strn/hfbk change to a redirect, or are removed, remove them
		//  from this regexp matcher.
		const prefixMatch = parsedUrl.hostname.match(
			/^([A-Z0-9-]+(?=-(?:app|hfbk|sfsh|strn)\.ourpact\.com$))/i,
		)

		return prefixMatch?.[1] || ''
	} catch {
		return ''
	}
}

const sessionStorage = ((ss) => {
	const tryCatch = <T>(fn: () => T, key: string, action: 'delete' | 'read' | 'write') => {
		try {
			return fn()
		} catch (e) {
			console.error(`Failed to ${action} storage key ${key}`, e)
		}
	}

	return {
		delete: (k: string) => tryCatch(() => ss.removeItem(k), k, 'delete'),
		get: (k: string) => tryCatch(() => ss.getItem(k), k, 'read'),
		set: (k: string, v: string) => tryCatch(() => ss.setItem(k, v), k, 'write'),
	}
})(getSessionStorage())

const EPH_ID_KEY = '__OURPACT_EPH__'

/**
 * Entity to get and clear the "session device id". This is really just a UUID
 * that we generate and keep for the session, since web doesn't have access to
 * a unique device id (udid). This is cleared when we log out and immediately
 * regenerated.
 */
export const SessionDeviceId = (() => {
	let ephId: string

	const createEphId = () => {
		ephId = `eph_${v4()}`.slice(0, 36)

		sessionStorage.set(EPH_ID_KEY, ephId)

		return ephId
	}

	return {
		clear: () => {
			sessionStorage.delete(EPH_ID_KEY)
			return createEphId()
		},

		get: () => {
			const persistedEphId = sessionStorage.get(EPH_ID_KEY)

			return (ephId = persistedEphId || createEphId())
		},
	}
})()

export type ServerEndpoints = {
	readonly hfbk: string
	readonly sfsh: string
}

const HFBK_KEY = '__OURPACT_HFBK__'
const SFSH_KEY = '__OURPACT_SFSH__'

// Throttle this so it's not thrashing reads
export const getEndpoints = throttle(
	(): ServerEndpoints => {
		// Base endpoints come from env
		const endpoints: Writable<ServerEndpoints> = {
			hfbk: process.env.OURPACT_HFBK,
			sfsh: process.env.OURPACT_SFSH,
		}

		if (process.env.APP_ENV !== 'dev') return endpoints

		// If we're in dev, first try to parse the prefix from the url
		const prefix = parseUrlPrefix(window.location.href)

		// If we have a prefix, set the initial urls based on that
		if (prefix) {
			endpoints.hfbk = getPrefixedEndpoint(prefix, 'hfbk')
			endpoints.sfsh = getPrefixedEndpoint(prefix, 'sfsh')
		}

		// Finally, try to use endpoints from storage if available.
		const hfbk = sessionStorage.get(HFBK_KEY)
		const sfsh = sessionStorage.get(SFSH_KEY)

		if (hfbk && sfsh) {
			endpoints.hfbk = hfbk
			endpoints.sfsh = sfsh
		}

		return endpoints
	},
	3000,
	{ leading: true, trailing: false },
)

export const setEndpoints = (endpoints: ServerEndpoints) => {
	if (process.env.APP_ENV !== 'dev') return

	sessionStorage.set(HFBK_KEY, endpoints.hfbk)
	sessionStorage.set(SFSH_KEY, endpoints.sfsh)

	// Clear out any waiting throttle so any immediately subsequent calls get
	// the updated values.
	getEndpoints.cancel()
}

export const ACCOUNT_TOKEN_EXPIRY_MINUTES = process.env.APP_ENV === 'dev' ? 5 : 20
