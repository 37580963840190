import type { SimpleDims } from '../widgets/Carousel'

/**
 * Calculates the size of background image using "contain"
 * @param img HTMLImageElement used to measure intrinsic dimensions
 * @param height Height of the containing canvas
 * @param width Width of the containing canvas
 */
export const getBgContainSize = (
	img: HTMLImageElement,
	height: number,
	width: number,
): SimpleDims => {
	const { naturalHeight, naturalWidth } = img
	const imgRatio = naturalWidth / naturalHeight
	const canvasRatio = width / height

	if (imgRatio > canvasRatio) {
		height = width / imgRatio
	} else if (imgRatio < canvasRatio) {
		width = height * imgRatio
	}

	return { height, width }
}
