import { assertNotNullish, setIfNotEqual } from '@eturi/util'
import { keysToBool, size } from '@op/util'
import { createSelector, createSlice, type Draft, isAnyOf, type Selector } from '@reduxjs/toolkit'
import { castDraft } from 'immer'
import forOwn from 'lodash/forOwn'
import isEmpty from 'lodash/isEmpty'
import mapValues from 'lodash/mapValues'
import reduce from 'lodash/reduce'
import { createSliceTransformer } from 'rtk-slice-transformer'
import { resetAction } from '../actions'
import { bindCreateAsyncThunkToState } from '../bindCreateAsyncThunkToState'
import type { HttpExtra } from '../http'
import type {
	DeviceApp,
	GranDirectives,
	IdPayloadAction,
	InitState,
	NormalizedRawGran,
	OmnibusKey,
	RawGran,
	RawOmnibusDirectives,
	ScheduleGran,
	ScheduleGranPut,
	SThunkState,
} from '../types'
import {
	createIdPayloadPrepare,
	DEFAULT_OMNIBUS,
	isDefaultOmnibusType,
	mapGranDirectivesToServerPut,
	mapRawToServerPutOmnibus,
	pickIdPayload,
	SpecialApp,
} from '../types'

export type UserGranState = InitState & {
	readonly granularity: RawGran
}

export const createUserGranState = (): UserGranState => ({
	granularity: /*@__PURE__*/ {},
	isInit: false,
})

export type GranState = {
	readonly [userId: string]: UserGranState
}

export type WithGranState = {
	readonly granularity: GranState
}

const initialState: GranState = {}

const ensureUserState = (s: Draft<GranState>, userId: string) =>
	castDraft((s[userId] ||= createUserGranState()))

export const granularitySlice = /*@__PURE__*/ createSlice({
	name: 'granularity',
	initialState,
	reducers: {
		updateAppDirectives: {
			prepare: createIdPayloadPrepare<GranDirectives>(),
			reducer(s, a: IdPayloadAction<GranDirectives>) {
				const [id, updatedAppDirectives] = pickIdPayload(a)
				const appDirectives = ensureUserState(s, id).granularity.app_directives

				if (!appDirectives) return

				// Delete any directives that are default 'S', otherwise, update value
				forOwn(updatedAppDirectives, (updatedDirective, bundleId) => {
					if (updatedDirective === 'S') {
						// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
						delete appDirectives[bundleId]
					} else {
						appDirectives[bundleId] = updatedDirective
					}
				})
			},
		},

		updateOmnibus: {
			prepare: createIdPayloadPrepare<RawOmnibusDirectives>(),
			reducer(s, a: IdPayloadAction<RawOmnibusDirectives>) {
				const [id, updatedOmnibus] = pickIdPayload(a)
				const omnibus = ensureUserState(s, id).granularity.omnibus_directives

				if (!omnibus) return

				// Delete any default omnibus directives, otherwise, update value
				forOwn(updatedOmnibus, (updatedGranType, omnibusKey: OmnibusKey) => {
					if (isDefaultOmnibusType(omnibusKey, updatedGranType)) {
						// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
						delete omnibus[omnibusKey]
					} else {
						omnibus[omnibusKey] = updatedGranType as any
					}
				})
			},
		},

		updateScheduleGran: {
			prepare: createIdPayloadPrepare<ScheduleGranPut>(),
			reducer(s, a: IdPayloadAction<ScheduleGranPut>) {
				const [id, updatedScheduleAppRules] = pickIdPayload(a)
				const scheduleGranularity = ensureUserState(s, id).granularity.app_schedules

				if (!scheduleGranularity) return

				forOwn(updatedScheduleAppRules, (updatedScheduleGranMap, bundleId) => {
					const currentScheduleGranMap = (scheduleGranularity[bundleId] ||= {})

					forOwn(updatedScheduleGranMap, (updatedScheduleGran, scheduleId) => {
						// If we have granularity, overwrite it
						if (updatedScheduleGran) {
							currentScheduleGranMap[scheduleId] = updatedScheduleGran
							// If value is specifically null, remove it
						} else if (updatedScheduleGran === null) {
							// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
							delete currentScheduleGranMap[scheduleId]
						}
					})

					if (isEmpty(currentScheduleGranMap)) {
						// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
						delete scheduleGranularity[bundleId]
					}
				})
			},
		},
	},
	extraReducers: (builder) =>
		builder
			.addCase(resetAction, () => initialState)
			.addCase(fetchUserGran.fulfilled, (s, { meta, payload: granularity }) => {
				setIfNotEqual(s, meta.arg.userId, { granularity, isInit: true })
			}),
})

export const { updateAppDirectives, updateOmnibus, updateScheduleGran } = granularitySlice.actions

const isGranAction = /*@__PURE__*/ isAnyOf(updateAppDirectives, updateOmnibus, updateScheduleGran)

export const granularitySliceTransformer = /*@__PURE__*/ createSliceTransformer(
	granularitySlice,
	(s) =>
		mapValues(s, ({ isInit, granularity }) => ({
			isInit,
			granularity: {
				app_directives: size(granularity.app_directives),
				omnibus_directives: {
					...granularity.omnibus_directives,
					...keysToBool(granularity.omnibus_directives, [
						'webContentFilterBlacklist',
						'webContentFilterWhitelist',
					]),
				},
			},
		})),
	(a) => (isGranAction(a) ? null : a),
)

////////// Thunks //////////////////////////////////////////////////////////////

export type GranThunkState = SThunkState & WithGranState

const createAsyncThunk = /*@__PURE__*/ bindCreateAsyncThunkToState<GranThunkState>()

type FetchUserGranArg = HttpExtra & {
	readonly userId: string
}

export const fetchUserGran = /*@__PURE__*/ createAsyncThunk(
	'gran/user/fetch',
	async ({ userId, ...extra }: FetchUserGranArg, { dispatch, extra: { http } }) => {
		const rawGran = await dispatch(
			http.get<Maybe<RawGran>>(`/granularity_by_user?user_id=${userId}`, extra),
		)

		assertNotNullish(rawGran, 'RawGran')

		return rawGran
	},
	{
		condition: (arg, api) => {
			if (!arg.force && granularity$(api.getState())[arg.userId]?.isInit) return false
		},
	},
)

type UpdateUserAppDirectiveArg = {
	readonly app: DeviceApp
	readonly userId: string
}

// FIXME: try / catch client impl
export const updateUserAppDirective = /*@__PURE__*/ createAsyncThunk(
	'gran/user/appDirective/update',
	async ({ app, userId }: UpdateUserAppDirectiveArg, { dispatch, getState, extra: { http } }) => {
		const { bundleId, granularity } = app

		// Pull apart combo app
		const directives =
			bundleId === SpecialApp.APPLE_COMBO ?
				{ [SpecialApp.APPLE_CAMERA]: granularity, [SpecialApp.APPLE_FACETIME]: granularity }
			:	{ [bundleId]: granularity }

		const rawAppDirectives = granularity$(getState())[userId]?.granularity.app_directives || {}

		// Create a state holding the current values of the directives being updated
		// for the purpose of rollback on fail. For anything not found, we set to
		// schedule by default, to make sure we overwrite everything.
		const rollbackDirectives = reduce(
			directives,
			(dirs: Writable<GranDirectives>, _, bundleId) => {
				dirs[bundleId] = rawAppDirectives[bundleId] || 'S'
				return dirs
			},
			{},
		)

		dispatch(updateAppDirectives(userId, directives))

		try {
			await dispatch(
				http.put('/granularity_by_user', {
					data: [mapGranDirectivesToServerPut(directives), {}, userId],
				}),
			)
		} catch (e) {
			dispatch(updateAppDirectives(userId, rollbackDirectives))
			throw e
		}
	},
)

type UpdateUserOmnibusArg = {
	readonly omnibus: RawOmnibusDirectives
	readonly userId: string
}

// FIXME: try / catch client impl
export const updateUserOmnibus = /*@__PURE__*/ createAsyncThunk(
	'gran/user/omnibus/update',
	async ({ omnibus, userId }: UpdateUserOmnibusArg, { dispatch, getState, extra: { http } }) => {
		const currentOmnibus = granularity$(getState())[userId]?.granularity.omnibus_directives || {}

		// We get the current value, so we can roll back, if needed. We don't get
		// the raw value b/c we need to make sure the store will overwrite the
		// previous value, and this may be undefined.
		const rollbackOmnibus = reduce(
			omnibus,
			(dirs: RawOmnibusDirectives, _, key: OmnibusKey) => {
				;(dirs as any)[key] = currentOmnibus[key] || DEFAULT_OMNIBUS[key]
				return dirs
			},
			{},
		)

		// Update optimistically
		dispatch(updateOmnibus(userId, omnibus))

		try {
			await dispatch(
				http.put('/granularity_by_user', {
					data: [{}, mapRawToServerPutOmnibus(omnibus), userId],
				}),
			)
		} catch (e) {
			dispatch(updateOmnibus(userId, rollbackOmnibus))
			throw e
		}
	},
)

type UpdateUserScheduleGranArg = {
	readonly scheduleAppRuleChanges: ScheduleGranPut
	readonly userId: string
}

export const updateUserScheduleAppRules = /*@__PURE__*/ createAsyncThunk(
	'gran/user/scheduleAppRules/update',
	async (
		{ scheduleAppRuleChanges, userId }: UpdateUserScheduleGranArg,
		{ dispatch, getState, extra: { http } },
	) => {
		const modAppRuleChanges: Writable<ScheduleGranPut> = { ...scheduleAppRuleChanges }
		const specialValue = modAppRuleChanges[SpecialApp.APPLE_COMBO]

		// NOTE: Specifically checking undefined, b/c null is a valid value for this PUT
		if (specialValue !== undefined) {
			// Pull apart SpecialApp combo
			modAppRuleChanges[SpecialApp.APPLE_CAMERA] = modAppRuleChanges[SpecialApp.APPLE_FACETIME] =
				specialValue
			// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
			delete modAppRuleChanges[SpecialApp.APPLE_COMBO]
		}

		const currentScheduleAppRules =
			granularity$(getState())[userId]?.granularity.app_schedules || {}

		// We get the current value, so we can roll back, if needed. We don't get the raw value b/c
		// we need to make sure the store will overwrite the previous value, and this may be undefined.
		const rollbackScheduleAppRules = reduce(
			modAppRuleChanges,
			(changes: DeepWritable<ScheduleGranPut>, scheduleRuleChanges, bundleId) => {
				const changesForBundleId = (changes[bundleId] ||= {})
				const currentGran = currentScheduleAppRules[bundleId]

				// If it exists then use existing value, if it didn't exist then we set to null,
				// so it's removed
				forOwn(scheduleRuleChanges, (_, scheduleId) => {
					changesForBundleId[scheduleId] = currentGran?.[scheduleId] || null
				})

				return changes
			},
			{},
		)

		// Update optimistically
		dispatch(updateScheduleGran(userId, modAppRuleChanges))

		try {
			await dispatch(
				http.put('/granularity_by_user', { data: [{}, {}, userId, modAppRuleChanges] }),
			)
			return
		} catch (e) {
			dispatch(updateScheduleGran(userId, rollbackScheduleAppRules))
			throw e
		}
	},
)

////////// Selectors ///////////////////////////////////////////////////////////

const granularity$ = <T extends WithGranState>(s: T) => s.granularity

/**
 * NOTE: We don't export the state selector raw, because we to normalize the SpecialApp cases first.
 *  We could do this in the result we get from the server, but I don't want to have to migrate
 *  the model to a different shape / version.
 */
export const createUserGranSelector = (userIdSelector$: Selector<any, Maybe<string>>) =>
	createSelector(granularity$, userIdSelector$, (granState, userId): NormalizedRawGran => {
		const rawUserGran = (granState[userId || ''] || createUserGranState()).granularity
		const normalizedAppDirs: Writable<GranDirectives> = {}
		const normalizedScheduleGran: Writable<ScheduleGran> = {}

		forOwn(rawUserGran.app_directives!, (gran, rawBundleId) => {
			if (rawBundleId === SpecialApp.APPLE_FACETIME) return

			const bundleId =
				rawBundleId === SpecialApp.APPLE_CAMERA ? SpecialApp.APPLE_COMBO : rawBundleId

			normalizedAppDirs[bundleId] = gran
		})

		forOwn(rawUserGran.app_schedules!, (scheduleGranMap, rawBundleId) => {
			if (!scheduleGranMap || rawBundleId === SpecialApp.APPLE_FACETIME) return

			const bundleId =
				rawBundleId === SpecialApp.APPLE_CAMERA ? SpecialApp.APPLE_COMBO : rawBundleId

			normalizedScheduleGran[bundleId] = scheduleGranMap
		})

		return {
			app_directives: normalizedAppDirs,
			app_schedules: normalizedScheduleGran,
			omnibus_directives: rawUserGran.omnibus_directives || {},
		}
	})
