import cls from 'classnames'
import type { TestIDValue } from '../TestID'

/**
 * Types for button components that allow them to use various tags, and in
 * using those tags, gain ability to pass props specific to those tags.
 *
 * @example
 * ```
 * <Btn tag="a" href="/foo" target="_blank">Go to foo</Btn>
 * <Btn type="submit">Submit</Btn>
 * ```
 */
export type ButtonTags = 'a' | 'button' | 'div' | 'input' | 'label'
export type BtnColor = 'teal' | 'red' | 'gray'
export type BtnSize = 'sm' | 'md' | 'lg'

/**
 * Props including `tag` prop, mapping the element attributes to the tag type
 * @see ButtonTags
 */
export type ButtonBaseProps<T extends ButtonTags = 'button'> = MOmit<
	JSX.IntrinsicElements[T],
	'color'
> & {
	readonly active?: boolean
	readonly color?: BtnColor
	readonly 'data-testid'?: TestIDValue
	readonly fat?: boolean
	readonly invert?: boolean
	readonly size?: BtnSize
	readonly tag?: T
}

const BtnColorMap = {
	gray: 'btn-gray',
	red: 'btn-red',
	teal: 'btn-teal',
} satisfies { readonly [C in BtnColor]: string }

const BtnInvertColorMap = {
	gray: 'btn-gray-invert',
	red: 'btn-red-invert',
	teal: 'btn-teal-invert',
} satisfies { readonly [C in BtnColor]: string }

export const getBtnCls = (
	active: boolean,
	color: BtnColor,
	fat: boolean,
	invert: boolean,
	size: BtnSize,
	...rest: cls.ArgumentArray
) =>
	cls(
		active && 'btn-active',
		fat && 'btn-fat',
		size === 'lg' && 'btn-lg',
		size === 'sm' && 'btn-sm',
		active || invert ? BtnInvertColorMap[color] : BtnColorMap[color],
		...rest,
	)
/**
 * Simple function to normalize the button type and warn if a `button` tag
 * with type="button" does not contain a proper handler.
 */
export const getBtnType = <T extends ButtonTags>(tag: T, p: any) => {
	const type = tag === 'button' && !p.type ? 'button' : undefined

	// In opposition to HTML standard, button elements without `type` defined are
	// by default "button", rather than "submit". We use this much more frequently
	// as a generic button than as a submit button. This obviously means that
	// using this as a "submit" means explicitly passing that type.
	if (
		process.env.NODE_ENV === 'development' &&
		tag === 'button' &&
		type === 'button' &&
		!p.onClick
	) {
		console.warn(
			'Button component created without a "type" or click handler is probably a mistake',
			'Button component has a "type" of "button" by default, not "submit"',
		)
	}

	return type
}
