/// <reference types="@eturi/ts-helpers" />
/// <reference types="@op/images-types" />
/// <reference types="vite/client" />

import './styles/index.scss'

import { I18NextProvider } from '@eturi/react-i18next'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { AnalyticsRegion, initAnalytics } from './analytics'
import { App } from './App'
import { TextTrans } from './components/TextTrans'
import { PollingProvider } from './hooks/usePolling'
import { PersistGate } from './PersistGate'
import { initSentry } from './sentry'
import { store } from './store'
import { LoadPage, PageLoadingSpinner } from './widgets'

// eslint-disable-next-line
import i18n from './i18n'

initSentry()
initAnalytics()

const RootElement = document.getElementById('root')!
const ReactRoot = (
	<I18NextProvider i18n={i18n} textComponent={TextTrans}>
		<Provider store={store}>
			<PersistGate loading={<PageLoadingSpinner />} store={store as any}>
				<AnalyticsRegion>
					<PollingProvider>
						<LoadPage>
							<App />
						</LoadPage>
					</PollingProvider>
				</AnalyticsRegion>
			</PersistGate>
		</Provider>
	</I18NextProvider>
)

const root = createRoot(RootElement)
root.render(ReactRoot)

/**
 * Chrome and potentially any Chromium-based browser have issues when you set
 * the outline style of focused elements. Instead of using the default focus
 * behavior, all elements that can be focused, are given a :focus state when
 * tapped, touched, or clicked. This code fixes that by blurring when one of
 * these events occurs:
 *
 *   - 'click' events handle taps on mobile ('touchend' doesn't work)
 *   - 'pointercancel' handles when the user long presses, or clicks and drags
 *     outside the element. Doing so doesn't fire a click event until the user
 *     interacts with something else, but it does give the element focus.
 *   - 'pointerup' occurs before `click` on desktop so intercepts the fastest
 *     on desktop and avoids potential flashes.
 *
 * We use 'passive' 'passive' so this is as lightweight as possible, and we use
 * 'capture' to make sure we get the event before a child can `stopPropagation`
 */
;(() => {
	const doc = document as any
	const ignoreElements = ['BODY', 'DATALIST', 'INPUT', 'SELECT', 'TEXTAREA']

	;['click', 'pointercancel', 'pointerup'].forEach((e) => {
		document.addEventListener(
			e,
			() => {
				const activeElement = doc.activeElement

				if (
					activeElement &&
					!ignoreElements.includes(activeElement.tagName) &&
					activeElement.blur
				) {
					activeElement.blur()
				}
			},
			{ capture: true, passive: true },
		)
	})
})()

if (process.env.NODE_ENV === 'development') {
	;((w: any) => {
		w.$qsa = (q: string) => document.querySelectorAll(q)
		w.$qs = (q: string) => document.querySelector(q)
		w.$getStyle = ($el: Element, s: keyof CSSStyleDeclaration) => w.getComputedStyle($el)[s]

		w.$showCurrentAria = () => {
			Array.from(document.querySelectorAll('*')).forEach((el) => {
				const attrs = Array.from(el.attributes)
					.filter((attr) => attr.name.includes('aria') && attr.value)
					.map((attr) => `${attr.name}: ${attr.value}`)

				if (!attrs.length) return

				console.group(el)
				attrs.forEach((a) => console.log(a))
				console.groupEnd()
			})
		}

		w.$showElementTitles = () => {
			document.querySelectorAll('[title]').forEach((el) => {
				console.log(`${el.getAttribute('title')}`, el)
			})
		}
	})(window)
}
