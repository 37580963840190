import type { ManagedUser } from '@op/services'

/** The default list of colors in order of how they are chosen for each user slot */
export const DEFAULT_AVATAR_COLOR_TUPLE = [
	'#0F9321',
	'#9B47CC',
	'#FF812C',
	'#117DED',
	'#FF8ED0',
	'#5B36F5',
	'#008994',
	'#1749FF',
	'#DE392A',
	'#145E4A',
	'#ED038F',
	'#8D943E',
	'#851927',
	'#3574C8',
	'#714ECB',
	'#9A5656',
	'#AB7F19',
	'#08B9C2',
	'#70A800',
	'#FF9F1C',
]

/** How the colors are sorted in the UI color picker */
export const UI_SORTED_AVATAR_COLOR_INDICES = [
	17, 11, 19, 4 /* -----------------------------------Row 1----------------------------------- */,
	6, 16, 2, 10 /* ------------------------------------Row 2----------------------------------- */,
	13, 18, 8, 1 /* ------------------------------------Row 3----------------------------------- */,
	3, 0, 15, 14 /* ------------------------------------Row 4----------------------------------- */,
	7, 9, 12, 5 /* -------------------------------------Row 5----------------------------------- */,
]

export type PickerConflictColorTuple = [
	pickerUserWithColorConflict?: ManagedUser,
	colorConflictNewIdx?: number,
]

/**
 * If there is a color conflict between `pickerUser` and another user, this returns a tuple
 * containing the conflict user as well as a new color index to assign to the conflict user.
 * If there are no conflicts, the tuple is empty.
 *
 * @param children All active users to search for conflicts
 * @param pickerUser The user whose color is being picked
 * @param pickerColorIdx The index of the new color for `pickerUser`
 */
export const getUserWithColorConflict = (
	children: ManagedUser[],
	pickerUser: ManagedUser,
	pickerColorIdx: number,
): PickerConflictColorTuple => {
	const pickerUserWithColorConflict = children.find(
		(c) => c.user_id !== pickerUser.user_id && c.settings.slot === pickerColorIdx,
	)

	if (!pickerUserWithColorConflict) return []

	// If overwritten child exists, give it the next available index
	const colorConflictNewIdx = DEFAULT_AVATAR_COLOR_TUPLE.map((_, idx) => idx).find(
		(maybeAvailableIdx) => {
			// This index is available if no other child has this slot index assigned (other than
			// the currentColorIdx, which is being reassigned, and is therefore available).
			return !children.find(
				(u) =>
					u.settings.slot === maybeAvailableIdx && maybeAvailableIdx !== pickerUser?.settings.slot,
			)
		},
	)

	return [pickerUserWithColorConflict, colorConflictNewIdx]
}
