import { createContext } from 'react'

export type CarouselDragHandler = (isDragging: boolean) => void
export type CarouselTapHandler = (isScaled: boolean) => void

export type CarouselCtx = {
	readonly activeIdx: number
	readonly size: number
	onDrag(handler: CarouselDragHandler): () => void
	onTap(handler: CarouselTapHandler): () => void
	onZoom(handler: () => void): () => void
	setActiveIdx(index: number): void
}

export const CarouselCtx = createContext<CarouselCtx>(null as any)
