import type { LocationData, ManagedDevice, ManagedUser } from '@op/services'

export const PICK_GMAPS_DEVICE = ['device_id', 'displayName'] as const
export type GMapsDevice = Pick<ManagedDevice, (typeof PICK_GMAPS_DEVICE)[number]>

export const PICK_GMAPS_USER = [
	'avatar',
	'devices',
	'user_id',
	'user_location_active',
	'user_name',
] as const
export type GMapsUser = Pick<ManagedUser, (typeof PICK_GMAPS_USER)[number]>

export type GMapsData = {
	readonly device: GMapsDevice
	readonly location: LocationData
	readonly user: GMapsUser
}
