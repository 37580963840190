import { assertNotNullish } from '@eturi/util'
import { activeChildId$ } from '@op/services'
import { createResolverRedirect } from '../../common'
import { resolveSerialHasChildren } from '../../route-resolvers'

export const resolveBaseLocatorPage = /*@__PURE__*/ resolveSerialHasChildren((store) => {
	const activeChildId = activeChildId$(store.getState())

	assertNotNullish(activeChildId)

	throw createResolverRedirect(`/locator/${activeChildId}`)
})
