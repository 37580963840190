import { lazy, memo } from 'react'
import { LoadPage } from '../../widgets'
import { LocatorNavBar } from './LocatorNavBar'

const LocatorPageContent = /*@__PURE__*/ lazy(() => import('./LocatorPageContent'))

const LocatorPage = () => (
	<section className="locator-page page relative -my-4">
		<LocatorNavBar />

		<LoadPage>
			<LocatorPageContent />
		</LoadPage>
	</section>
)

export default /*@__PURE__*/ memo(LocatorPage)
