import { useFn } from '@eturi/react'
import { useMemo } from 'react'
import { disabledProps } from '../../common'
import { useIsScreenMobile } from '../../hooks'
import { CarouselIconBtn } from './CarouselIconBtn'
import { useCarousel } from './useCarousel'
import { useCarouselOverlay } from './useCarouselOverlay'

type CarouselBtnOverlayProps = {
	readonly shouldRenderMobileClose?: boolean
	readonly isVisibleOverride?: boolean
	onClose(): void
}

export const CarouselBtnOverlay = ({
	onClose,
	shouldRenderMobileClose = true,
}: CarouselBtnOverlayProps) => {
	const { activeIdx, setActiveIdx, size } = useCarousel()
	const isOverlayVisible = useCarouselOverlay()
	const isScreenMobile = useIsScreenMobile()

	const hasNext = activeIdx < size - 1
	const hasPrev = activeIdx > 0

	const handleNextClick = useFn(() => setActiveIdx(activeIdx + 1))
	const handlePrevClick = useFn(() => setActiveIdx(activeIdx - 1))

	// add translated titles, translated?
	const NextItemButton = useMemo(
		() => (
			<CarouselIconBtn
				{...disabledProps(!hasNext, 'absolute-center-y right-2')}
				icon="chevron-right"
				iconCls="-mr-1"
				hidden={isScreenMobile}
				invisible={!isOverlayVisible}
				onClick={handleNextClick}
			/>
		),
		[hasNext, isOverlayVisible, isScreenMobile],
	)

	const PrevItemButton = useMemo(
		() => (
			<CarouselIconBtn
				{...disabledProps(!hasPrev, 'absolute-center-y left-2')}
				iconCls="-ml-1"
				icon="chevron-left"
				hidden={isScreenMobile}
				invisible={!isOverlayVisible}
				onClick={handlePrevClick}
			/>
		),
		[hasPrev, isOverlayVisible, isScreenMobile],
	)

	const CloseButton = useMemo(
		() =>
			(!isScreenMobile || shouldRenderMobileClose) && (
				<CarouselIconBtn
					className="!absolute top-2 left-2 md:left-auto md:right-3"
					icon={isScreenMobile ? 'chevron-left' : 'x'}
					hidden={false}
					invisible={!isOverlayVisible}
					onClick={onClose}
				/>
			),
		[isScreenMobile, isOverlayVisible, shouldRenderMobileClose],
	)

	return (
		<>
			{PrevItemButton}
			{NextItemButton}
			{CloseButton}
		</>
	)
}
