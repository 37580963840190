// NOTE: The only difference with `prem_plus` and `prem_plus_trial`, is the
//  latter shows the `Start Free Trial` button text
export type SubscriptionType =
	| 'options'
	| 'prem_plus'
	| 'prem_plus_trial'
	| 'affiliate'
	| 'trial'
	| 'upgrade'

const SubscriptionsTypes: ReadonlySet<SubscriptionType> = new Set<SubscriptionType>([
	'options',
	'prem_plus',
	'prem_plus_trial',
	'affiliate',
	'trial',
	'upgrade',
])

export const isSubscriptionType = (type: any): type is SubscriptionType =>
	SubscriptionsTypes.has(type)
