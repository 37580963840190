// This utility function checks if the current device has a touch screen.
// It uses a one-time check by attempting to create a TouchEvent and returns
// true if successful. Note that this check is computed once and requires a
// page refresh to update, as resizing the window or using developer tools
// to switch to a touch device does not dynamically update the result.
export const isTouchDevice = (() => {
	try {
		document.createEvent('TouchEvent')
		return true
	} catch (e) {
		// TouchEvent creation failed, indicating a non-touch device
	}
	return false
})()
