import type { ButtonBaseProps, ButtonTags } from '../../types'
import { getBtnCls, getBtnType } from '../../types'
import { useBtnLoader } from './useBtnLoader'

type LoadingBtnProps<T extends ButtonTags = 'button'> = ButtonBaseProps<T> & {
	readonly isLoading: boolean
}

export const LoadingBtn = <T extends ButtonTags = 'button'>({
	active = false,
	color = 'teal',
	children,
	className,
	fat = false,
	invert = false,
	isLoading,
	size = 'md',
	tag = 'button' as T,
	...p
}: LoadingBtnProps<T>) => {
	const [btnCls, BtnChildLoader] = useBtnLoader(
		isLoading,
		getBtnCls(active, color, fat, invert, size, 'btn', className),
	)
	const type = getBtnType(tag, p)
	const Wrapper = tag as any

	return (
		<Wrapper {...p} className={btnCls} type={type}>
			<BtnChildLoader>{children}</BtnChildLoader>
		</Wrapper>
	)
}
