import { useWindowEvent } from '@eturi/react'
import { useResizeObserver } from '@op/react-web'
import cls from 'classnames'
import type { MutableRefObject, RefCallback, RefObject } from 'react'
import { type ReactNode, useCallback, useContext, useLayoutEffect, useRef } from 'react'
import { ModalFooterCtx } from './ModalFooterCtx'

type ModalFooterProps = {
	readonly children?: ReactNode
	readonly className?: string
	readonly fade?: boolean
	readonly stacked?: boolean
	readonly sxs?: boolean
}

// Very old Safari versions don't support `ResizeObserver`, and we have this component in the
// Data & Privacy screen. In case it's not supported, we add a regular resize handler.
const SUPPORTS_RESIZE_OBSERVER = typeof ResizeObserver === 'function'

export const ModalFooter = ({
	children,
	className,
	fade = true,
	stacked,
	sxs,
}: ModalFooterProps) => {
	let $footerCbRef: RefCallback<HTMLElement>
	let $footerElRef: RefObject<HTMLElement>
	const setCtxFooterHt = useContext(ModalFooterCtx)

	// NOTE: Conditional hooks are fine here because they are based on a constant.
	if (SUPPORTS_RESIZE_OBSERVER) {
		;[$footerCbRef, $footerElRef] = useResizeObserver((dims) => {
			try {
				setCtxFooterHt(dims.height)
			} catch {
				// Nada
			}
		})
	} else {
		$footerElRef = useRef<HTMLElement>(null)
		$footerCbRef = useCallback(
			(el: HTMLElement | null) =>
				(($footerElRef as MutableRefObject<HTMLElement | null>).current = el),
			[],
		)

		useLayoutEffect(() => {
			setCtxFooterHt($footerElRef.current?.offsetHeight || null)
		}, [])

		useWindowEvent('resize', () => {
			setCtxFooterHt($footerElRef.current?.offsetHeight || null)
		})
	}

	return (
		<div
			className="modal__bottom-fixed fixed bottom-0 left-0 right-0 z-20 z-hack"
			ref={$footerCbRef}
		>
			{fade && <div className="modal__bottom--fade" />}
			<div
				className={cls(
					'modal__bottom',
					stacked && 'modal__bottom--stacked',
					sxs && 'modal__bottom--sxs',
					className,
				)}
			>
				{children}
			</div>
		</div>
	)
}
