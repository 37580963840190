import { omit } from '@eturi/util'
import type { InitState } from '@op/services'
import { DEFAULT_LOCALE, toMajorMinorVersion } from '@op/services'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { createSliceTransformer, stripPayload } from 'rtk-slice-transformer'
import { UAParser } from 'ua-parser-js'
import { getUserAgent, isTouchDevice } from '../util'

export type BrowserInfoState = InitState & {
	readonly browserLocale: string
	readonly hasBrowserFocus: boolean
	readonly isNetworkOnline: boolean
	readonly pathname: string
	readonly userAgent: string
}

export type WithBrowserInfoState = {
	readonly browserInfo: BrowserInfoState
}

const initialState: BrowserInfoState = {
	browserLocale: DEFAULT_LOCALE,
	hasBrowserFocus: true,
	isInit: false,
	isNetworkOnline: true,
	pathname: window.location.pathname,
	userAgent: getUserAgent(),
}

export const browserInfoSlice = createSlice({
	name: 'browserInfo',
	initialState,
	reducers: {
		setBrowserInfoInit(s) {
			s.isInit = true
		},

		setHasBrowserFocus(s, a: PayloadAction<boolean>) {
			s.hasBrowserFocus = a.payload
		},

		setBrowserLocale(s, a: PayloadAction<string>) {
			s.browserLocale = a.payload
		},

		setNetworkOnline(s, a: PayloadAction<boolean>) {
			s.isNetworkOnline = a.payload
		},

		setPathname(s, a: PayloadAction<string>) {
			s.pathname = a.payload
		},

		setUserAgent(s, a: PayloadAction<string>) {
			s.userAgent = a.payload
		},
	},
})

export const {
	setBrowserInfoInit,
	setBrowserLocale,
	setHasBrowserFocus,
	setNetworkOnline,
	setPathname,
	setUserAgent,
} = browserInfoSlice.actions

const isStripPayloadAction = /*@__PURE__*/ isAnyOf(setBrowserLocale, setUserAgent)

export const browserInfoSliceTransformer = /*@__PURE__*/ createSliceTransformer(
	browserInfoSlice,
	(s) => omit(s, ['browserLocale', 'userAgent']),
	(a) =>
		setPathname.match(a) ? null
		: isStripPayloadAction(a) ? stripPayload(a)
		: a,
)

////////// Selector Helpers ////////////////////////////////////////////////////
const NEWER_IOS_VERSION = 12.2

const _isMobileDevice = (info: UAParser.IDevice, ua: string) =>
	(info.type && /mobile|tablet/.test(info.type)) ||
	/Mobile|Android|OurPact_Android|iP(ad|od|hone)|Fennec|mini/.test(ua)

const _isIOSModel = (name: string): boolean => name === 'iOS'

const _isMobileSafari = (ua: string): boolean =>
	/version\/([\w.]+).+?mobile\/\w+\s(safari)/i.test(ua)

const _isIPadAmbiguousSafari = ({ name, version }: UAParser.IBrowser): boolean => {
	if (!(name && version)) return false

	return (
		name.toLowerCase() === 'safari' && Number(toMajorMinorVersion(version)) >= 13 && isTouchDevice
	)
}

const _isSupportedIOS = (os: UAParser.IOS, browser: UAParser.IBrowser, ua: string): boolean =>
	_isIPadAmbiguousSafari(browser) || (_isIOSModel(os.name || '') && _isMobileSafari(ua))

const _isIOS = (os: UAParser.IOS, browser: UAParser.IBrowser): boolean =>
	_isIPadAmbiguousSafari(browser) || _isIOSModel(os.name || '')

const _isNewerIOSVersion = (os: UAParser.IOS, browser: UAParser.IBrowser): boolean =>
	Number(toMajorMinorVersion(os.version)) >= NEWER_IOS_VERSION || _isIPadAmbiguousSafari(browser)

////////// Selectors ///////////////////////////////////////////////////////////
const state$ = <T extends WithBrowserInfoState>(s: T) => s.browserInfo

export const browserLocale$ = /*@__PURE__*/ createSelector(state$, (s) => s.browserLocale)
export const hasBrowserFocus$ = /*@__PURE__*/ createSelector(state$, (s) => s.hasBrowserFocus)
export const isBrowserInfoInit$ = /*@__PURE__*/ createSelector(state$, (s) => s.isInit)
export const isNetworkOnline$ = /*@__PURE__*/ createSelector(state$, (s) => s.isNetworkOnline)
export const pathname$ = /*@__PURE__*/ createSelector(state$, (s) => s.pathname)
export const userAgent$ = /*@__PURE__*/ createSelector(state$, (s) => s.userAgent)
// NOTE: getMinNativeVersion is always just 4, so we can hard code it for now

export const uaParser$ = /*@__PURE__*/ createSelector(userAgent$, (ua) => new UAParser(ua))
export const browserInfo$ = /*@__PURE__*/ createSelector(uaParser$, (p) => p.getBrowser())
export const deviceInfo$ = /*@__PURE__*/ createSelector(uaParser$, (p) => p.getDevice())
export const osInfo$ = /*@__PURE__*/ createSelector(uaParser$, (p) => p.getOS())
export const isAndroidDevice$ = /*@__PURE__*/ createSelector(osInfo$, (os) => os.name === 'Android')
export const isIOS$ = /*@__PURE__*/ createSelector(osInfo$, browserInfo$, _isIOS)
export const isMobileDevice$ = /*@__PURE__*/ createSelector(
	deviceInfo$,
	userAgent$,
	_isMobileDevice,
)
export const isNewerIOSDevice$ = /*@__PURE__*/ createSelector(
	osInfo$,
	browserInfo$,
	_isNewerIOSVersion,
)
export const isSupportedIOS$ = /*@__PURE__*/ createSelector(
	osInfo$,
	browserInfo$,
	userAgent$,
	_isSupportedIOS,
)
