import { useMounted, useStateGetter } from '@eturi/react'
import type { Location } from 'history'
import { useLayoutEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

type LocationHistoryState = {
	readonly from?: string
}

type LocationHistoryListener = Location & {
	readonly state?: LocationHistoryState
}

/**
 * Hook for intercepting the back button. These shenanigans will push a
 * unique state to the browser w/ the current path this allows us intercept
 * the back button and do something else.
 *
 * - NOTE: This should be used with care, as it injects history records.
 *    If multiple components in the same route use this, it will likely cause
 *    issues.
 */
export const useBackButton = (onBack: () => void | false, disable = false) => {
	const history = useHistory()
	const location = useLocation()
	const [didStopBack, setDidStopBack] = useStateGetter(false)
	const [didHitStopHandler, setDidHitStopHandler] = useStateGetter(false)
	const isMounted = useMounted()

	useLayoutEffect(() => {
		if (disable) return

		const originalPathname = location.pathname
		let didPopHistory = false

		history.push(originalPathname)

		const removeHistoryListener = history.listen(({ pathname, state }: LocationHistoryListener) => {
			if (state && state.from) {
				// If a link is pressed then we want to we want to go to that new route. We can only
				// determine that if where we're coming from is the same as the original pathname. This
				// way we know we're trying to leave the current pathname purposely
				if (pathname !== originalPathname && originalPathname === state.from) {
					// We set didPopHistory to true because that's how we determine if on dismount
					// we should didPopHistory to determine if we should trigger goBack()
					didPopHistory = true

					// Assume that if the pathname changes w/ state.from, we intend to actually close.
					return onBack()
				}
			}

			// If the back button is pressed we need to know b/c there are multiple
			// ways of exiting fullscreen. In this case we know the back button is
			// pressed, so we don't need to clean up the history entry we injected
			// by calling `goBack()`
			didPopHistory = true

			if (didStopBack() && !didHitStopHandler()) {
				return setDidHitStopHandler(true)
			}

			setDidHitStopHandler(false)

			if (onBack() === false) {
				window.setTimeout(() => history.push(originalPathname))

				if (isMounted()) {
					setDidStopBack(true)
				}

				return
			} else {
				isMounted() && setDidStopBack(false)
			}

			if (didStopBack()) return

			// However, if instead this listener is triggered by the user navigating
			// to a different route, we have an issue. The route will contain the
			// superfluous history record of the original path. So if the user then
			// presses back, they will go to the previous route, but if they press it
			// again, they will stay at that previous route.
			//
			// To fix this, we trigger a `goBack` to pop off the current record,
			// which leaves us still with the two original records. Then we have to
			// set a timeout and replace the superfluous record with the new one.
			// You'd think this would leave us with exactly the correct number of
			// records, but it doesn't. It actually leaves us in the same state we
			// would have been with the normal goBack state, but at the new route.
			//
			// As an example, say we're on route /foo and so have two /foo records:
			// - If the user hits the browser back or we trigger onBack and trigger
			//   goBack ourselves, we're left at path /foo, with a superfluous record
			//   to go forward to path /foo
			// - If the user instead navigates to /bar, with this situation, we end
			//   up at state /bar, with a back record to /foo, and a superfluous
			//   forward record to /bar.
			//
			// We've tried many things to manipulate this, but to no avail.
			//
			// We also want to make sure we handle logout properly, if the new
			// route is /logout we don't want to go back
			if (pathname !== originalPathname && pathname !== '/logout') {
				history.goBack()
				window.setTimeout(() => history.replace(pathname))
			}
		})

		return () => {
			removeHistoryListener()

			// If we didn't exit using the back button, make sure to remove the entry.
			// NOTE: There is no way to make it so the user can't go forward to this
			//  phantom state.
			didPopHistory || history.goBack()
		}
	}, [disable])
}
