import { useConstant, useFn } from '@eturi/react'
import type { FormState } from 'final-form'
import { useMemo, useState } from 'react'
import isEqual from 'react-fast-compare'
import { Form, FormSpy, useForm } from 'react-final-form'
import { minLength, required } from '../../validators'
import {
	Btn,
	Check,
	CheckboxGroup,
	CheckBtn,
	CheckField,
	FormLabel,
	ModalFooter,
	Radio,
	RadioBtn,
	type RadioCheckSize,
	RadioField,
	RadioGroup,
} from '../../widgets'

export const DemoFormsView = () => {
	const initialFormValues = useConstant(
		(): RadioCheckTestPageFormValues => ({
			checkGroupButtons: [CheckButtonType.C],
			checkGroupLabelled: [CheckLabelledType.BUTTONS_SPACED],
			radioGroupButtons: RadioButtonType.B,
			radioGroupLabelled: RadioLabelledType.md,
		}),
	)

	const [radioCheckOpts, setRadioCheckOpts] = useState(() => initialFormValues.checkGroupLabelled)
	const [radioCheckSize, setRadioCheckSize] = useState<RadioCheckSize>(
		() => initialFormValues.radioGroupLabelled as RadioCheckSize,
	)

	const handleFormChange = useFn((fs: FormState<RadioCheckTestPageFormValues>) => {
		const {
			values: { checkGroupLabelled, radioGroupLabelled },
		} = fs

		if (radioGroupLabelled !== radioCheckSize)
			setRadioCheckSize(radioGroupLabelled as RadioCheckSize)

		if (checkGroupLabelled && !isEqual(checkGroupLabelled, radioCheckOpts)) {
			setRadioCheckOpts(checkGroupLabelled)
		}
	})

	const groupsWithLabelsProps = useMemo(
		(): GroupsWithLabelsProps => ({
			isReversed: radioCheckOpts.includes(CheckLabelledType.LABELLED_REVERSED),
			isSpaced: radioCheckOpts.includes(CheckLabelledType.LABELLED_SPACED),
			size: radioCheckSize,
		}),
		[radioCheckOpts, radioCheckSize],
	)

	const groupsWithButtonsProps = useMemo(
		(): GroupsWithButtonsProps => ({
			isReversed: radioCheckOpts.includes(CheckLabelledType.BUTTONS_REVERSED),
			isSpaced: radioCheckOpts.includes(CheckLabelledType.BUTTONS_SPACED),
			isStacked: radioCheckOpts.includes(CheckLabelledType.BUTTONS_STACKED),
			size: radioCheckSize,
		}),
		[radioCheckOpts, radioCheckSize],
	)

	const handleSubmit = useFn(() => {
		/**/
	})

	return (
		<div className="is-page-pad-touch">
			<h2 className="mb-5 text-3xl text-center">Forms</h2>

			<Form
				onSubmit={handleSubmit}
				initialValues={initialFormValues}
				render={(fp) => (
					<form onSubmit={fp.handleSubmit} style={{ padding: '15px' }}>
						<RadioLabelsGroupTest {...groupsWithLabelsProps} />
						<CheckboxLabelsGroupTest {...groupsWithLabelsProps} />
						<CheckboxButtonsGroupTest {...groupsWithButtonsProps} />
						<RadioButtonGroupTest {...groupsWithButtonsProps} />

						<FormStateOutput {...fp} />

						<FormSpy subscription={{ values: true }} onChange={handleFormChange} />

						<ModalFooter stacked>
							<Btn type="submit">Submit</Btn>
							<Btn onClick={fp.form.reset}>Reset</Btn>
						</ModalFooter>
					</form>
				)}
			/>
		</div>
	)
}

const FormStateOutput = ({
	errors,
	submitFailed,
	submitSucceeded,
	valid,
	values,
}: FormState<any>) => (
	<pre>
		<code>{JSON.stringify({ errors, submitFailed, submitSucceeded, valid, values }, null, 2)}</code>
	</pre>
)

const CHECK_BUTTONS_NAME = 'checkGroupButtons'
const CHECK_LABELS_NAME = 'checkGroupLabelled'
const H3_STYLES = { marginBottom: '2rem' }
const RADIO_BUTTONS_NAME = 'radioGroupButtons'
const RADIO_LABELS_NAME = 'radioGroupLabelled'

type CheckLabelledType = ValueUnion<typeof CheckLabelledType>
const CheckLabelledType = {
	BUTTONS_REVERSED: 'areButtonsReversed',
	BUTTONS_SPACED: 'areButtonsSpaced',
	BUTTONS_STACKED: 'areButtonsStacked',
	LABELLED_REVERSED: 'areLabelledReversed',
	LABELLED_SPACED: 'areLabelledSpaced',
}
const ALL_CHECK_LABEL_TYPES = /*@__PURE__*/ Object.values(CheckLabelledType)

type CheckButtonType = ValueUnion<typeof CheckButtonType>
const CheckButtonType = {
	A: 'CheckboxGroupButtonA',
	B: 'CheckboxGroupButtonB',
	C: 'CheckboxGroupButtonC',
}

type RadioButtonType = ValueUnion<typeof RadioButtonType>
const RadioButtonType = {
	A: 'RadioGroupButtonA',
	B: 'RadioGroupButtonB',
	C: 'RadioGroupButtonC',
}

type RadioLabelledType = ValueUnion<typeof RadioLabelledType>
const RadioLabelledType = {
	xs: 'xs',
	sm: 'sm',
	md: 'md',
}

type GroupsWithLabelsProps = {
	readonly isReversed: boolean
	readonly isSpaced: boolean
	readonly size: RadioCheckSize
}

type GroupsWithButtonsProps = {
	readonly isReversed: boolean
	readonly isSpaced: boolean
	readonly isStacked: boolean
	readonly size: RadioCheckSize
}

type RadioCheckTestPageFormValues = {
	readonly checkGroupButtons: CheckButtonType[]
	readonly checkGroupLabelled: CheckLabelledType[]
	readonly radioGroupButtons: RadioButtonType
	readonly radioGroupLabelled: RadioLabelledType
}

const CheckboxButtonsGroupTest = (p: GroupsWithButtonsProps) => (
	<div>
		<h3 style={H3_STYLES}>Checkbox Group Buttons</h3>

		<div className="field">
			<FormLabel label="Select two or more" {...p}>
				<CheckboxGroup name={CHECK_BUTTONS_NAME} validate={minLength('Select at least two!', 2)}>
					<CheckField
						name={CHECK_BUTTONS_NAME}
						value={CheckButtonType.A}
						render={({ input }) => (
							<CheckBtn {...input} size={p.size}>
								A
							</CheckBtn>
						)}
					/>

					<CheckField
						name={CHECK_BUTTONS_NAME}
						value={CheckButtonType.B}
						render={({ input }) => (
							<CheckBtn {...input} size={p.size}>
								B
							</CheckBtn>
						)}
					/>

					<CheckField
						name={CHECK_BUTTONS_NAME}
						value={CheckButtonType.C}
						render={({ input }) => (
							<CheckBtn {...input} size={p.size}>
								C
							</CheckBtn>
						)}
					/>
				</CheckboxGroup>
			</FormLabel>
		</div>
	</div>
)

const CheckboxLabelsGroupTest = (p: GroupsWithLabelsProps) => {
	const form = useForm<RadioCheckTestPageFormValues>()
	const checkTypes = form.getState().values.checkGroupLabelled
	const isFullySelected = ALL_CHECK_LABEL_TYPES.every((v) => checkTypes.includes(v))
	const isIndeterminate = !isFullySelected && checkTypes.length > 0

	return (
		<div>
			<h3 style={H3_STYLES}>Checkbox Group Labelled</h3>

			<div className="field">
				<CheckboxGroup isStacked={true}>
					<Check
						checked={isFullySelected}
						onChange={() => {
							form.change('checkGroupLabelled', isFullySelected ? [] : ALL_CHECK_LABEL_TYPES)
						}}
						isIndeterminate={isIndeterminate}
						{...p}
					>
						Select All
					</Check>

					<CheckField
						name={CHECK_LABELS_NAME}
						value={CheckLabelledType.BUTTONS_REVERSED}
						render={({ input }) => (
							<Check {...input} {...p}>
								Reverse Labelled Buttons
							</Check>
						)}
					/>

					<CheckField
						name={CHECK_LABELS_NAME}
						value={CheckLabelledType.BUTTONS_SPACED}
						render={({ input }) => (
							<Check {...input} {...p}>
								Space Labelled Buttons (Unstacked Only)
							</Check>
						)}
					/>

					<CheckField
						name={CHECK_LABELS_NAME}
						value={CheckLabelledType.BUTTONS_STACKED}
						render={({ input }) => (
							<Check {...input} {...p}>
								Stack Labelled Buttons
							</Check>
						)}
					/>

					<CheckField
						name={CHECK_LABELS_NAME}
						value={CheckLabelledType.LABELLED_REVERSED}
						render={({ input }) => (
							<Check {...input} {...p}>
								Reverse Individually Labelled
							</Check>
						)}
					/>

					<CheckField
						name={CHECK_LABELS_NAME}
						value={CheckLabelledType.LABELLED_SPACED}
						render={({ input }) => (
							<Check {...input} {...p}>
								Space Individually Labelled
							</Check>
						)}
					/>
				</CheckboxGroup>
			</div>
		</div>
	)
}
const RadioButtonGroupTest = (p: GroupsWithButtonsProps) => (
	<div>
		<h3 style={H3_STYLES}>Radio Group Buttons</h3>

		<div className="field">
			<FormLabel label="Select a radio button" {...p}>
				<RadioGroup name={RADIO_BUTTONS_NAME} validate={required('Select a radio button value')}>
					<RadioField
						name={RADIO_BUTTONS_NAME}
						value={RadioButtonType.A}
						render={({ input }) => (
							<RadioBtn {...input} size={p.size} tabIdx={0}>
								A
							</RadioBtn>
						)}
					/>

					<RadioField
						name={RADIO_BUTTONS_NAME}
						value={RadioButtonType.B}
						render={({ input }) => (
							<RadioBtn {...input} size={p.size} tabIdx={1}>
								B
							</RadioBtn>
						)}
					/>

					<RadioField
						name={RADIO_BUTTONS_NAME}
						value={RadioButtonType.C}
						render={({ input }) => (
							<RadioBtn {...input} size={p.size} tabIdx={2}>
								C
							</RadioBtn>
						)}
					/>
				</RadioGroup>
			</FormLabel>
		</div>
	</div>
)

const RadioLabelsGroupTest = (p: GroupsWithLabelsProps) => (
	<div>
		<h3 style={H3_STYLES}>Select Size (Radio Group Labelled)</h3>

		<div className="field">
			<RadioGroup
				isStacked={true}
				name={RADIO_LABELS_NAME}
				validate={required('Select a radio with label')}
			>
				<RadioField
					name={RADIO_LABELS_NAME}
					value={RadioLabelledType.xs}
					render={({ input }) => (
						<Radio {...input} {...p}>
							Xtra Small
						</Radio>
					)}
				/>

				<RadioField
					name={RADIO_LABELS_NAME}
					value={RadioLabelledType.sm}
					render={({ input }) => (
						<Radio {...input} {...p}>
							Small
						</Radio>
					)}
				/>

				<RadioField
					name={RADIO_LABELS_NAME}
					value={RadioLabelledType.md}
					render={({ input }) => (
						<Radio {...input} {...p}>
							Medium
						</Radio>
					)}
				/>
			</RadioGroup>
		</div>
	</div>
)
