import type { CssGlyphName } from '@op/icons'
import cls from 'classnames'
import type { BtnSize, ButtonBaseProps, ButtonTags } from '../../types'
import { getBtnCls, getBtnType } from '../../types'
import { Glyph } from '../Glyph'

export type ActionBtnIconPos = 'L' | 'R'

export type ActionBtnProps<T extends ButtonTags = 'button'> = ButtonBaseProps<T> & {
	readonly icon: CssGlyphName
	readonly iconCls?: string
	readonly iconPos?: ActionBtnIconPos
	readonly wrapClassName?: string
}

export const ActionBtn = <T extends ButtonTags = 'button'>(p: ActionBtnProps<T>) => {
	const [btnCls, content, Wrapper, type, restProps] = getActionBtnProps(p)

	return (
		<Wrapper {...restProps} className={btnCls} type={type}>
			{content}
		</Wrapper>
	)
}

const BtnSizeMap = {
	lg: 'action-btn-lg',
	md: 'action-btn-md',
	sm: 'action-btn-sm',
} satisfies { [S in BtnSize]: string }

export const getActionBtnProps = <T extends ActionBtnProps<any>>(originalProps: T) => {
	const {
		active = false,
		color = 'teal',
		children,
		className,
		fat = false,
		icon,
		iconCls,
		iconPos = 'L',
		invert = false,
		size = 'md',
		tag = 'button',
		wrapClassName,
		...restProps
	} = originalProps

	const hasContent = Boolean(children)
	const btnCls = getBtnCls(
		active,
		color,
		fat,
		invert,
		size,
		'action-btn',
		BtnSizeMap[size],
		hasContent && 'action-text-btn',
		className,
	)
	const type = getBtnType(tag, originalProps)
	const content = (
		<span
			className={cls(
				'flex-center h-full w-full',
				iconPos === 'R' && 'flex-row-reverse',
				wrapClassName,
			)}
		>
			<Glyph className={iconCls} name={icon} size={size} />
			{hasContent && <span className={iconPos === 'L' ? 'ml-3' : 'mr-3'}>{children}</span>}
		</span>
	)

	return [btnCls, content, tag, type, restProps] as const
}
