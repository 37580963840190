import cls from 'classnames'

type CarouselPageControlProps = {
	readonly className?: string
	readonly pageCount: number
	readonly page: number
}

export const CarouselPageControl = ({ className, pageCount, page }: CarouselPageControlProps) => (
	<div className={cls('flex flex-row m-2 items-center justify-center', className)}>
		{Array.from({ length: pageCount }, (_, i) => (
			<div
				key={i}
				className={cls('h-2 w-2 me-2 bg-gray-40 rounded-full', i === page && 'bg-text h-3 w-3')}
			/>
		))}
	</div>
)
