import { Trans, useT } from '@eturi/react-i18next'
import type { DeviceBlockStatus, ManagedDevice } from '@op/services'
import { getDesiredVewBlockState } from '@op/services'
import isNumber from 'lodash/isNumber'
import type { ReactNode } from 'react'
import { useMemo } from 'react'
import { DateTime } from '../../widgets'
import ChildStatusPendingText from '../ChildStatus/ChildStatusPendingText'
import { useStatusTextColor } from '../ChildStatus/useStatusColor'
import DeviceBattery from './DeviceBattery'
import { DeviceFigure } from './DeviceFigure'

type DeviceStatusProps = {
	readonly device: ManagedDevice
	readonly status: DeviceBlockStatus
}

export const DeviceStatus = ({ device, status }: DeviceStatusProps) => {
	const [t] = useT('devices.device')
	const isManaged = status.viewState !== 'unmanaged'
	const deviceBatteryLife = device.attributes?.battery_level
	const desiredViewState = getDesiredVewBlockState(status.status, status.viewState)
	const color = useStatusTextColor(desiredViewState)

	// FIXME: Remove individual pending strings from translations and use shared
	//  pending text helper.
	const PrettyStatusText = useMemo(() => {
		if (!isManaged) return ''

		if (status.viewState === 'pending') {
			return (
				<ChildStatusPendingText
					desiredViewState={getDesiredVewBlockState(status.status, status.viewState)}
				/>
			)
		}

		const statusKey =
			status.viewState === 'paused' ? 'paused'
			: status.status === 'blocked' ? 'blocked'
			: 'granted'

		return (
			<Trans
				i18nKey="block_statuses.access_blockedOrGranted"
				tOptions={{ color, blockedOrGranted: t.$(`block_statuses.${statusKey}`) }}
			/>
		)
	}, [t, status, isManaged])

	return (
		<div>
			<DeviceFigure className="mb-4" device={device} status={status}>
				{PrettyStatusText}
			</DeviceFigure>

			{device.displayName !== device.model_name && (
				<DeviceStatusInfo title={t('name')}>
					<p>{device.displayName}</p>
				</DeviceStatusInfo>
			)}

			<DeviceStatusInfo title={t.$('devices.model')}>
				<p>{device.model_name}</p>
			</DeviceStatusInfo>

			<DeviceStatusInfo title={t('paired_date')}>
				<DateTime
					date={isManaged ? device.created : device.last_activity || device.created}
					format="LLL"
				/>
			</DeviceStatusInfo>

			<DeviceStatusInfo title={t.$('devices.battery_life.battery_life')}>
				{isNumber(deviceBatteryLife) ?
					<>
						<DeviceBattery
							battery={deviceBatteryLife}
							className="h-7 w-7 self-center -mt-0.5 mr-2.5 md:mt-0"
						/>

						<p>
							{t('last_seen')}{' '}
							<DateTime date={device.last_activity || device.created} format="LLL" />
						</p>
					</>
				:	<div>
						<p className="mb-2">{t.$('devices.battery_life.no_battery_info_title')}</p>

						<ul className="list-disc pl-5">
							<li>{t.$('devices.battery_life.no_battery_info_reason_1')}</li>
							<li>{t.$('devices.battery_life.no_battery_info_reason_2')}</li>
						</ul>
					</div>
				}
			</DeviceStatusInfo>
		</div>
	)
}

type DeviceStatusInfoProps = {
	readonly children: ReactNode
	readonly title: ReactNode
}

const DeviceStatusInfo = ({ children, title }: DeviceStatusInfoProps) => (
	<div className="flex text-sm sm:text-base">
		<p className="font-bold mr-3 whitespace-nowrap">{title}:</p>
		{children}
	</div>
)
