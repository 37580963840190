import './DeviceFigure.scss'

import type { DeviceBlockStatus, ManagedDevice } from '@op/services'
import cls from 'classnames'
import type { ReactNode } from 'react'
import { useMemo } from 'react'
import { StatusIndicator } from '../StatusIndicator'

type DeviceFigureProps = {
	readonly children?: ReactNode
	readonly className?: string
	readonly device: ManagedDevice
	readonly status: DeviceBlockStatus
}

// LOL
const TABLET_REGEX = /(ipad|tab)/

export const DeviceFigure = ({
	children,
	className,
	device: { model, os },
	status: { viewState },
}: DeviceFigureProps) => {
	const DeviceCls = useMemo(() => {
		const icon =
			os === 'iOS' || os === 'android' ?
				TABLET_REGEX.test(model) ?
					'tablet'
				:	'phone'
			:	'pc'

		return `device-figure__graphic device-figure__graphic--${icon}`
	}, [model, os])

	return (
		<div className={cls('device-figure', className)}>
			<div className={DeviceCls}>
				<StatusIndicator className="device-figure__status" status={viewState} />
			</div>

			<div className="device-figure__name ellipsis">{children}</div>
		</div>
	)
}
