import { useBoolState, useConstant, useFn } from '@eturi/react'
import FULLSCREEN_THUMB_1 from '@op/assets/gallery/fullscreen_1.png'
import FULLSCREEN_THUMB_2 from '@op/assets/gallery/fullscreen_2.png'
import FULLSCREEN_THUMB_3 from '@op/assets/gallery/fullscreen_3.png'
import FULLSCREEN_THUMB_4 from '@op/assets/gallery/fullscreen_4.png'
import FULLSCREEN_THUMB_5 from '@op/assets/gallery/fullscreen_5.png'
import FULLSCREEN_THUMB_6 from '@op/assets/gallery/fullscreen_6.png'
import FULLSCREEN_THUMB_7 from '@op/assets/gallery/fullscreen_7.png'
import { useLayoutEffect, useState } from 'react'
import { Btn, Modal } from '../../widgets'
import { BottomSheet } from '../../widgets/BottomSheet/lazy'
import { useCarouselImgDims } from '../../widgets/Carousel'
import type { CarouselItemProps } from '../../widgets/Carousel/Carousel'
import { CarouselBtnOverlay } from '../../widgets/Carousel/CarouselBtnOverlay'
import { CarouselPageControl } from '../../widgets/Carousel/CarouselPageControl'
import { ContainedCarousel } from '../../widgets/Carousel/ContainedCarousel'
import { FullscreenCarousel } from '../../widgets/Carousel/FullscreenCarousel'

export const DemoCarouselView = () => {
	const fsImgs = useConstant(() => [
		FULLSCREEN_THUMB_1,
		FULLSCREEN_THUMB_2,
		FULLSCREEN_THUMB_3,
		FULLSCREEN_THUMB_4,
		FULLSCREEN_THUMB_5,
		FULLSCREEN_THUMB_6,
		FULLSCREEN_THUMB_7,
	])

	const renderFSImage = useFn(({ item }: CarouselItemProps<string>) => (
		<figure
			className="bg-center bg-contain bg-no-repeat flex grow h-full w-full"
			style={{ backgroundImage: `url(${item})` }}
		/>
	))

	const [activePage, setActivePage] = useState(0)
	const [activeFsIdx, setActiveFsIdx] = useState(0)
	const [isModalCarouselVisible, showModalCarousel, hideModalCarousel] = useBoolState(false)
	const [isFullScreenCarouselVisible, showFullScreenCarousel, hideFullScreenCarousel] =
		useBoolState(false)
	const [isBottomSheetCarouselVisible, showBottomSheetCarousel, hideBottomSheetCarousel] =
		useBoolState(false)

	const fsDims = useCarouselImgDims(isFullScreenCarouselVisible ? fsImgs[activeFsIdx] : undefined)

	useLayoutEffect(() => {
		setActivePage(0)
	}, [isBottomSheetCarouselVisible, isModalCarouselVisible])

	return (
		<div className="is-page-pad-touch">
			<h2 className="mb-5 text-3xl text-center">Carousels</h2>

			<Btn className="m-3" onClick={showModalCarousel}>
				Modal Carousel
			</Btn>
			<Btn className="m-3" onClick={showFullScreenCarousel}>
				Full Screen Carousel
			</Btn>
			<Btn className="m-3" onClick={showBottomSheetCarousel}>
				Bottom Sheet Carousel
			</Btn>

			{isModalCarouselVisible && (
				<Modal header="Modal Carousel" onClose={hideModalCarousel}>
					<ContainedCarousel<string>
						className="flex mx-auto w-64 h-96 md:w-96 overflow-hidden"
						items={fsImgs}
						keyExtractor={(item: string) => item}
						renderItem={renderFSImage}
						onChange={(_, idx) => setActivePage(idx)}
						onClose={hideModalCarousel}
					/>

					<CarouselPageControl pageCount={fsImgs.length} page={activePage} />
				</Modal>
			)}

			{isFullScreenCarouselVisible && (
				<FullscreenCarousel
					dims={fsDims}
					items={fsImgs}
					renderItem={renderFSImage}
					onChange={(_, idx) => setActiveFsIdx(idx)}
					onClose={hideFullScreenCarousel}
					zoom
				>
					<CarouselBtnOverlay onClose={hideFullScreenCarousel} />
				</FullscreenCarousel>
			)}

			{isBottomSheetCarouselVisible && (
				<BottomSheet onClose={hideBottomSheetCarousel}>
					<ContainedCarousel<string>
						className="flex mx-auto w-64 h-96 md:w-96 overflow-hidden mb-3"
						items={fsImgs}
						keyExtractor={(item: string) => item}
						onChange={(_, idx) => setActivePage(idx)}
						renderItem={renderFSImage}
						onClose={hideBottomSheetCarousel}
					/>
					<CarouselPageControl pageCount={fsImgs.length} page={activePage} />
				</BottomSheet>
			)}
		</div>
	)
}
