import { unixNow } from '@eturi/date-util'
import { assertNotNullish, notEmpty, pick, setIfNotEqual } from '@eturi/util'
import { toParamsURL } from '@op/util'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import mapValues from 'lodash/mapValues'
import size from 'lodash/size'
import { createSliceTransformer } from 'rtk-slice-transformer'
import { v4 } from 'uuid'
import { resetAction } from '../actions'
import { bindCreateAsyncThunkToState, unwrapThunks } from '../bindCreateAsyncThunkToState'
import type { HttpExtra } from '../http'
import type {
	DeviceHistoryMap,
	Geofence,
	GeofenceInfo,
	InitState,
	LocationAddressMap,
	LocationHistory,
	LocationInfo,
	LocationLookupRes,
	RawGeofenceInfo,
	RawLatLon,
	RawLocationHistory,
	RawLocationInfo,
	SThunkState,
} from '../types'
import {
	mapGeofenceInfoToRaw,
	mapRawLocationLookup,
	mapRawToGeofenceInfo,
	mapRawToLocationHistory,
	mapRawToLocationInfo,
} from '../types'
import type { WithUserState } from './user.slice'
import { rawSortedFilteredChildren$ } from './user.slice'

export type GeoState = InitState & {
	readonly geofences: RawGeofenceInfo
	readonly geofencesTs: number
	readonly locationAddressMap: LocationAddressMap
	readonly locationHistory: LocationHistory
	readonly locationHistoryDate: Maybe<string> // Format YYYY-MM-DD
	// FIXME: Rename this shit
	readonly locationHistoryTs: number
	readonly locations: LocationInfo
}

export type WithGeoState = {
	readonly geo: GeoState
}

const initialState: GeoState = {
	geofences: {},
	geofencesTs: 0,
	isInit: false,
	locationAddressMap: {},
	locationHistory: {},
	locationHistoryDate: null,
	locationHistoryTs: 0,
	locations: {},
}

type SetUserLocationDeviceHistory = {
	readonly deviceHistory: DeviceHistoryMap
	readonly userId: string
}

export const geoSlice = /*@__PURE__*/ createSlice({
	name: 'geo',
	initialState,
	reducers: {
		resetLocationHistoryDate(s) {
			s.locationHistoryDate = null
		},

		// NOTE: This is tied to location history so be careful where this is set
		setLocationHistoryDate(s, a: PayloadAction<string>) {
			s.locationHistoryDate = a.payload
		},

		setLocations(s, a: PayloadAction<LocationInfo>) {
			setIfNotEqual(s, 'locations', a.payload)
		},

		setUserLocationDeviceHistory(s, { payload }: PayloadAction<SetUserLocationDeviceHistory>) {
			setIfNotEqual(s.locationHistory, payload.userId, payload.deviceHistory)
		},
	},
	extraReducers: (builder) =>
		builder
			.addCase(resetAction, () => initialState)
			.addCase(fetchGeofences.fulfilled, (s, a) => {
				setIfNotEqual(s, 'geofences', a.payload)
				s.geofencesTs = unixNow()
			})
			.addCase(fetchLocations.fulfilled, (s, a) => {
				setIfNotEqual(s, 'locations', a.payload)
			})
			.addCase(fetchLocationAddresses.fulfilled, (s, a) => {
				for (const address in a.payload) {
					s.locationAddressMap[address] = a.payload[address]
				}
			})
			.addCase(fetchLocationHistory.fulfilled, (s, a) => {
				// If the dates don't match then we don't update state
				// can happen if users change date while call is pending
				if (s.locationHistoryDate !== a.meta.arg.date) return
				const locationHistory = a.payload
				for (const id in locationHistory) {
					setIfNotEqual(s.locationHistory, id, locationHistory[id])
				}

				s.locationHistoryTs = Date.now()
			})
			.addCase(fetchGeofencesAndLocations.fulfilled, (s) => {
				s.isInit = true
			})
			.addCase(removeGeofence.fulfilled, (s, a) => {
				// eslint-disable-next-line @typescript-eslint/no-dynamic-delete
				delete s.geofences[a.meta.arg]
			})
			.addCase(updateGeofences.fulfilled, (s, a) => {
				const rawGeofenceInfo: RawGeofenceInfo = a.payload

				for (const id in rawGeofenceInfo) {
					s.geofences[id] = rawGeofenceInfo[id]
				}
			}),
})

export const {
	resetLocationHistoryDate,
	setLocationHistoryDate,
	setLocations,
	setUserLocationDeviceHistory,
} = geoSlice.actions

export const geoSliceTransformer = /*@__PURE__*/ createSliceTransformer(
	geoSlice,
	(s: GeoState) => ({
		isInit: s.isInit,
		geofences: mapValues(s.geofences, (g) => pick(g, ['active', 'radius', 'triggers', 'users'])),
		locations: mapValues(s.locations, (l) => ({
			...l,
			device_info: mapValues(l.device_info, (d) => ({
				...d,
				location: size(d.location),
			})),
		})),
	}),
)

////////// Thunks //////////////////////////////////////////////////////////////

export type GeoThunkState = SThunkState & WithUserState & WithGeoState

const createAsyncThunk = /*@__PURE__*/ bindCreateAsyncThunkToState<GeoThunkState>()

export const addGeofence = /*@__PURE__*/ createAsyncThunk(
	'geofence/add',
	async (geofence: Geofence, { dispatch }) => {
		await dispatch(updateGeofences({ [v4()]: geofence })).unwrap()
	},
)

export const fetchGeofencesAndLocations = /*@__PURE__*/ createAsyncThunk(
	'geofencesAndLocations/fetch',
	async (extra: HttpExtra = {}, { dispatch }) => {
		await Promise.all(
			unwrapThunks([dispatch(fetchGeofences(extra)), dispatch(fetchLocations(extra))]),
		)
	},
	{
		condition: (arg, api) => {
			if (!arg?.force && isGeoInit$(api.getState())) return false
		},
	},
)

/**
 * FIXME: We're sending back this `modified_after` timestamp, and yet we replace
 *  all the geofences in the model. This is what OurPact does as well. I don't
 *  currently understand this, b/c based on the semantics, I would think the
 *  server would only send back geofences modified after the ts. If that's the
 *  case, it would seem we would want to merge the geofences rather than replace
 *  them in the model.
 */
export const fetchGeofences = /*@__PURE__*/ createAsyncThunk(
	'geofences/fetch',
	async (extra: HttpExtra = {}, { dispatch, getState, extra: { http } }) => {
		const geoFetchTs = geoFetchTs$(getState())
		const geoInfo = await dispatch(
			http.get<Maybe<RawGeofenceInfo>>(`/geofences?modified_after=${geoFetchTs}`, extra),
		)

		assertNotNullish(geoInfo, 'RawGeofenceInfo')

		return geoInfo
	},
)

/**
 * Fetches the `RawLocationInfo` and maps to decorated. Note that when called without a specific
 * `user_id` argument, the server returns location info only for non-retired, managed users. Thus,
 * we don't need to worry about filtering out these users or devices ourselves in selectors.
 *
 * The result is decorated before storing, b/c they are read only objects that aren't ever updated
 * in our app, and have properties that we want to filter out for the sake of performance
 * (last_activity, last_seen, etc)
 */
export const fetchLocations = /*@__PURE__*/ createAsyncThunk(
	'geolocations/fetch',
	async (extra: HttpExtra = {}, { dispatch, extra: { http } }) => {
		const rawLocInfo = await dispatch(
			http.get<Maybe<RawLocationInfo>>('/user_location?last=1', extra),
		)

		assertNotNullish(rawLocInfo, 'RawLocationInfo')

		return mapRawToLocationInfo(rawLocInfo)
	},
)

export type FetchLocationHistoryArgs = HttpExtra & {
	readonly userId: string
	readonly date: string // ISO Date string
}

export const fetchLocationHistory = /*@__PURE__*/ createAsyncThunk(
	'geolocations/fetchHistory',
	async ({ userId, date }: FetchLocationHistoryArgs, { dispatch, extra: { http } }) => {
		const rawLocHistory = await dispatch(
			http.get<Maybe<RawLocationHistory>>(
				toParamsURL('/location_history', {
					date,
					user_id: userId,
				}),
			),
		)

		assertNotNullish(rawLocHistory, 'RawLocationHistoryInfo')

		// map to decorated values
		return mapRawToLocationHistory(rawLocHistory)
	},
	{
		condition: (arg, api) => {
			// Make sure user has geo enabled before making history request
			if (
				!rawSortedFilteredChildren$(api.getState()).some(
					(c) => c.user_id === arg.userId && c.user_location_active,
				)
			) {
				return false
			}
		},
	},
)

type FetchReverseGeocodeAddress = HttpExtra & {
	readonly addresses: RawLatLon[]
}

export const fetchLocationAddresses = /*@__PURE__*/ createAsyncThunk(
	'geolocations/reverseGeocode',
	async ({ addresses }: FetchReverseGeocodeAddress, { dispatch, extra: { http } }) => {
		const rawLocationLookup = await dispatch(
			http.get<LocationLookupRes>(
				toParamsURL('/location_lookup', {
					lookup: JSON.stringify({
						type: 'reverse_geocode',
						latlons: addresses,
					}),
				}),
			),
		)

		return mapRawLocationLookup(rawLocationLookup)
	},
)

export const removeGeofence = /*@__PURE__*/ createAsyncThunk(
	'geofence/delete',
	async (geofenceId: string, { dispatch, extra: { http } }) => {
		await dispatch(http.delete('/geofences', { data: [[geofenceId]] }))
	},
)

export const updateGeofences = /*@__PURE__*/ createAsyncThunk(
	'geofences/update',
	async (geoInfo: GeofenceInfo, { dispatch, extra: { http } }) => {
		const rawGeoInfo = mapGeofenceInfoToRaw(geoInfo)

		await dispatch(http.put('/geofences', { data: [rawGeoInfo] }))

		return rawGeoInfo
	},
)

////////// Selectors ///////////////////////////////////////////////////////////

const state$ = <T extends WithGeoState>(s: T) => s.geo

export const locationHistoryDate$ = /*@__PURE__*/ createSelector(
	state$,
	(s) => s.locationHistoryDate,
)
export const geoFetchTs$ = /*@__PURE__*/ createSelector(state$, (s) => s.geofencesTs)
export const isGeoInit$ = /*@__PURE__*/ createSelector(state$, (s) => s.isInit)
export const locationAddressMap$ = /*@__PURE__*/ createSelector(state$, (s) => s.locationAddressMap)
export const locationInfo$ = /*@__PURE__*/ createSelector(state$, (s) => s.locations)
export const locationHistory$ = /*@__PURE__*/ createSelector(state$, (s) => s.locationHistory)
export const locationHistoryTs$ = /*@__PURE__*/ createSelector(state$, (s) => s.locationHistoryTs)
export const rawGeoInfo$ = /*@__PURE__*/ createSelector(state$, (s) => s.geofences)
export const geoInfo$ = /*@__PURE__*/ createSelector(rawGeoInfo$, mapRawToGeofenceInfo)
export const hasGeoInfo$ = /*@__PURE__*/ createSelector(rawGeoInfo$, notEmpty)
export const hasLocationInfo$ = /*@__PURE__*/ createSelector(locationInfo$, notEmpty)
