import { timeoutPromise } from '@eturi/util'
import { lazy } from 'react'

export const Ably = /*@__PURE__*/ lazy(
	() =>
		import(
			/* webpackChunkName: 'ably' */
			/* webpackPrefetch: -100 */
			'../Ably/Ably'
		),
)

let didDelayChat = false

/**
 * We delay the first-time loading of the chat component so it doesn't interfere
 * with other stuff loading first. Technically, chat is only rendered once, and
 * we don't need to worry about `didDelayChat`, but it's a good practice just
 * in case.
 */
export const Chat = /*@__PURE__*/ lazy(async () => {
	const loadChat = () =>
		import(
			/* webpackChunkName: 'chat' */
			/* webpackPrefetch: -1000 */
			'./Chat'
		)

	if (didDelayChat) return loadChat()

	await timeoutPromise(2000)

	didDelayChat = true

	return loadChat()
})

export const EmailVerificationModal = /*@__PURE__*/ lazy(
	() =>
		import(
			/* webpackChunkName: 'email-verification-modal' */
			/* webpackPrefetch: 0 */
			'./EmailVerification/EmailVerificationModal'
		),
)

export * from './GuardModal/lazy'
export * from './Navigation/lazy'
export * from './OCR/lazy'
export * from './PaymentDetails/lazy'
export * from './Products/lazy'
export * from './Promo/lazy'
export * from './Restrix/lazy'
export * from './VewSettings/lazy'
