import {
	IOS_DEBOUNCE_SCROLL,
	useDebounce,
	useFn,
	useMaybeState,
	useRefGetter,
	useWindowEvent,
} from '@eturi/react'
import { useT } from '@eturi/react-i18next'
import type { PremTier } from '@op/services'
import { PREM_NAME, PREM_PLUS_NAME } from '@op/services'
import { useDrag } from '@use-gesture/react'
import cls from 'classnames'
import type { Ref } from 'react'
import {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useLayoutEffect,
	useRef,
	useState,
} from 'react'
import { useAnalytics } from '../../analytics'
import { useIsScreenMD } from '../../hooks'
import { TestID } from '../../TestID'
import { LoadEmpty, Modal } from '../../widgets'
import { BottomSheet } from '../../widgets/BottomSheet/lazy'
import { ChooseSubSelectBox } from './ChooseSubSelectBox'
import { ChooseSubSelectCheck } from './ChooseSubSelectCheck'
import { SeeMoreBtn } from './SeeMoreBtn'
import { SubFeatures } from './SubFeatures'
import { TierBanner } from './TierBanner'
import { TierFeature } from './TierFeature'

// FIXME: We need to revisit hidePremPlus, just because it's supported doesn't
//  mean it is something we might ever do, it doesn't make sense to give a
//  discount on our lesser tier but not on our highest tier. Ideally we can remove
//  this and simplify
type SelectTiersProps = {
	// Some views specifically show only one tier at a time, such as upgrade to
	// prem + or coupons that have only certain tiers available
	readonly hidePrem: boolean
	readonly hidePremPlus: boolean
	readonly selected: PremTier
	onSelect(tier: PremTier): void
}

// Allow scrolling to a tier imperatively
export type SelectTierInstance = {
	isTierVisible(tier: PremTier): boolean
	scrollTo(tier: PremTier): void
}

export const SelectTier = forwardRef(
	(
		{ hidePrem, hidePremPlus, selected, onSelect }: SelectTiersProps,
		ref: Ref<SelectTierInstance>,
	) => {
		const [t] = useT()
		const track = useAnalytics()
		const isScreenMd = useIsScreenMD()
		const $scrollElRef = useRef<HTMLDivElement>(null)
		const [isDragging, setDragging] = useRefGetter(false)
		const [maxScroll, setMaxScroll] = useRefGetter(0)

		const [seeMoreTier, _setSeeMoreTier, clearSeeMoreTier] = useMaybeState<PremTier>(null)
		const [shouldDragSnap, setShouldDragSnap] = useState(false)
		const [snapToTier, setSnapToTier] = useState(selected)

		const isPrem = selected === 'premium'
		const isPremPlus = selected === 'premium_plus'
		const hasHiddenPlan = hidePrem || hidePremPlus

		const setSeeMoreTier = (tier: PremTier) => {
			_setSeeMoreTier(tier)
			track('Interact', { action: 'click', name: 'See More Button', tier })
		}

		const scrollTo = useFn((tier: PremTier = snapToTier) => {
			$scrollElRef.current?.scrollTo({
				behavior: 'smooth',
				left: tier === 'premium' ? maxScroll() : 0,
			})
		})

		useImperativeHandle(ref, () => ({
			isTierVisible: (tier) => snapToTier === tier,
			scrollTo,
		}))

		/**
		 * Calculate the snapToTier based on scroll position. This function runs when the
		 * user drag is completed, or on scroll. We use the additional scroll handler b/c
		 * the user can perform a momentum scroll that gets past the snap distance, but
		 * doesn't get past the "drag" distance.
		 */
		const snapToScroll = useDebounce(() => {
			const scrollLeft = $scrollElRef.current?.scrollLeft || 0
			const snapDistance = maxScroll() / SNAP_DISTANCE_FRACTION

			const nextSnapToTier =
				(snapToTier === 'premium' ?
					scrollLeft <= maxScroll() - snapDistance && 'premium_plus'
				:	scrollLeft >= snapDistance && 'premium') || snapToTier

			setSnapToTier(nextSnapToTier)
			scrollTo(nextSnapToTier)
		}, IOS_DEBOUNCE_SCROLL)

		const bind = useDrag(
			({ last }) => {
				// Start dragging so onScroll doesn't trigger
				setDragging(true)

				if (!last) return

				// Snap to scroll when finished dragging
				snapToScroll()
				// Stop dragging when finished
				setDragging(false)
			},
			// Since we are using this just to track whether we're dragging, we need to use touch events,
			// rather than pointer events. This is because, when scrolling starts, the `onPointerUp`
			// event no longer fires. Thus, pointer events cannot be used to track dragging within a
			// scrollable area. This is why the docs suggest setting the touch-action CSS property to
			// 'none'.
			{ axis: 'x', pointer: { touch: true } },
		)

		const handleScroll = useFn(() => !isDragging() && snapToScroll())

		const handleSelect = useFn((tier: PremTier) => {
			setSnapToTier(tier)
			onSelect(tier)
		})

		/**
		 * Set the scroll metrics when mounted and on resize, and make sure the left scroll
		 * is correct for the `snapToTier`:
		 *
		 * - Calculate maximum scroll distance
		 * - Calculate whether snapping behavior should be applied
		 * - Set the scroll left position
		 *
		 * Note that we set `shouldDragSnap` here, rather than making it a simple value
		 * derived from `maxScroll`, because `maxScroll` is a ref, not a state. `shouldDragSnap`
		 * needs to be a state, but it's a simple boolean, so it makes sense to split them up
		 * so that setting a bunch of different `maxScroll` values doesn't trigger tons of
		 * superfluous renders.
		 */
		const setScroll = () => {
			const $el = $scrollElRef.current

			if (!$el) return

			const maxScroll = $el.scrollWidth - $el.offsetWidth

			// Set max scroll and then set scroll left, to make sure snapToTier remains
			// in the correct position
			setMaxScroll(maxScroll)
			setShouldDragSnap(maxScroll > DRAG_SNAP_THRESHOLD)

			$el.scrollLeft = snapToTier === 'premium' ? maxScroll : 0
		}

		useWindowEvent('resize', setScroll)

		// Initial scroll calculations
		useLayoutEffect(setScroll, [])

		// Turn off dragging if `shouldDragSnap` is false
		useEffect(() => {
			if (!shouldDragSnap) setDragging(false)
		}, [shouldDragSnap])

		// Perform smooth scroll when `snapToTier` changes
		useEffect(scrollTo, [snapToTier])

		return (
			<div
				ref={$scrollElRef}
				className={cls(
					'flex gap-x-8 lg:gap-x-16 pl-3 touch-scroll-x sm:px-4 lg:px-0 lg:overflow-visible',
					// NOTE: Need to add compensatory padding on right when hiding prem, otherwise,
					//  box will overflow container at xs res.
					hasHiddenPlan ? 'justify-center pr-3' : 'lg:justify-around',
				)}
				{...(shouldDragSnap ? { onScroll: handleScroll, ...bind() } : undefined)}
			>
				<ChooseSubSelectBox
					className="p-3"
					selected={isPremPlus}
					onSelect={() => handleSelect('premium_plus')}
					testId={TestID.SUBSCRIPTION_SELECT_TIER_TID$$}
				>
					<div className="radio-check-target">
						<div className="flex items-center justify-between mb-2">
							<ChooseSubSelectCheck
								className={cls(hidePrem && 'invisible')}
								selected={hidePrem ? false : isPremPlus}
							/>

							<p className="font-bold uppercase text-sm">
								{t('subscription_features.most_popular')}
							</p>

							<ChooseSubSelectCheck className="invisible" selected={false} />
						</div>

						<TierBanner selected={isPremPlus} text={PREM_PLUS_NAME} />

						<TierFeature
							color="text-ft-monitor"
							icon="vew-now"
							subtitle={t('subscription_features.monitor_activity_desc')}
							title={t('subscription_features.monitor_activity')}
						/>

						<TierFeature
							color="text-ft-locator"
							icon="places"
							subtitle={t('subscription_features.locator_prem_plus_desc')}
							title={t('subscription_features.family_locator')}
						/>

						<TierFeature
							color="text-ft-screentime"
							icon="clock"
							subtitle={t('subscription_features.screentime_desc_prem_plus')}
							title={t('subscription_features.screentime_management')}
						/>

						<TierFeature
							color="text-ft-supervision"
							icon="add-user"
							subtitle={t('subscription_features.supervision_desc')}
							title={t('subscription_features.supervision')}
						/>
					</div>

					<SeeMoreBtn onClick={setSeeMoreTier} tier="premium_plus" />
				</ChooseSubSelectBox>

				{!hidePrem && (
					<ChooseSubSelectBox
						onSelect={() => handleSelect('premium')}
						selected={isPrem}
						className="p-3 self-center"
						testId={TestID.SUBSCRIPTION_SELECT_TIER_TID$$}
					>
						<div className="radio-check-target">
							<ChooseSubSelectCheck
								className={cls(hidePremPlus && 'invisible')}
								selected={hidePremPlus ? false : isPrem}
							/>

							<TierBanner selected={isPrem} text={PREM_NAME} />

							<TierFeature
								color="text-ft-locator"
								icon="places"
								subtitle={t('subscription_features.locator_prem_desc')}
								title={t('subscription_features.family_locator')}
							/>

							<TierFeature
								color="text-ft-screentime"
								icon="clock"
								subtitle={t('subscription_features.screentime_desc')}
								title={t('subscription_features.screentime_management')}
							/>

							<TierFeature
								color="text-ft-supervision"
								icon="add-user"
								subtitle={t('subscription_features.supervision_desc')}
								title={t('subscription_features.supervision')}
							/>
						</div>

						<SeeMoreBtn onClick={setSeeMoreTier} tier="premium" />
					</ChooseSubSelectBox>
				)}

				{!isScreenMd && seeMoreTier && (
					<LoadEmpty>
						<BottomSheet onClose={clearSeeMoreTier} panDownToClose>
							<div className="pb-6 px-4">
								<SubFeatures tier={seeMoreTier} />
							</div>
						</BottomSheet>
					</LoadEmpty>
				)}

				{isScreenMd && seeMoreTier && (
					<Modal
						header={t('subscription_features.feature_header', {
							tier: seeMoreTier === 'premium_plus' ? PREM_PLUS_NAME : PREM_NAME,
						})}
						onClose={clearSeeMoreTier}
						size="md"
					>
						<SubFeatures tier={seeMoreTier} />
						<Modal.Footer />
					</Modal>
				)}
			</div>
		)
	},
)

SelectTier.displayName = 'SelectTier'

// Don't add snapping behavior is there is barely any room to scroll
const DRAG_SNAP_THRESHOLD = 100

// Snap when 1/4 of total scroll distance is moved.
const SNAP_DISTANCE_FRACTION = 4
