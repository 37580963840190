import type { Config as MixpanelConfig } from 'mixpanel-browser'

const LIB_NAME = 'mixpanel'

;((win) => {
	const mixpanel = (win.mixpanel ||= [])

	if (mixpanel.__SV) return

	mixpanel._i = []

	mixpanel.init = (token: string, config: Partial<MixpanelConfig>, name = LIB_NAME) => {
		// support multiple mixpanel instances
		const target = name != LIB_NAME ? (mixpanel[name] = []) : mixpanel

		// Pass in current people object if it exists
		target.people ||= []

		target.toString = (no_stub?: unknown) =>
			LIB_NAME + (name != LIB_NAME ? `.${name}` : '') + (!no_stub ? ' (stub)' : '')

		target.people.toString = () => target.toString(1) + '.people (stub)'

		// create shallow clone of the public mixpanel interface
		// Note: only supports 1 additional level atm, e.g. mixpanel.people.set, not mixpanel.people.set.do_something_else.
		;(
			'add_group ' +
			'alias ' +
			'clear_opt_in_out_tracking ' +
			'disable ' +
			'has_opted_in_tracking ' +
			'has_opted_out_tracking ' +
			'identify ' +
			'name_tag ' +
			'opt_in_tracking ' +
			'opt_out_tracking ' +
			'people.append ' +
			'people.clear_charges ' +
			'people.delete_user ' +
			'people.increment ' +
			'people.remove ' +
			'people.set ' +
			'people.set_once ' +
			'people.track_charge ' +
			'people.union ' +
			'people.unset ' +
			'register ' +
			'register_once ' +
			'remove_group ' +
			'reset ' +
			'set_config ' +
			'set_group ' +
			'start_batch_senders ' +
			'time_event ' +
			'track track_pageview ' +
			'track_forms ' +
			'track_links ' +
			'track_with_groups ' +
			'unregister'
		)
			.split(' ')
			.forEach((fn) => {
				let fnTarget = target

				const split = fn.split('.')

				if (split.length == 2) {
					fnTarget = target[split[0]]
					fn = split[1]
				}

				fnTarget[fn] = (...args: any[]) => {
					fnTarget.push([fn, ...args])
				}
			})

		const groupFns = 'delete remove set set_once union unset'.split(' ')

		// special case for get_group(): chain method calls like mixpanel.get_group('foo', 'bar').unset('baz')
		target.get_group = (...args: any[]) => {
			const mock_group: { [fn: string]: () => void } = {}
			const call1 = ['get_group', ...args]

			groupFns.forEach((fn) => {
				mock_group[fn] = (...args: any[]) => {
					target.push([call1, [fn, ...args]])
				}
			})

			return mock_group
		}

		// register mixpanel instance
		mixpanel._i.push([token, config, name])

		import('mixpanel-browser')
	}

	// Snippet version, used to fail on new features w/ old snippet
	mixpanel.__SV = 1.2

	// Pass in current Mixpanel object if it exists (for ppl like Optimizely)
})(window as any)
