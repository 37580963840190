import mapValues from 'lodash/mapValues'

export type GranType = /* Always*/ 'A' | /* Never */ 'N' | /* Schedule*/ 'S'
export type BoolDirectiveType = /* Disabled */ 'False' | /* Enabled */ 'True'
/**
 * This is actually:
 * 'allow' | 'block' | 'schedule'
 * However, as we use it now, only 'allow' will be present, since we don't have a UI
 * or implementation that would have a different value
 */
export type ScheduleGranType = 'allow'

export type GranDirectives = {
	readonly [bundleIdOrDirectiveName: string]: GranType
}

export type ScheduleGranMap = {
	readonly [scheduleId: string]: ScheduleGranType
}

export type RawScheduleGran = {
	readonly [bundleId: string]: Maybe<ScheduleGranMap>
}

export type ScheduleGran = {
	readonly [bundleId: string]: ScheduleGranMap
}

// Map of app ids to an array of schedules ids
export type ScheduleGraMap = {
	readonly [bundleId: string]: string[]
}

export type ScheduleGranPut = {
	readonly [bundleId: string]: NullablePartial<ScheduleGranMap>
}

export type RawGran = {
	readonly app_directives?: GranDirectives
	readonly omnibus_directives?: RawOmnibusDirectives
	readonly app_schedules?: RawScheduleGran
}

export type NormalizedRawGran = {
	readonly app_directives: GranDirectives
	readonly omnibus_directives: RawOmnibusDirectives
	readonly app_schedules: ScheduleGran
}

export type OmnibusKey =
	| 'allowAirDrop'
	| 'allowAppRemoval'
	| 'allowAssistant'
	| 'allowInAppPurchases'
	| 'allowPasscodeModification'
	| 'allowEraseContentAndSettings'
	| 'allowScreenShot'
	| 'androidAllowAirplaneMode'
	| 'androidAllowAppRemoval'
	| 'androidAllowNetworkTimeLock'
	| 'androidForceLimitAdTracking'
	| 'enableWebContentFilter'
	| 'forceAssistantProfanityFilter'
	| 'forceITunesStorePasswordEntry'
	| 'forceLimitAdTracking'
	| 'webContentFilterBlacklist'
	| 'webContentFilterWhitelist'

export type OmnibusDirectives = {
	readonly allowAirDrop: GranType
	readonly allowAppRemoval: GranType
	readonly allowAssistant: GranType
	readonly allowEraseContentAndSettings: GranType
	readonly allowInAppPurchases: GranType
	readonly allowPasscodeModification: GranType
	readonly allowScreenShot: GranType
	readonly androidAllowAirplaneMode: GranType
	readonly androidAllowAppRemoval: GranType
	readonly androidAllowNetworkTimeLock: GranType
	readonly androidForceLimitAdTracking: GranType
	readonly enableWebContentFilter: BoolDirectiveType
	readonly forceAssistantProfanityFilter: GranType
	readonly forceITunesStorePasswordEntry: GranType
	readonly forceLimitAdTracking: GranType
	readonly webContentFilterBlacklist: string
	readonly webContentFilterWhitelist: string
}

type NullablePartial<T> = { [P in keyof T]?: T[P] | null }
export type RawOmnibusDirectives = Partial<OmnibusDirectives>
export type RawOmnibusServerPut = NullablePartial<OmnibusDirectives>
export type RawGranDirectivesServerPut = NullablePartial<GranDirectives>

// Granularity type helpers
export const DEFAULT_OMNIBUS = {
	allowAirDrop: 'A',
	allowAppRemoval: 'A',
	allowAssistant: 'S',
	allowInAppPurchases: 'A',
	allowPasscodeModification: 'A',
	allowEraseContentAndSettings: 'A',
	allowScreenShot: 'A',
	androidAllowAirplaneMode: 'A',
	androidAllowAppRemoval: 'A',
	androidAllowNetworkTimeLock: 'A',
	androidForceLimitAdTracking: 'N',
	enableWebContentFilter: 'False',
	forceAssistantProfanityFilter: 'N',
	forceITunesStorePasswordEntry: 'N',
	forceLimitAdTracking: 'N',
	webContentFilterBlacklist: '',
	webContentFilterWhitelist: '',
} as const satisfies OmnibusDirectives

export const isBoolTypeEnabled = (val: Maybe<BoolDirectiveType>) => val === 'True'

export const isDefaultOmnibusType = <K extends OmnibusKey>(key: K, value: any) =>
	DEFAULT_OMNIBUS[key] === value

// Keys starting with `allow` or `androidAllow`
const ALLOW_KEY_REGEXP = /^allow|androidAllow/

export const isGranTypeEnabled = (omnibusKey: OmnibusKey, val: GranType) =>
	ALLOW_KEY_REGEXP.test(omnibusKey) ? val === 'N' : val === 'A'

/**
 * Ternary states are defined as such (yes, this is odd):
 *
 * Primary ON,  Secondary OFF: "SCHEDULE" (DEFAULT)
 * Primary ON,  Secondary ON:  "NEVER"
 * Primary OFF, Secondary OFF: "ALWAYS"
 */
export const isTernaryGranTypeEnabled = (val: GranType): [boolean, boolean] =>
	val === 'S' ? [true, false]
	: val === 'N' ? [true, true]
	: [false, false]

export const mapRawToOmnibus = (d: RawOmnibusDirectives): OmnibusDirectives => ({
	...DEFAULT_OMNIBUS,
	...d,
})

/** Changes 'S' granularity type to null when sending to server */
export const mapGranDirectivesToServerPut = (dirs: GranDirectives): RawGranDirectivesServerPut =>
	mapValues(dirs, (d) => (d === 'S' ? null : d))

/** Maps any default values to `null` for server PUT, which resets them to default */
export const mapRawToServerPutOmnibus = (raw: RawOmnibusDirectives): RawOmnibusServerPut =>
	mapValues(raw, (v: any, k: any) => (isDefaultOmnibusType(k, v) ? null : v))

export const toggleGranType = (val: GranType): GranType => (val === 'A' ? 'N' : 'A')

/**
 * For cases (currently only iOS assistant) where we have a ternary rather than
 * boolean selection, we show two toggles. The toggle states are odd and can be
 * seen above.
 *
 * @see isTernaryGranTypeEnabled
 *
 * If the primary is toggled and the toggle being used is also the primary
 * toggle, it means the primary has gone from "off" to "on", which puts us in
 * an "SCHEDULE" (default) state.
 *
 * For the next case, if the primary is toggled, we can assume that the toggle
 * being used is secondary (since the first case returns). If the secondary is
 * toggled off, we set the state to "NEVER".
 *
 * Otherwise, we can set the state to "ALWAYS" (primary and secondary off).
 */
const _toggleTernaryGranType =
	(isPrimary: boolean) =>
	(isPrimaryToggled: boolean, isSecondaryToggled: boolean): GranType =>
		isPrimaryToggled && (isPrimary || !isSecondaryToggled) ? 'S'
		: isPrimaryToggled && isSecondaryToggled ? 'N'
		: 'A'

export const togglePrimaryTernaryGranType = /*@__PURE__*/ _toggleTernaryGranType(true)
export const toggleSecondaryTernaryGranType = /*@__PURE__*/ _toggleTernaryGranType(false)
