import { useToggleState, useWindowEvent } from '@eturi/react'
import { useHandleSynStop } from '@op/react-web'
import { useMemo } from 'react'
import { NavChildList } from '../../components'
import { NavNotifBtn } from '../../components/Navigation/lazy'
import { ActionBtn, LoadComponent, NavBar, useNav } from '../../widgets'

export const ManageNavBar = () => {
	const [isNotifsOpen, toggleNotifsOpen, , hideNotifs] = useToggleState(false)
	const nav = useNav()

	const handleMobileMenuToggle = useHandleSynStop(() => {
		nav.toggle()
		hideNotifs()
	})

	useWindowEvent('click', hideNotifs)

	// NOTE: It's better to use `useMemo` instead of `memo` so we can choose what triggers
	//  a re-render. With `memo`, `NavBarOutlet` renders twice when toggling mobile menu,
	//  because `nav.isOpen` causes this to render, which changes the `children` of `NavBar`,
	//  which sets the nav bar content. Using this, it only re-renders once.
	return useMemo(
		() => (
			<NavBar>
				{nav.isMobile && <ActionBtn icon="hamburger" invert onClick={handleMobileMenuToggle} />}

				<NavChildList alignLeft />

				<LoadComponent>
					<NavNotifBtn isOpen={isNotifsOpen} onClick={toggleNotifsOpen} />
				</LoadComponent>
			</NavBar>
		),
		[isNotifsOpen, nav.isMobile],
	)
}
