import { useBoolState, useToggleState } from '@eturi/react'
import cls from 'classnames'
import { useState } from 'react'
import { Btn, HybridModal, InfoModal, Modal, type ModalSize, Switch } from '../../widgets'

export const DemoModalsView = () => {
	const [hasExtraContent, toggleExtraContent] = useToggleState(false)
	const [hasInfoTitle, toggleInfoTitle] = useToggleState(true)
	const [isHybridVisible, showHybrid, hideHybrid] = useBoolState(false)
	const [isInfoCloseDisabled, toggleInfoCloseDisabled] = useToggleState(false)
	const [isInfoCloseVisible, toggleInfoCloseVisible] = useToggleState(false)
	const [isInfoVisible, showInfo, hideInfo] = useBoolState(false)
	const [isLongTitle, toggleLongTitle] = useToggleState(false)
	const [isNormalVisible, showNormal, hideNormal] = useBoolState(false)
	const [modalSize, setModalSize] = useState<ModalSize>('default')

	const modalTitle = (() => {
		if (isInfoVisible) return hasInfoTitle ? `Here's Some Info!` : undefined

		if (isHybridVisible) {
			return (
				'This is a HybridModal' +
				(isLongTitle ? '. Meaning it becomes like full screen view on mobile sizes!' : '')
			)
		}

		if (isNormalVisible) {
			return (
				'This is a normal Modal' +
				(isLongTitle ?
					'. Meaning, it will act as a Modal on all screen sizes, including mobile!'
				:	'')
			)
		}
	})()

	return (
		<div className="is-page-pad-touch">
			<h2 className="mb-8 text-3xl text-center">Modals</h2>

			<div className="flex gap-y-12 flex-wrap">
				<div className="flex flex-auto flex-col items-center gap-y-4 px-4">
					<h3 className="mb-3">Normal / Hybrid Modals</h3>

					<Btn onClick={showHybrid}>Show Hybrid Modal</Btn>

					<Btn onClick={showNormal}>Show Normal Modal</Btn>

					<label className="flex-center gap-x-3 uppercase">
						Add Extra Content: <Switch enabled={hasExtraContent} onToggle={toggleExtraContent} />
					</label>

					<label className="flex-center gap-x-3 uppercase">
						Long Title: <Switch enabled={isLongTitle} onToggle={toggleLongTitle} />
					</label>

					<label className="flex-center gap-x-3 uppercase">
						Size:{' '}
						<select
							onChange={(ev) => {
								setModalSize(ev.currentTarget.value as ModalSize)
							}}
							value={modalSize}
						>
							<option value="default">Default</option>
							<option value="fluid">Fluid</option>
							<option value="md">Medium</option>
						</select>
					</label>
				</div>

				<div className="flex flex-auto flex-col items-center gap-y-4 px-4">
					<h3 className="mb-3">Info Modal</h3>

					<Btn onClick={showInfo}>Show Info Modal</Btn>
					<label className="flex-center gap-x-3 uppercase">
						Show Title: <Switch enabled={hasInfoTitle} onToggle={toggleInfoTitle} />
					</label>

					<label className="flex-center gap-x-3 uppercase">
						Show Close Button:{' '}
						<Switch enabled={isInfoCloseVisible} onToggle={toggleInfoCloseVisible} />
					</label>

					<label className="flex-center gap-x-3 uppercase">
						Disable Bg & Escape Close:{' '}
						<Switch enabled={isInfoCloseDisabled} onToggle={toggleInfoCloseDisabled} />
					</label>
				</div>
			</div>

			{isHybridVisible && (
				<HybridModal header={modalTitle!} onClose={hideHybrid} size={modalSize as any}>
					<BigModalContent extra={hasExtraContent} />

					<Modal.Footer>
						<Btn onClick={hideHybrid}>Okay</Btn>
					</Modal.Footer>
				</HybridModal>
			)}

			{isNormalVisible && (
				<Modal header={modalTitle!} onClose={hideNormal} size={modalSize as any}>
					<BigModalContent extra={hasExtraContent} />

					<Modal.Footer>
						<Btn onClick={hideNormal}>Okay</Btn>
					</Modal.Footer>
				</Modal>
			)}

			{isInfoVisible && (
				<InfoModal
					disableOutsideClose={isInfoCloseDisabled}
					header={modalTitle}
					hideClose={!isInfoCloseVisible}
					onClose={hideInfo}
				>
					<p className="mb-5">
						Sed maximus felis pretium sem auctor, at tempus libero ultricies. Nam eu lacinia dui,
						vel tristique nibh. Vestibulum eu commodo augue, at malesuada magna.
					</p>

					<ColorBoxes colors={['bg-pink-500', 'bg-pink-800', 'bg-pink-600', 'bg-pink-700']} short />

					<p className="mt-5">
						Mauris quis eleifend dolor. Donec tortor leo, venenatis vel nibh in, viverra suscipit
						nisi. Donec porta iaculis velit, eu feugiat ligula fermentum ut. Aenean pharetra gravida
						ex, non posuere sapien efficitur vitae.
					</p>

					{!isInfoCloseVisible && (
						<Modal.Footer>
							<Btn onClick={hideInfo}>Close</Btn>
						</Modal.Footer>
					)}
				</InfoModal>
			)}
		</div>
	)
}

const ColorBox = ({ color, short }: { readonly color: string; readonly short?: boolean }) => (
	<div className={cls('w-full', short ? 'h-16' : 'h-32', color)} />
)

type ColorBoxesProps = {
	readonly colors: [string, string, string, string]
	readonly short?: boolean
}

const ColorBoxes = ({ colors: [c1, c2, c3, c4], short }: ColorBoxesProps) => {
	return (
		<div className="flex flex-auto">
			<div className="flex flex-auto flex-col">
				<ColorBox color={c1} short={short} />
				<ColorBox color={c2} short={short} />
			</div>

			<div className="flex flex-auto flex-col">
				<ColorBox color={c3} short={short} />
				<ColorBox color={c4} short={short} />
			</div>
		</div>
	)
}

type BigModalContentProps = {
	readonly extra?: boolean
}

const BigModalContent = ({ extra }: BigModalContentProps) => (
	<>
		<h3 className="mb-5">Some Sample Content</h3>

		<p className="mb-5">
			Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed maximus felis pretium sem auctor,
			at tempus libero ultricies. Nam eu lacinia dui, vel tristique nibh. Vestibulum eu commodo
			augue, at malesuada magna. Mauris quis eleifend dolor. Donec tortor leo, venenatis vel nibh
			in, viverra suscipit nisi. Donec porta iaculis velit, eu feugiat ligula fermentum ut. Aenean
			pharetra gravida ex, non posuere sapien efficitur vitae.
		</p>

		<div className="flex mb-5">
			<ColorBoxes colors={['bg-slate-900', 'bg-slate-700', 'bg-slate-800', 'bg-slate-600']} />
			<ColorBoxes colors={['bg-indigo-500', 'bg-indigo-700', 'bg-indigo-600', 'bg-indigo-900']} />
		</div>

		<p className="mb-5">
			Nullam ut finibus odio, ac lobortis erat. Duis blandit tellus libero, a accumsan purus
			pellentesque et. Curabitur ac odio a massa suscipit feugiat. Donec ac tortor magna.
			Suspendisse nisl nunc, bibendum at ex non, ultricies rhoncus metus. Etiam eu tincidunt eros.
			Aliquam rhoncus vestibulum tellus at condimentum. Vivamus sed ornare elit, eu consequat eros.
		</p>

		<p>
			Sed a venenatis mauris, id mollis nibh. Etiam varius purus diam, ut lacinia est hendrerit nec.
			Aliquam porta enim risus, non efficitur erat consectetur at. Nunc porttitor ultrices leo at
			tristique. Morbi ac sem dictum, porta tortor a, sodales quam. Sed tempus diam urna, vitae
			egestas dui ultricies eu. Donec sodales feugiat maximus.
		</p>

		{extra && (
			<>
				<div className="flex my-5">
					<ColorBoxes
						colors={['bg-emerald-600', 'bg-emerald-500', 'bg-emerald-800', 'bg-emerald-900']}
					/>
				</div>

				<p>
					Integer ullamcorper dui quis purus consectetur malesuada. Aliquam congue pretium sapien.
					Etiam tellus tellus, convallis non varius et, consequat ac erat. Mauris rutrum ipsum eget
					neque dignissim, vitae pharetra est mollis. Proin ultrices auctor massa, ac scelerisque
					massa sollicitudin quis. Cras ac volutpat ante. Lorem ipsum dolor sit amet, consectetur
					adipiscing elit. Nulla vitae posuere nibh. Integer vitae magna nec mi congue malesuada.
				</p>
			</>
		)}
	</>
)
