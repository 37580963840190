import cls from 'classnames'

type TierBannerProps = {
	readonly selected: boolean
	readonly text: string
}

export const TierBanner = ({ selected, text }: TierBannerProps) => (
	<h2
		className={cls(
			'flex-center mb-3 md:mb-4 -mx-6 my-2 p-3 md:p-4',
			selected ? 'bg-teal' : 'bg-[#D2E5E8]',
		)}
	>
		<p className="font-bold text-white text-xl md:text-2xl uppercase">{text}</p>
	</h2>
)
