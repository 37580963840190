import { useT } from '@eturi/react-i18next'
import type { CssGlyphName } from '@op/icons'
import type { PremTier } from '@op/services'
import cls from 'classnames'
import type { ReactNode } from 'react'
import { useIsScreenSM } from '../../hooks'
import { Glyph } from '../../widgets'

type SubComparisonProps = {
	readonly tier: PremTier
}

export const SubFeatures = ({ tier }: SubComparisonProps) => {
	const [t] = useT('subscription_features')
	const isPremPlus = tier === 'premium_plus'

	return (
		<div className="sm:pr-4 sm:pl-[7.5%] max-w-2xl mx-auto md:px-2 md:min-w-[600px]">
			{isPremPlus ?
				<PremPlusFeatures />
			:	<PremFeatures />}

			<FeatureHeaderRow title={t('screentime_management')} />

			<RowBox>
				<FeatureCol
					color="text-ft-screentime"
					glyph="clock"
					subtitle={t('feature.unlimited_schedules.subtitle')}
					title={t('feature.unlimited_schedules.title')}
				/>

				<FeatureCol
					color="text-ft-screentime"
					glyph="app-rules"
					subtitle={t('feature.app_rules.subtitle')}
					title={t('feature.app_rules.title')}
				/>
			</RowBox>

			<RowBox>
				<FeatureCol
					color="text-ft-screentime"
					glyph="cancel"
					subtitle={t('feature.unlimited_blocks.subtitle')}
					title={t('feature.unlimited_blocks.title')}
				/>

				<FeatureCol
					color="text-ft-screentime"
					glyph="web"
					subtitle={t('feature.web_restrictions.subtitle')}
					title={t('feature.web_restrictions.title')}
				/>
			</RowBox>

			<RowBox isLast>
				<FeatureCol
					color="text-ft-screentime"
					glyph="allowance"
					subtitle={t('feature.allowance.subtitle')}
					title={t('feature.allowance.title')}
				/>

				<FeatureCol
					color="text-ft-screentime"
					glyph="chat"
					subtitle={t('feature.block_texting.subtitle')}
					title={t('feature.block_texting.title')}
				/>
			</RowBox>

			<FeatureHeaderRow title={t('supervision')} />

			<RowBox>
				<FeatureCol
					color="text-ft-supervision"
					glyph="add-user"
					subtitle={t('feature.manage_devices.subtitle')}
					title={t('feature.manage_devices.title')}
				/>

				<FeatureCol
					color="text-ft-supervision"
					glyph="lock"
					subtitle={t('feature.tamperproof.subtitle')}
					title={t('feature.tamperproof.title')}
				/>
			</RowBox>

			<RowBox>
				<FeatureCol
					color="text-ft-supervision"
					glyph="info-bubble"
					subtitle={t('feature.app_alerts.subtitle')}
					title={t('feature.app_alerts.title')}
				/>

				<FeatureCol
					color="text-ft-supervision"
					glyph="battery"
					subtitle={t('feature.battery_life.subtitle')}
					title={t('feature.battery_life.title')}
				/>
			</RowBox>
		</div>
	)
}

const FeatureHeaderRow = ({ title }: { readonly title: string }) => (
	<h5 className="my-2">
		<p className="font-bold uppercase">{title}</p>
	</h5>
)

type RowBoxProps = {
	readonly children?: ReactNode
	readonly isLast?: boolean
}

const RowBox = ({ children, isLast }: RowBoxProps) => (
	<section
		className={cls('flex -ml-2.5 -mr-2.5 sm:mr-0 sm:ml-0 md:gap-x-4', isLast && 'mb-3 sm:mb-4')}
	>
		{children}
	</section>
)

type FeatureColProps = {
	readonly color: string
	readonly glyph: CssGlyphName
	readonly subtitle: string
	readonly title: string
}

const FeatureCol = ({ color, glyph, subtitle, title }: FeatureColProps) => {
	const isSmScreen = useIsScreenSM()
	return (
		<div className="flex mx-1 my-1 flex-[0.5] items-center">
			<Glyph name={glyph} className={cls('mr-2 sm:mr-3', color)} size={isSmScreen ? 'lg' : 'md'} />

			<div className="shrink">
				<p className="font-bold leading-tight text-xs sm:text-sm md:text-base md:leading-6">
					{title}
				</p>
				<p className="text-xs sm:text-sm leading-tight md:leading-4">{subtitle}</p>
			</div>
		</div>
	)
}

const PremFeatures = () => {
	const [t] = useT('subscription_features')

	return (
		<>
			<FeatureHeaderRow title={t('family_locator')} />

			<RowBox>
				<FeatureCol
					color="text-ft-locator"
					glyph="map"
					subtitle={t('feature.location_history.prem_range')}
					title={t('feature.location_history.title')}
				/>

				<FeatureCol
					color="text-ft-locator"
					glyph="crosshair-circle"
					subtitle={t('feature.current_location.subtitle')}
					title={t('feature.current_location.title')}
				/>
			</RowBox>

			<RowBox isLast>
				<FeatureCol
					color="text-ft-locator"
					glyph="places"
					subtitle={t('feature.custom_places.subtitle')}
					title={t('feature.custom_places.title')}
				/>

				<FeatureCol
					color="text-ft-locator"
					glyph="directions"
					subtitle={t('feature.directions.subtitle')}
					title={t('feature.directions.title')}
				/>
			</RowBox>
		</>
	)
}

export const PremPlusFeatures = () => {
	const [t] = useT('subscription_features')

	return (
		<>
			<FeatureHeaderRow title={t('monitor_activity')} />

			<RowBox>
				<FeatureCol
					color="text-ft-monitor"
					glyph="vew-now"
					subtitle={t('feature.view_screenshots.subtitle')}
					title={t('feature.view_screenshots.title')}
				/>
				<FeatureCol
					color="text-ft-monitor"
					glyph="gallery-search"
					subtitle={t('feature.filter_images.subtitle')}
					title={t('feature.filter_images.title')}
				/>
			</RowBox>

			<RowBox>
				<FeatureCol
					color="text-ft-monitor"
					glyph="interval"
					subtitle={t('feature.set_frequency.subtitle')}
					title={t('feature.set_frequency.title')}
				/>
				<FeatureCol
					color="text-ft-monitor"
					glyph="lips"
					subtitle={t('feature.flag_content.subtitle')}
					title={t('feature.flag_content.title')}
				/>
			</RowBox>

			<RowBox isLast>
				<FeatureCol
					color="text-ft-monitor"
					glyph="gallery-img"
					subtitle={t('feature.encrypted_gallery.subtitle')}
					title={t('feature.encrypted_gallery.title')}
				/>
			</RowBox>

			<FeatureHeaderRow title={t('family_locator')} />

			<RowBox>
				<FeatureCol
					color="text-ft-locator"
					glyph="map"
					subtitle={t('feature.location_history.prem_plus_range')}
					title={t('feature.location_history.title')}
				/>
				<FeatureCol
					color="text-ft-locator"
					glyph="crosshair-circle"
					subtitle={t('feature.current_location.subtitle')}
					title={t('feature.current_location.title')}
				/>
			</RowBox>

			<RowBox isLast>
				<FeatureCol
					color="text-ft-locator"
					glyph="places"
					subtitle={t('feature.custom_places.subtitle')}
					title={t('feature.custom_places.title')}
				/>
				<FeatureCol
					color="text-ft-locator"
					glyph="directions"
					subtitle={t('feature.directions.subtitle')}
					title={t('feature.directions.title')}
				/>
			</RowBox>
		</>
	)
}
