import { useDebouncedValue } from '@eturi/react'

/**
 * This is like `useDebouncedValue`, but it's optimistic on disabled being
 * false and pessimistic on disabled being true. It should be used for states
 * like saving, where we don't want to flash a bunch of stuff if saving is
 * quick. So we'll disable buttons and whatnot, but we won't change the styles
 * until the debounced value is true.
 */
export const useDebounceDisabled = (disabled?: boolean, forceDisabled?: boolean) => {
	const debounceDisabled = useDebouncedValue(forceDisabled || disabled, 500)

	return Boolean(forceDisabled || !disabled ? disabled : debounceDisabled)
}
