import { lazy } from 'react'

export const Carousel = /*@__PURE__*/ lazy(
	() =>
		import(
			/* webpackChunkName: 'carousel' */
			/* webpackPrefetch: 0 */
			'./Carousel'
		),
)
