import { useFn } from '@eturi/react'
import mapValues from 'lodash/mapValues'
import type { DependencyList } from 'react'
import { useLayoutEffect, useMemo, useState } from 'react'
import { AppMediaBrkPt, AppMediaBrkPts } from '../css-variables'

export type AppMediaMatches = {
	readonly [K in AppMediaBrkPt]: boolean
}

export type MediaResizeListener = (matches: AppMediaMatches) => void

type MediaQueryEventListener = (ev: MediaQueryListEvent) => void

export const mediaWidthStr = (minBrkPt?: number) => `screen and (min-width: ${minBrkPt}px)`

const addQlChange = (ql: MediaQueryList, listener: MediaQueryEventListener) => {
	ql.addEventListener ? ql.addEventListener('change', listener) : ql.addListener(listener)
}

const _getMediaMatches = (): AppMediaMatches =>
	mapValues(AppMediaBrkPt, (v) => window.matchMedia(mediaWidthStr(v)).matches)

let mediaMatches = _getMediaMatches()

export const getMediaMatches = () => mediaMatches

const listeners = new Set<MediaResizeListener>()

AppMediaBrkPts.forEach((bp) => {
	const ql = window.matchMedia(mediaWidthStr(bp))

	addQlChange(ql, () => {
		mediaMatches = _getMediaMatches()
		listeners.forEach((l) => l(mediaMatches))
	})
})

export const useMediaResize = (listener: MediaResizeListener) => {
	const immutableListener = useFn(listener)

	useLayoutEffect(() => {
		listeners.add(immutableListener)
		return () => {
			listeners.delete(immutableListener)
		}
	}, [])
}

export const useMediaMatches = () => {
	const [brkPt, setBrkPt] = useState(getMediaMatches)

	useMediaResize((v) => {
		window.requestAnimationFrame(() => setBrkPt(v))
	})

	return brkPt
}

export const useMediaMemo = <T>(
	factory: (mediaMatches: AppMediaMatches) => T,
	deps: DependencyList = [],
) => {
	const mediaMatches = useMediaMatches()
	const depsMemo = useMemo(() => deps, deps)

	return useMemo(() => factory(mediaMatches), [mediaMatches, depsMemo])
}

type MediaMap<T> = {
	readonly [K in AppMediaBrkPt]?: T
}

const ORDERED_SIZES = ['xxl', 'xl', 'lg', 'md', 'sm'] as const

export const getMediaValue = <T>(base: T, map: MediaMap<T>): T => {
	for (const size of ORDERED_SIZES) {
		let ret

		if (mediaMatches[size] && (ret = map[size]) != null) return ret
	}

	return base
}

export const useIsScreenSM = () => useMediaMatches().sm
export const useIsScreenMD = () => useMediaMatches().md
export const useIsScreenLG = () => useMediaMatches().lg
export const useIsScreenXL = () => useMediaMatches().xl
export const useIsScreenXXL = () => useMediaMatches().xxl
export const useIsScreenMobile = () => !useIsScreenMD()
