import { useConstant, useFn } from '@eturi/react'
import { useEffect } from 'react'
import { v4 } from 'uuid'
import { appMiscSlice } from '../reducers/app-misc.slice'
import { useAppDispatch } from '../types'

export const useFreezeScroll = () => {
	const d = useAppDispatch()
	const id = useConstant(v4)

	// Unfreeze when unmounting
	useEffect(
		() => () => {
			d(appMiscSlice.actions._removeScrollFrozenId(id))
		},
		[],
	)

	return useFn((isFrozen: boolean) => {
		d(
			isFrozen ?
				appMiscSlice.actions._addScrollFrozenId(id)
			:	appMiscSlice.actions._removeScrollFrozenId(id),
		)
	})
}
