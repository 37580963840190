import { useT } from '@eturi/react-i18next'
import { useHandleSynStop } from '@op/react-web'
import { hasChildren$ } from '@op/services'
import { useSelector } from 'react-redux'
import { useIsScreenLG } from '../../hooks'
import { ActionBtn, NavBar, useNav } from '../../widgets'

export const ConnectNavBar = () => {
	const [t] = useT()
	const hasChildren = useSelector(hasChildren$)
	const isScreenLg = useIsScreenLG()
	const nav = useNav()

	const handleMenuClick = useHandleSynStop(nav.toggle)

	return (
		<NavBar>
			{!isScreenLg && !hasChildren ?
				<ActionBtn icon="hamburger" invert onClick={handleMenuClick} />
			:	<NavBar.BackBtn />}
			<div className="flex-auto lg:hidden" />
			<NavBar.Title>{t('connect.connect_comparison.ourpact_connect')}</NavBar.Title>
			<div className="flex-auto lg:hidden" />
			<div className="btn invisible lg:hidden" />
		</NavBar>
	)
}
