import './Accordion.scss'

import {
	IOS_DEBOUNCE_SCROLL,
	useConstant,
	useDebounce,
	useFn,
	useTimeout,
	useWindowEvent,
} from '@eturi/react'
import { useKeyboardClick } from '@op/react-web'
import { a, useSpring } from '@react-spring/web'
import cls from 'classnames'
import type { ReactNode } from 'react'
import { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { v4 } from 'uuid'

type AccordionProps = {
	readonly children: ReactNode
	readonly className?: string
	readonly independent?: boolean
}

type AccordionCtx = {
	// Whether the AccordionItem with the passed id is open
	isOpen(id: string): boolean

	// Handles toggling an accordion item. Accepts optional override.
	onToggle(id: string, toggleOpen?: boolean): void
}

const AccordionContext = createContext<AccordionCtx>(null as any)

export const Accordion = ({ children, className, independent = true }: AccordionProps) => {
	const [openIds, setOpenIds] = useState<Set<string>>(() => new Set())

	const isOpen = useFn((id: string) => openIds.has(id))
	const onToggle = useFn((id: string, toggleOpen?: boolean) => {
		const _isOpen = isOpen(id)

		// If toggleOpen is the same as _isOpen, we have the state we need.
		// Otherwise, we're going to flip to the correct state anyways.
		if (_isOpen === toggleOpen) return

		// If the accordion is independent, then multiple items can be open at the
		// same time. Otherwise, we create an empty set.
		const newOpenIds = independent ? new Set(openIds) : new Set<string>()

		// Then we either add or remove the id based on the toggle.
		_isOpen ? newOpenIds.delete(id) : newOpenIds.add(id)

		setOpenIds(newOpenIds)
	})

	// Only update / re-render when openIds is set.
	const value = useMemo(() => ({ isOpen, onToggle }), [openIds])

	return (
		<div className={cls('accordion', className)}>
			<AccordionContext.Provider value={value}>{children}</AccordionContext.Provider>
		</div>
	)
}

const ACCORDION_SPRING_CONFIG = {
	friction: 27,
	mass: 1,
	tension: 210,
}

const ACCORDION_CLOSED_PROPS = {
	config: ACCORDION_SPRING_CONFIG,
	height: '0px',
	opacity: 0,
}

type AccordionItemProps = {
	readonly children?: ReactNode
	readonly className?: string
	readonly contentClassName?: string
	readonly defaultOpen?: boolean
	readonly iconClassName?: string
	readonly iconStyles?: string
	readonly title?: string | ReactNode
	readonly titleClassName?: string
}

const AccordionItem = ({
	children,
	className,
	contentClassName,
	defaultOpen,
	iconClassName,
	iconStyles,
	title,
	titleClassName,
}: AccordionItemProps) => {
	// Each AccordionItem instance creates a unique id. This way it's not required
	// to pass and id via props.
	const id = useConstant(v4)
	const ctx = useContext(AccordionContext)
	const contentRef = useRef<HTMLDivElement>(null)
	const isOpen = ctx.isOpen(id)
	const [setOpenTimeout, clearTimeout] = useTimeout()

	// Props for spring states done via get / set for rendering perf.
	const getChevronSpringProps = useFn(() => ({
		config: ACCORDION_SPRING_CONFIG,
		transform: `rotate(${isOpen ? 180 : 0}deg)`,
	}))

	const getContentSpringProps = useFn(() => {
		// It's possible for this to be undefined since accordion is lazy loaded.
		const $c = contentRef.current

		if (!isOpen || !$c) return ACCORDION_CLOSED_PROPS

		// We have to remove the currently set height. If this is called on resize,
		// the height will already be set and it may be greater than scrollHeight.
		$c.style.height = ''

		return {
			config: ACCORDION_SPRING_CONFIG,
			height: `${$c.scrollHeight}px`,
			opacity: 1,
		}
	})

	const [chevronSpringProps, chevronSpringApi] = useSpring(getChevronSpringProps)
	const [contentSpringProps, contentSpringApi] = useSpring(getContentSpringProps)

	// Set both spring props via their getters
	const setSpringProps = useFn(() => {
		chevronSpringApi.start(getChevronSpringProps)
		contentSpringApi.start(getContentSpringProps)
	})

	const handleClick = useKeyboardClick(() => ctx.onToggle(id))

	useWindowEvent(
		'resize',
		useDebounce(() => {
			// We don't need to measure items that aren't open.
			if (!isOpen) return

			// Re-set the spring props on resize in case the height changes
			setSpringProps()
		}, IOS_DEBOUNCE_SCROLL),
	)

	useEffect(() => {
		// Open if this should be open by default
		if (defaultOpen) ctx.onToggle(id, true)

		// All we need to do to clean up is close the item, which removes the id
		return () => {
			ctx.onToggle(id, false)
		}
	}, [])

	useEffect(() => {
		// This timeout helps fix the issue where scrollHeight is wrong when
		// isOpen is true on init. In this case items are still rendering
		// when we measure, causing scrollHeight to be wrong. this timeout
		// gives items just enough time to render before measuring.
		setOpenTimeout(setSpringProps, 0)

		return clearTimeout
	}, [isOpen])

	// If the children update i.e. content is added or removed
	// then we re-measure the content height, we only check this when the
	// menu is open
	useEffect(() => {
		if (!(contentRef && isOpen)) return
		contentSpringApi.start(getContentSpringProps)
	}, [children])

	return (
		<div className={cls('accordion-item border-t', className)}>
			<header className={cls('accordion-item__title', titleClassName)} {...handleClick}>
				{title}
				<a.i
					className={cls('accordion-item__chevron pakicon-chevron-down', iconStyles, iconClassName)}
					style={chevronSpringProps}
				/>
			</header>

			<a.div className="overflow-hidden" ref={contentRef} style={contentSpringProps}>
				<div className={cls('accordion-item__content', contentClassName)}>{children}</div>
			</a.div>
		</div>
	)
}

Accordion.Item = AccordionItem
