import type { PayloadAction } from '@reduxjs/toolkit'

// Common id payloads for sub-reducers. E.g. changing something for a particular user id.
export type IdPayload<T> = {
	readonly id: string
	readonly value: T
}

export type VoidIdPayload = {
	readonly id: string
	readonly value?: void
}

export type IdPayloadAction<T = void> = PayloadAction<void extends T ? VoidIdPayload : IdPayload<T>>

/**
 * Binds a type `T` to a `prepare` method, where `T` is the `IdPayload` value.
 * If no generic is bound, defaults to `VoidIdPayload` and will not accept a
 * second argument.
 */
export const createIdPayloadPrepare = <T = void>(): void extends T ? (id: string) => IdPayloadAction
:	(id: string, value: T) => IdPayloadAction<T> =>
	((id: string, value: T) => ({ payload: { id, value } })) as any

/**
 * Picks the id and value as a tuple from an IdPayloadAction
 */
export const pickIdPayload = <T extends PayloadAction<IdPayload<any> | VoidIdPayload>>({
	payload: { id, value },
}: T): T extends PayloadAction<IdPayload<infer P>> ? [id: string, payload: P] : [id: string] =>
	[id, value] as any
