import { type ReactNode, useCallback, useRef } from 'react'
import { useStore } from 'react-redux'
import type { RootState } from '../reducers'
import { trackWithState } from './analytics'
import { AnalyticsRegionCtx } from './AnalyticsRegionCtx'
import type { Track } from './Track'

type AnalyticsRegionProps = {
	readonly children?: ReactNode
	readonly name?: string
}

export const AnalyticsRegion = ({ children, name }: AnalyticsRegionProps) => {
	const store = useStore<RootState>()
	const region = useRef(name)
	region.current = name

	const track: Track = useCallback((type: string, data?: Record<string, any>) => {
		trackWithState(store.getState(), type, { ...data, context: data?.context || region.current })
	}, [])

	return <AnalyticsRegionCtx.Provider value={track}>{children}</AnalyticsRegionCtx.Provider>
}
