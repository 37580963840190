import { createContext } from 'react'
import type { ConfirmToastOpts, ToastOpts } from './types'

export type ToastAPICtx = {
	addConfirmToast(opts: ConfirmToastOpts): string
	addErrorToast(optsOrMsg: ToastOpts | string): string
	addInfoToast(optsOrMsg: ToastOpts | string): string
	removeToast(id: string): void
}

// NOTE: Toast API and toasts themselves are separate contexts so that updating
//  a list of toasts doesn't cause components using the toast API to re-render.
//  Only the ToastContainer(s) will re-render when toasts are added/removed.
export const ToastAPICtx = createContext<ToastAPICtx>(null as any)
