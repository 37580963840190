import { now } from '@eturi/date-util'
import mapValues from 'lodash/mapValues'
import type { LatLng } from './LatLng'
import { toLatLng } from './LatLng'
import type { RawLatLonInfo } from './RawLatLon'

//////////////////////////
// RAW User Geo Objects //
//////////////////////////

/**
 * This is what the server sends for GET /user_location, a map of user_id -> RawUserGeo. These are
 * filtered to include only managed, non-retired users by the server.
 *
 * @example
 * Below is an example of the full model from this request, which says a lot about
 * why there are so many interfaces
 *
 * { // RawLocationsInfo
 *   "62d4d7a2-1785-40e4-8ff0-860da4a7122d": { // user_id -> RawUserGeo
 *     "last_activity": 1513210430586,
 *     "user_location_active": true,
 *     "device_info": { // RawGeoDeviceInfo
 *       "257a3be9-534c-4d34-b23e-a7cb2c4f6f00": { // device_id -> RawDeviceGeo
 *         "location": [ // RawLocationData[]
 *           {
 *             "location_data": { // RawLatLonInfo
 *               "acc": "5",
 *               "lat": 10.232,
 *               "lon": -12.23
 *               "seg_end": true,
 *               "start_ts: 1662479517757,
 *             },
 *             "location_ts": 1514110430900
 *           }
 *         ],
 *         "last_seen": 1514110430500,
 *         "device_location_active": true,
 *         "device_location_ts": 1513210536992
 *       }
 *     }
 *   }
 * }
 */
export type RawLocationInfo = {
	readonly [userId: string]: RawUserGeo
}

/**
 * These are the individual, raw user location objects, which will mapped to UserGeo
 * @see UserGeo
 * @see RawLocationInfo
 */
export type RawUserGeo = {
	readonly device_info: RawGeoDeviceInfo
	// NOTE: We don't care about last_activity and strip it
	readonly last_activity: Maybe<number>
	readonly user_location_active: Maybe<boolean>
}

/**
 * Raw map of device geo info that comes from the server
 * @see RawDeviceGeo
 */
export type RawGeoDeviceInfo = {
	readonly [deviceId: string]: RawDeviceGeo
}

/**
 * Base device geo that contains shared fields between both raw and decorated
 * @see DeviceGeo
 * @see RawDeviceGeo
 */
type BaseDeviceGeo = {
	readonly device_location_active: Maybe<boolean>
	// device_location_ts is the timestamp of the last time permissions for
	// device_location_active were toggled, not to be confused with
	// location_data.location_ts which is the last time we got a location fix
	readonly device_location_ts: Maybe<number>
}

/**
 * Raw device geo that the server sends
 * @see RawLocationData
 */
export type RawDeviceGeo = BaseDeviceGeo & {
	readonly device_location_active: Maybe<boolean>
	readonly device_location_ts: Maybe<number>
	readonly location: RawLocationData[]
	// Copied from Device.last_seen
	// NOTE: We don't care about this, and are going to strip it
	readonly last_seen: Maybe<number>
}

/**
 * Base location object that both raw and decorated share fields with
 * @see LocationData
 * @see RawLocationData
 */
type BaseLocationData = {
	readonly location_ts: number
}

/**
 * Raw location data from the device in lat / lon
 * @see BaseLocationData
 * @see RawLatLonInfo
 */
export type RawLocationData = BaseLocationData & {
	readonly location_data: RawLatLonInfo
}

///////////////////////////
// DECORATED Geo Objects //
///////////////////////////

/**
 * Mapped locations info where RawUserGeo is converted to UserGeo
 * @see UserGeo
 * @see RawLocationInfo
 */
export type LocationInfo = {
	readonly [userId: string]: UserGeo
}

/**
 * Mapped user locations where `last_activity` is stripped and `device_info`
 * is mapped to GeoDeviceInfo
 * @see GeoDeviceInfo
 */
export type UserGeo = {
	readonly device_info: GeoDeviceInfo
	readonly user_location_active: boolean
}

/**
 * Map of device_id to DeviceGeo
 * @see DeviceGeo
 */
export type GeoDeviceInfo = {
	readonly [deviceId: string]: DeviceGeo
}

/**
 * Decorated device geo where `last_seen` is stripped
 * and raw locations are mapped to LocationData
 * @see LocationData
 */
export type DeviceGeo = MPick<BaseDeviceGeo, 'device_location_ts'> & {
	readonly device_location_active: boolean
	readonly location: LocationData[]
}

/**
 * Decorated location data
 * @see RawLocationData
 */
export type LocationData = BaseLocationData & {
	readonly isInactive: boolean
	readonly location_data: LatLng
}

//// Mapping locations info

// NOTE: We don't use object spreading to copy here and below when mapping
//  UserGeo, b/c we want to remove some fields like last_activity
//  last_seen, etc.
export const mapRawToDeviceGeo = (rawDeviceGeo: RawDeviceGeo): DeviceGeo => ({
	device_location_active: Boolean(rawDeviceGeo.device_location_active),
	device_location_ts: rawDeviceGeo.device_location_ts,
	location: mapRawToLocationData(rawDeviceGeo.location),
})

export const mapRawToGeoDeviceInfo = (rawGeoDeviceInfo: RawGeoDeviceInfo): GeoDeviceInfo =>
	mapValues(rawGeoDeviceInfo, mapRawToDeviceGeo)

export const mapRawToLocationData = (rawLocationData: RawLocationData[]): LocationData[] =>
	rawLocationData.map(({ location_data, location_ts }) => ({
		isInactive: +now.subtract(2, 'd') >= location_ts,
		location_data: toLatLng(location_data),
		location_ts,
	}))

export const mapRawToLocationInfo = (rawLocationInfo: RawLocationInfo): LocationInfo =>
	mapValues(rawLocationInfo, mapRawToUserGeo)

// NOTE: See mapRawToDeviceGeo (we don't spread)
export const mapRawToUserGeo = (rawUserGeo: RawUserGeo): UserGeo => ({
	device_info: mapRawToGeoDeviceInfo(rawUserGeo.device_info),
	user_location_active: Boolean(rawUserGeo.user_location_active),
})
