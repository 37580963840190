import { lazy, memo } from 'react'
import { LoadPage } from '../../widgets'
import { ConnectNavBar } from './ConnectNavBar'

const ConnectPageContent = /*@__PURE__*/ lazy(() => import('./ConnectPageContent'))

const ConnectPage = () => (
	<div className="page is-page-pad-touch">
		<ConnectNavBar />

		<LoadPage>
			<ConnectPageContent />
		</LoadPage>
	</div>
)

export default /*@__PURE__*/ memo(ConnectPage)
