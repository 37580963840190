import noop from 'lodash/noop'
import type { ReactNode } from 'react'
import { BaseModal } from './BaseModal'
import { ModalFooter } from './ModalFooter'

export type InfoModalProps = {
	readonly children?: ReactNode
	readonly disableOutsideClose?: boolean
	readonly header?: string
} & (
	| {
			readonly hideClose?: true
			onClose?(): void
	  }
	| {
			readonly hideClose: false
			onClose(): void
	  }
)

export const InfoModal = ({ hideClose = true, onClose = noop, ...p }: InfoModalProps) => (
	<BaseModal hideClose={hideClose} onClose={onClose} size="info" {...p} />
)

InfoModal.Footer = ModalFooter
