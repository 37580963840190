import reduce from 'lodash/reduce'
import type { DeviceOS } from './Device'
import type { GranType } from './Granularity'

// Raw variants
export type RawAppsDataForDevices = {
	readonly [deviceId: string]: Maybe<RawAppsData>
}

type RawInstalledApps = {
	readonly [bundleId: string]: Maybe<RawDeviceApp>
}

type RawAppsData = {
	readonly installed_apps: Maybe<RawInstalledApps>
	readonly installed_apps_ts: number
	readonly os: DeviceOS
	readonly updated: boolean
}

type RawDeviceApp = {
	readonly detailed_version: string
	readonly display_version: string
	readonly installer_package_name?: string
	readonly is_system?: boolean
	readonly supervision_required: boolean
	readonly title: string
}

export type NormalizedAppsDataForDevices = {
	readonly [deviceId: string]: Omit<RawAppsData, 'installed_apps'> & {
		readonly installed_apps: {
			readonly [bundleId: string]: NormalizedDeviceApp
		}
	}
}

export type NormalizedDeviceApp = RawDeviceApp & {
	readonly bundleId: string
	// Since we normalize the `SpecialApp` case, we use this to fetch app details from the
	// actual bundleId, rather than the fake "special" one.
	readonly detailsBundleId: string
}

// Decorated versions
export type AppRules = {
	readonly [appId: string]: DeviceApp
}

export type DeviceApp = NormalizedDeviceApp & {
	readonly bundleId: string
	readonly description: Maybe<string>
	readonly deviceDisplayNames: string[]
	readonly granularity: GranType
	readonly is_system: boolean
	readonly os: DeviceOS
}

export type SpecialApp = ValueUnion<typeof SpecialApp>
export const SpecialApp = {
	APPLE_CAMERA: 'com.apple.camera',
	APPLE_FACETIME: 'com.apple.facetime',
	APPLE_COMBO: 'camera-facetime',
} as const

const RAW_SAMPLE_DEVICE_APP: RawDeviceApp = {
	detailed_version: '',
	display_version: '',
	is_system: false,
	supervision_required: true,
	title: '',
}

type BundleIdAndTitleTuple = [bundleId: string, title: string]

const SAMPLE_BUNDLE_IDS_AND_TITLES: BundleIdAndTitleTuple[] = [
	['com.facebook.Facebook', 'Facebook'],
	['com.burbn.instagram', 'Instagram'],
	['com.zhiliaoapp.musically', 'TikTok'],
	['com.mojang.minecraftpe', 'Minecraft'],
	['com.google.ios.youtube', 'YouTube'],
	['com.505games.terraria', 'Terraria'],
	['net.whatsapp.WhatsApp', 'WhatsApp'],
	['com.spotify.client', 'Spotify'],
	['com.toyopagroup.picaboo', 'Snapchat'],
]

const SAMPLE_INSTALLED_APPS = reduce(
	SAMPLE_BUNDLE_IDS_AND_TITLES,
	(apps: Writable<RawInstalledApps>, app) => {
		apps[app[0]] = RAW_SAMPLE_DEVICE_APP
		return apps
	},
	{},
)

/**
 * These are without titles and are only to be used for fetching app details for sample dataset
 */
export const RAW_SAMPLE_APP_DATA: RawAppsDataForDevices = {
	['MOCK_DEVICE']: {
		installed_apps: SAMPLE_INSTALLED_APPS,
		installed_apps_ts: Date.now(),
		os: 'iOS',
		updated: true,
	},
}

const SAMPLE_DEVICE_APP: DeviceApp = {
	bundleId: '',
	detailsBundleId: '',
	description: '',
	detailed_version: '',
	deviceDisplayNames: [''],
	display_version: '',
	granularity: 'S',
	is_system: false,
	os: 'iOS',
	supervision_required: true,
	title: '',
}

export const getSampleDeviceApps = (() => {
	let deviceApps: DeviceApp[] | null = null

	const createSampleDeviceApp = (bundleId: string, title: string): DeviceApp => ({
		...SAMPLE_DEVICE_APP,
		bundleId,
		detailsBundleId: bundleId,
		title,
	})

	return () =>
		(deviceApps ||= SAMPLE_BUNDLE_IDS_AND_TITLES.map(([bundleId, title]) =>
			createSampleDeviceApp(bundleId, title),
		))
})()

export const mergeServerAppsData = (r: RawAppsDataForDevices[]): RawAppsDataForDevices =>
	Object.assign({}, ...r)
