// This is just a helper to make sure keys are correct for mangling
type TestIDKey = `${number | string}_TID$$`
type TestID<K extends TestIDKey = TestIDKey> = {
	readonly [P in K]: string
}

export const TestID = {
	BTN_ADD_SCHEDULE_TID$$: 'btn_add_schedule',
	BTN_ACCOUNT_CHANGE_EMAIL_TID$$: 'btn_change_email',
	BTN_ACCOUNT_CHANGE_LOCALE_TID$$: 'btn_change_locale',
	BTN_ACCOUNT_CHANGE_NOTIF_TID$$: 'btn_change_notifications',
	BTN_ACCOUNT_CHANGE_PW_TID$$: 'btn_change_password',
	BTN_ACCOUNT_CHANGE_TID$$: 'btn_account_change',
	BTN_ACCOUNT_DELETE_TID$$: 'btn_account_delete',
	BTN_ACCOUNT_DELETE_CONTINUE_TID$$: 'btn_account_delete_continue',
	BTN_CHILD_RULES_ADD_TIME_TID$$: 'btn_cr_add',
	BTN_CHILD_RULES_BLOCK_TID$$: 'btn_cr_block',
	BTN_CHILD_RULES_CANCEL_TID$$: 'btn_cr_cancel',
	BTN_CHILD_RULES_GRANT_TID$$: 'btn_cr_grant',
	BTN_COOKIE_CLOSE_TID$$: 'btn_cook_close',
	BTN_DELETE_SCHEDULE_TID$$: 'btn_delete_schedule',
	BTN_LOGIN_TID$$: 'btn_login',
	BTN_MODAL_CLOSE_TID$$: 'btn_modal_close',
	BTN_NAV_NOTIF_TID$$: 'btn_nav_notif',
	BTN_PW_TOOLTIP_TID$$: 'btn_pw_tooltip',
	BTN_RESET_PW_TID$$: 'btn_reset_pw',
	BTN_SAVE_SCHEDULE_TID$$: 'btn_save_schedule',
	BTN_SIGNUP_TID$$: 'btn_signup',
	BTN_SUBSCRIPTION_COMPARE_TID$$: 'btn_subscription_compare',
	BTN_SUBSCRIPTION_SEE_MORE_TID$$: 'btn_subscription_see_more',
	BTN_SUBSCRIPTION_SUBMIT_TID$$: 'btn_subscription_submit',
	BTN_TOAST_CANCEL_TID$$: 'btn_toast_cancel',
	BTN_TOAST_CONFIRM_TID$$: 'btn_toast_confirm',
	CHECK_DAY_SELECTOR_TID$$: 'check_day_selector',
	CLICK_OFF_APP_TID$$: 'click_off',
	DASHBOARD_TAB_ALLOWANCE_TID$$: 'dashboard_allowance',
	DASHBOARD_TAB_APP_RULES_TID$$: 'dashboard_app_rules',
	DASHBOARD_TAB_RESTRIX_TID$$: 'dashboard_restrix',
	DASHBOARD_TAB_SCHEDULES_TID$$: 'dashboard_schedules',
	INPUT_CONFIRM_EMAIL_TID$$: 'input_email_confirm',
	INPUT_CONFIRM_PW_TID$$: 'input_pw_confirm',
	INPUT_EMAIL_TID$$: 'input_email',
	INPUT_NEW_PW_TID$$: 'input_new_pw',
	INPUT_PW_TID$$: 'input_pw',
	INPUT_SCHEDULE_END_TID$$: 'input_schedule_end',
	INPUT_SCHEDULE_NAME_TID$$: 'input_schedule_name',
	INPUT_SCHEDULE_START_TID$$: 'input_schedule_start',
	INPUT_USER_NAME_TID$$: 'input_user_name',
	LINK_GALLERY_TID$$: 'link_gallery',
	LINK_LOCATOR_TID$$: 'link_locator',
	PROMO_SELECT_CYCLE_TID$$: 'promo_select_cycle',
	PROMO_SELECT_TIER_TID$$: 'promo_select_tier',
	SCHEDULE_LIST_ITEM_TID$$: 'schedule_item',
	SCHEDULE_LIST_TID$$: 'schedule_list',
	SUBSCRIPTION_SELECT_CYCLE_TID$$: 'subscription_select_cycle',
	SUBSCRIPTION_SELECT_TIER_TID$$: 'subscription_select_tier',
	SWITCH_NOTIF_CHANGE_TID$$: 'switch_notif_change',
	SWITCH_SCHEDULE_RECURRING_TID$$: 'switch_schedule_rec',
	SWITCH_SCHEDULE_TID$$: 'switch_schedule',
	TOAST_CONFIRM_TID$$: 'toast_confirm',
	TOAST_ERROR_TID$$: 'toast_error',
	TOAST_INFO_TID$$: 'toast_info',
} as const satisfies TestID

export type TestIDValue = ValueUnion<typeof TestID>
