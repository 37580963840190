import './HybridModal.scss'

import { BaseModal } from './BaseModal'
import { ModalFooter } from './ModalFooter'
import type { ModalProps } from './types'

/**
 * A hybrid component that is a modal when the screen is MD or larger, but
 * takes up the full page view in smaller layouts.
 */
export const HybridModal = (p: ModalProps) => <BaseModal isHybrid {...p} />

HybridModal.Footer = ModalFooter
