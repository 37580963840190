import { useIsScreenMD, useIsScreenSM } from '../../hooks'
import { Check } from '../../widgets'

type ChooseSubSelectCheckProps = {
	readonly className?: string
	readonly selected: boolean
}

export const ChooseSubSelectCheck = ({ className, selected }: ChooseSubSelectCheckProps) => {
	const isSmScreen = useIsScreenSM()
	const isMdScreen = useIsScreenMD()
	const checkSize =
		isMdScreen ? 'md'
		: isSmScreen ? 'sm'
		: 'xs'

	return <Check className={className} checked={selected} readOnly size={checkSize} />
}
