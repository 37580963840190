import { ImmutSet, setInterdependentBoolState } from '@eturi/util'
import type { OcrCatFilter, WritableDraft } from '@op/services'
import { castImmutSet, resetAction } from '@op/services'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import identity from 'lodash/identity'
import { createSliceTransformer } from 'rtk-slice-transformer'

export type GalleryUIState = {
	readonly areFiltersEmpty: boolean
	readonly askOnDelete: boolean
	readonly deviceIds: ImmutSet<string>
	readonly isDateFilterVisible: boolean
	readonly isDeviceFilterVisible: boolean
	readonly isFsVisible: boolean
	readonly isOcrFilterVisible: boolean
	readonly isVewNowOpen: boolean
	readonly ocrFilters: ImmutSet<OcrCatFilter>
}

export type WithGalleryUIState = {
	readonly galleryUI: GalleryUIState
}

const initialState: GalleryUIState = {
	areFiltersEmpty: false,
	askOnDelete: true,
	deviceIds: new ImmutSet(),
	isDateFilterVisible: false,
	isDeviceFilterVisible: false,
	isFsVisible: false,
	isOcrFilterVisible: false,
	isVewNowOpen: false,
	ocrFilters: new ImmutSet(),
}

// Filter keys
const FK = ['isDateFilterVisible', 'isDeviceFilterVisible', 'isOcrFilterVisible'] as const

type GFK = (typeof FK)[number]

const setGalleryInterdependentBool = setInterdependentBoolState<
	GalleryUIState | WritableDraft<GalleryUIState>,
	GFK
>(FK)

export const galleryUISlice = /*@__PURE__*/ createSlice({
	name: 'galleryUI',
	initialState,
	reducers: {
		resetFilters(s) {
			const castState = castImmutSet<GalleryUIState>(s)
			castState.deviceIds = initialState.deviceIds
			castState.ocrFilters = initialState.ocrFilters
		},

		setAskOnDelete(s, a: PayloadAction<boolean>) {
			s.askOnDelete = a.payload
		},

		setDateFilterVisible(s, a: PayloadAction<boolean>) {
			return setGalleryInterdependentBool(s, 'isDateFilterVisible', a.payload)
		},

		setDeviceFilterVisible(s, a: PayloadAction<boolean>) {
			return setGalleryInterdependentBool(s, 'isDeviceFilterVisible', a.payload)
		},

		setDeviceFilters(s, a: PayloadAction<ImmutSet<string>>) {
			castImmutSet<GalleryUIState>(s).deviceIds = a.payload
		},

		setFiltersEmpty(s, a: PayloadAction<boolean>) {
			s.areFiltersEmpty = a.payload
		},

		setFsVisible(s, a: PayloadAction<boolean>) {
			s.isFsVisible = a.payload
		},

		setOcrFilterVisible(s, a: PayloadAction<boolean>) {
			return setGalleryInterdependentBool(s, 'isOcrFilterVisible', a.payload)
		},

		setOcrFilters(s, a: PayloadAction<ImmutSet<OcrCatFilter>>) {
			castImmutSet<GalleryUIState>(s).ocrFilters = a.payload
		},

		setVewNowOpen(s, a: PayloadAction<boolean>) {
			s.isVewNowOpen = a.payload
		},
	},
	extraReducers: (builder) => builder.addCase(resetAction, () => initialState),
})

export const {
	resetFilters,
	setAskOnDelete,
	setDateFilterVisible,
	setDeviceFilters,
	setDeviceFilterVisible,
	setFiltersEmpty,
	setFsVisible,
	setOcrFilters,
	setOcrFilterVisible,
	setVewNowOpen,
} = galleryUISlice.actions

export const galleryUISliceTransformer = /*@__PURE__*/ createSliceTransformer(
	galleryUISlice,
	identity,
)

// NOTE: This needs to be used if we ever persist this state
export const rehydrateGalleryUISlice = (p: Partial<GalleryUIState>): GalleryUIState => ({
	...galleryUISlice.getInitialState(),
	...p,
	deviceIds: new ImmutSet(p.deviceIds),
	ocrFilters: new ImmutSet(p.ocrFilters),
})

////////// Selectors /////////////////////////////////////////////////////////

const state$ = <T extends WithGalleryUIState>(s: T) => s.galleryUI

export const askOnDeleteSample$ = /*@__PURE__*/ createSelector(state$, (s) => s.askOnDelete)
export const areGalleryFiltersEmpty$ = /*@__PURE__*/ createSelector(
	state$,
	(s) => s.areFiltersEmpty,
)
export const deviceFilters$ = /*@__PURE__*/ createSelector(state$, (s) => s.deviceIds)
export const isDateFilterVisible$ = /*@__PURE__*/ createSelector(
	state$,
	(s) => s.isDateFilterVisible,
)
export const isDeviceFilterVisible$ = /*@__PURE__*/ createSelector(
	state$,
	(s) => s.isDeviceFilterVisible,
)
export const isFsGalleryVisible$ = /*@__PURE__*/ createSelector(state$, (s) => s.isFsVisible)
export const isOcrFilterVisible$ = /*@__PURE__*/ createSelector(state$, (s) => s.isOcrFilterVisible)
export const isVewNowOpen$ = /*@__PURE__*/ createSelector(state$, (s) => s.isVewNowOpen)
export const ocrFilters$ = /*@__PURE__*/ createSelector(state$, (s) => s.ocrFilters)
