import { duration } from '@eturi/date-util'
import { useConstant, useFn, useStateGetter } from '@eturi/react'
import type { ReactNode } from 'react'
import { createContext } from 'react'
import { v4 } from 'uuid'
import type { ConfirmToastProps, ToastType } from './Toast'
import { ConfirmToast, Toast } from './Toast'
import { ToastAPICtx } from './ToastAPICtx'
import type { AnyToastOpts, ToastNode, ToastOpts } from './types'

const CONFIRM_TOAST_DURATION = /*@__PURE__*/ duration(1, 'm')
const DEFAULT_TOAST_DURATION = /*@__PURE__*/ duration(8, 's')

export const ToastsContext = /*@__PURE__*/ createContext<ToastNode[]>([])

export const ToastManager = (p: { readonly children?: ReactNode }) => {
	// NOTE: Need to use state getter in case setToasts is called multiple times
	//  synchronously (which would overwrite each value). This can be reproduced
	//  by having a confirm toast, that onConfirm, triggers an en error toast.
	const [toasts, setToasts] = useStateGetter<ToastNode[]>([])

	const addNewToast = useFn((anyToastOpts: AnyToastOpts, type: ToastType): string => {
		const id = v4()
		const removeNewToast = () => removeToast(id)
		let duration = DEFAULT_TOAST_DURATION
		let toast: ReactNode
		const props = { ...anyToastOpts, close: removeNewToast }

		if (type === 'confirm') {
			duration = CONFIRM_TOAST_DURATION
			toast = <ConfirmToast {...(props as ConfirmToastProps)} />
		} else {
			toast = <Toast {...props} type={type} />
		}

		const tId = !anyToastOpts.persistent ? window.setTimeout(removeNewToast, duration) : -1

		setToasts([...toasts(), { id, tId, toast }])

		return id
	})

	const removeToast = useFn((id: string) => {
		const newToasts = toasts().filter((t) => {
			if (t.id !== id) return true
			if (t.tId !== -1) window.clearTimeout(t.tId)
			return false
		})

		setToasts(newToasts)
	})

	const normalizeOpts = (optsOrMsg: ToastOpts | string): ToastOpts =>
		typeof optsOrMsg === 'string' ? { msg: optsOrMsg } : optsOrMsg

	const toastApiCtx = useConstant(
		(): ToastAPICtx => ({
			addConfirmToast: (opts) => addNewToast(opts, 'confirm'),
			addErrorToast: (optsOrMsg) => addNewToast(normalizeOpts(optsOrMsg), 'error'),
			addInfoToast: (optsOrMsg) => addNewToast(normalizeOpts(optsOrMsg), 'info'),
			removeToast,
		}),
	)

	return (
		<ToastAPICtx.Provider value={toastApiCtx}>
			<ToastsContext.Provider value={toasts()}>{p.children}</ToastsContext.Provider>
		</ToastAPICtx.Provider>
	)
}
