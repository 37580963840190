import type { AnimatedComponent, Interpolation, SpringValue } from '@react-spring/web'
import type { CSSProperties, DependencyList } from 'react'
import { useMemo } from 'react'
import type { AppMediaMatches } from '../hooks'
import { useMediaMemo } from '../hooks'

type Falsy = undefined | null | false
type RecursiveArray<T> = (T | readonly T[] | RecursiveArray<T>)[]
type StyleProp<T> = T | RecursiveArray<T | Falsy> | Falsy

/**
 * Black magic for turning a union into an intersection.
 * @see https://stackoverflow.com/questions/50374908/transform-union-type-to-intersection-type
 */
type ReadonlyUnionToIntersection<U> =
	(U extends any ? (k: U) => void : never) extends (k: infer I) => void ?
		// NOTE: This extra mapping "flattens" the intersection into a single readonly object
		{ readonly [P in keyof I]: I[P] }
	:	never

type CreateNamedStyles<T> = {
	readonly [P in keyof T]: StyleProp<CSSProperties>
}

type FlattenedNamedStyles<T extends CreateNamedStyles<any>> = {
	readonly [P in keyof T]: T[P] extends (infer U)[] ? ReadonlyUnionToIntersection<U> : T[P]
}

const flattenStyle = <S>(s: StyleProp<S>): S extends (infer U)[] ? U : S => {
	if (!s) return undefined as any

	if (!Array.isArray(s)) return s as any

	const result: any = {}

	for (let i = 0, len = s.length; i < len; ++i) {
		const computedStyle = flattenStyle(s[i] as any)

		if (computedStyle) {
			for (const k in computedStyle) {
				result[k] = computedStyle[k]
			}
		}
	}

	return result
}

export const createStyles = <S extends CreateNamedStyles<any>>(
	styles: S,
): FlattenedNamedStyles<S> => {
	const v: any = {}

	for (const k in styles) {
		v[k] = flattenStyle(styles[k])
	}

	return v
}

// This extracts all "special" styles from animated components
type TransformStyles = MOmit<
	WithRequired<Parameters<AnimatedComponent<'div'>>[0], 'style'>['style'],
	keyof CSSProperties
>

// This is the type of base animated styles (without "special")
type MaybeAnimatedCSSProps = {
	[P in keyof CSSProperties]?: CSSProperties[P] | SpringValue<CSSProperties[P]> | Interpolation
}

// Animated styles is a combination of base animated styles and "special" ones
type AnimatedStyles = MaybeAnimatedCSSProps & TransformStyles

export const useAnimatedStyle = <TStyle extends AnimatedStyles>(
	styleFactory: () => TStyle,
	deps: DependencyList = [],
) => useMemo(styleFactory, deps)

export const useAnimatedMediaStyle = <TStyle extends AnimatedStyles>(
	styleFactory: (mediaMatches: AppMediaMatches) => TStyle,
	deps?: DependencyList,
): TStyle => useMediaMemo(styleFactory, deps)

export const useStyle = <TStyle extends CSSProperties, TOut extends StyleProp<TStyle>>(
	styleFactory: () => TOut,
	deps: DependencyList = [],
) => useMemo(() => flattenStyle(styleFactory()), deps)

export const useMediaStyle = <TStyle extends CSSProperties>(
	styleFactory: (mediaMatches: AppMediaMatches) => TStyle,
	deps?: DependencyList,
) => useMediaMemo(styleFactory, deps)
