import { lazy } from 'react'
import { LoadComponent, NavBar } from '../../widgets'
import { LocatorNavChildList } from './LocatorNavChildList'

const LocatorNavBarActions = /*@__PURE__*/ lazy(() => import('./LocatorNavBarActions'))

export const LocatorNavBar = () => (
	<NavBar>
		<NavBar.BackBtn />

		<LocatorNavChildList />

		<LoadComponent>
			<LocatorNavBarActions />
		</LoadComponent>
	</NavBar>
)
