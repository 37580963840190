import { selectUnaryEvery } from '@eturi/util'
import { activeChildId$, isAuthenticated$ } from '@op/services'
import { createSelector } from '@reduxjs/toolkit'
import { canShowMenuView$, manageFeature$, rawRedirect$ } from '../reducers/app-misc.slice'
import type { ManageRoutePath } from '../types'

export const redirect$ = /*@__PURE__*/ createSelector(
	isAuthenticated$,
	rawRedirect$,
	(isAuth, rawRedirect) => (isAuth ? rawRedirect || '/manage' : '/login'),
)

export const getManageLink = (feature: ManageRoutePath, userId: Maybe<string>) =>
	`/manage${userId ? `/${userId}/${feature}` : ''}`

export const manageLink$ = /*@__PURE__*/ createSelector(
	manageFeature$,
	activeChildId$,
	getManageLink,
)

export const baseLink$ = /*@__PURE__*/ createSelector(
	isAuthenticated$,
	manageLink$,
	(isAuth, manageLink) => (isAuth ? manageLink : '/login'),
)

export const isMenuView$ = /*@__PURE__*/ createSelector(
	canShowMenuView$,
	isAuthenticated$,
	selectUnaryEvery,
)
