import { lazy } from 'react'

export const BottomSheet = /*@__PURE__*/ lazy(
	() =>
		import(
			/* webpackChunkName: 'bottom-sheet' */
			/* webpackPrefetch: 0 */
			'./BottomSheet'
		),
)
