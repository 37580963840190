import { memo } from 'react'
import { ZOverlay } from '../../constants/z-index'
import { Backdrop } from '../Backdrop'
import { useAppOverlayVis } from './useOverlay'

type AppOverlayProps = {
	readonly className?: string
	readonly fixed?: boolean
	readonly zIndex?: number
}

const Overlay = ({ className, fixed = true, zIndex = ZOverlay }: AppOverlayProps) => {
	const isVisible = useAppOverlayVis()

	return <Backdrop className={className} fixed={fixed} visible={isVisible} zIndex={zIndex} />
}

export default /*@__PURE__*/ memo(Overlay)
