import './CarouselIconBtn.scss'

import cls from 'classnames'
import type { ActionBtnProps } from '../Buttons'
import { LoadingActionBtn } from '../Buttons'

type CarouselIconBtnProps = ActionBtnProps & {
	readonly hidden?: boolean
	readonly invisible?: boolean
	readonly isLoading?: boolean
}

export const CarouselIconBtn = ({
	className,
	hidden = false,
	iconCls,
	invisible,
	isLoading = false,
	...p
}: CarouselIconBtnProps) => (
	<LoadingActionBtn
		className={cls(
			'carousel-icon-btn transition-opacity',
			hidden && '!hidden',
			invisible && '!cursor-default opacity-0 !pointer-events-none',
			className,
		)}
		iconCls={cls('!text-white', iconCls)}
		isLoading={isLoading}
		invert
		{...p}
	/>
)
