import { duration } from '@eturi/date-util'
import { ImmutSet, notEmpty } from '@eturi/util'
import type { Selector } from '@reduxjs/toolkit'
import { createSelector } from '@reduxjs/toolkit'
import keys from 'lodash/keys'
import orderBy from 'lodash/orderBy'
import pickBy from 'lodash/pickBy'
import type { UserSamplesState } from '../reducers'
import {
	activeChildId$,
	getUserSamplesState,
	isVewEnabled$,
	userSamples$,
	vewNowRequests$,
	vewUserConfigMap$,
} from '../reducers'
import type {
	AnyDecoratedSample,
	OcrCat,
	OcrCatFilter,
	SampleOcrHits,
	VewConfig,
	VewNowReqState,
} from '../types'

export const samplesStateForActive$ = /*@__PURE__*/ createSelector(
	userSamples$,
	activeChildId$,
	(samplesState, activeChildId): UserSamplesState =>
		getUserSamplesState(samplesState, activeChildId || ''),
)

export const samplesForActive$ = /*@__PURE__*/ createSelector(
	samplesStateForActive$,
	(s) => s.samples,
)

export const hasSamplesForActive$ = /*@__PURE__*/ createSelector(samplesForActive$, notEmpty)

// Thumbnails
export const samplesListForActive$ = /*@__PURE__*/ createSelector(
	samplesForActive$,
	// Convert the map to a list and filter out any samples that are expired.
	(samplesMap): AnyDecoratedSample[] => orderBy(samplesMap, 'sample_ts', 'desc'),
)

export const hasNonExpiredSamplesForActive$ = /*@__PURE__*/ createSelector(
	samplesListForActive$,
	notEmpty,
)

export const createFilteredSamplesSelector = (
	deviceFilters$: Selector<any, ImmutSet<string>>,
	ocrFilters$: Selector<any, ImmutSet<OcrCatFilter>>,
) =>
	createSelector(
		samplesListForActive$,
		deviceFilters$,
		ocrFilters$,
		(samples, deviceFilters, ocrFilters) => {
			const isDeviceFilterEmpty = !deviceFilters.size
			const isOcrFilterEmpty = !ocrFilters.size

			// No filters active, return everything
			if (isDeviceFilterEmpty && isOcrFilterEmpty) return samples

			return samples.filter((sample) => {
				if (!isDeviceFilterEmpty && !deviceFilters.has(sample.device_id)) return false

				if (!isOcrFilterEmpty) {
					// Get categories for sample, grabbing only categories that actually have a value to them
					const hits = keys(sample.hits).filter((k: OcrCat) => sample.hits[k])

					if (!hits.length && ocrFilters.has('uncategorized')) return true

					return hits.some((hit) => ocrFilters.has(hit))
				}

				return true
			})
		},
	)

const SAMPLE_EXPIRES_SOON_MS = duration(1, 'd')
/**
 * Determines if a sample will expire in the next 24 hours
 */
export const isSampleExpiredSoon = (expiryTs: number, { dayTs }: AnyDecoratedSample) =>
	dayTs <= expiryTs + SAMPLE_EXPIRES_SOON_MS

export const isVewEnabledForActive$ = /*@__PURE__*/ createSelector(
	activeChildId$,
	vewUserConfigMap$,
	(activeId, vewUserConfig) => Boolean(vewUserConfig[activeId || '']?.user_enabled),
)

export const isGalleryEnabledForActive$ = /*@__PURE__*/ createSelector(
	isVewEnabled$,
	isVewEnabledForActive$,
	hasSamplesForActive$,
	(isVewEnabled, isVewEnabledForActive, hasSamplesForActive) =>
		isVewEnabled && (isVewEnabledForActive || hasSamplesForActive),
)

export const vewConfigForActive$ = /*@__PURE__*/ createSelector(
	activeChildId$,
	vewUserConfigMap$,
	(id, s): Maybe<VewConfig> => s[id || ''],
)

export const vewNowForActive$ = /*@__PURE__*/ createSelector(
	activeChildId$,
	vewNowRequests$,
	(id, s): Maybe<VewNowReqState> => s[id || ''],
)

const _configTermsSet =
	(ocrKey: 'custom_terms' | 'ignore_terms') =>
	(config: Maybe<VewConfig>): ImmutSet<string> =>
		new ImmutSet(config?.ocr?.[ocrKey]?.map((term) => term.toLowerCase()))

export const customTermsForActive$ = /*@__PURE__*/ createSelector(
	vewConfigForActive$,
	/*@__PURE__*/ _configTermsSet('custom_terms'),
)

export const ignoreTermsForActive$ = /*@__PURE__*/ createSelector(
	vewConfigForActive$,
	/*@__PURE__*/ _configTermsSet('ignore_terms'),
)

export type OcrImageCount = Required<SampleOcrHits> & {
	readonly total: number
	readonly uncategorized: number
}

export const createOcrImageCountSelector = (
	filteredSamplesList$: Selector<any, AnyDecoratedSample[]>,
) =>
	createSelector(filteredSamplesList$, (filteredSamples): OcrImageCount => {
		const ocrImageCount: Writable<OcrImageCount> = {
			custom: 0,
			profanity: 0,
			sexual_content: 0,
			substance_abuse: 0,
			total: filteredSamples.length,
			uncategorized: 0,
			violence: 0,
		}

		for (const { hits } of filteredSamples) {
			// Only grab keys that have hits on them, iOS sends keys w/ 0 hits,
			// android only sends keys w/ hits
			const filteredHits = pickBy(hits, (v) => !!v)

			const hitsKeys = Object.keys(filteredHits) as (keyof OcrImageCount)[]

			// If there are no hits, it's uncategorized
			if (!hitsKeys.length) {
				ocrImageCount.uncategorized++
				continue
			}

			// Iterate any hits. The presence of the key indicates a hit.
			for (const hit of hitsKeys) {
				// We do the hasOwnProperty check in case a new hit type is added in the
				// future that isn't in the current `OcrImageCount` type.
				if (hit in ocrImageCount) {
					ocrImageCount[hit]++
				}
			}
		}

		return ocrImageCount
	})

export const createSampleExpirySelector = (sampleExpiryTs$: Selector<any, number>) =>
	createSelector(
		sampleExpiryTs$,
		(_: any, sample: AnyDecoratedSample) => sample,
		(sampleExpiryTs, sample) => isSampleExpiredSoon(sampleExpiryTs, sample),
	)
