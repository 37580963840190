import {
	activeBenefit$,
	availableBenefit$,
	getSKUPriceStrWithBenefit,
	getSkuPriceStrWithBenefitBreakdown,
	isExtendedTrialBenefit,
	isPlatformSKU,
	isStripeCouponBenefit,
	mapTierToFullName,
	mapTierToName,
	nextSKU$,
	type PaidTier,
	redeemedBenefit$,
	type SKUDef,
	skuDefs$,
	stripeSKUPrem$,
	stripeSKUPremPlus$,
} from '@op/services'
import { createSelector } from '@reduxjs/toolkit'
import isEmpty from 'lodash/isEmpty'
import { subscribingCycle$ } from '../reducers/product.slice'
import { isSubscribingStripe$, subscribingSKU$ } from './product'

export const subscribingPriceStr$ = /*@__PURE__*/ createSelector(
	activeBenefit$,
	subscribingSKU$,
	isSubscribingStripe$,
	getSKUPriceStrWithBenefit,
)

// Returns the highest tier sku they have a benefit for
export const highestBenefitSKU$ = /*@__PURE__*/ createSelector(
	activeBenefit$,
	skuDefs$,
	stripeSKUPrem$,
	stripeSKUPremPlus$,
	(activeBenefit, skuDefs, stripeSKUPrem, stripeSKUPremPlus): Maybe<SKUDef> => {
		// FIXME: Product needs to handle the case where we have both available and redeemed on
		// one account, for sake of rushed promo we are using reedemed as first choice
		if (!(isExtendedTrialBenefit(activeBenefit) || isStripeCouponBenefit(activeBenefit))) return

		const benefitSKUs = activeBenefit.details.limit_skus

		if (isEmpty(benefitSKUs)) return stripeSKUPremPlus

		const stripeSKUs = skuDefs.filter((s) => isPlatformSKU(s, 'stripe'))

		// `benefitSKUs` is an array if it's not empty, which we checked above
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
		return benefitSKUs!.reduce((subscribingSKU: SKUDef, sku) => {
			const newDef = stripeSKUs.find((s) => s.sku === sku)
			return newDef && newDef.weight > subscribingSKU.weight ? newDef : subscribingSKU
		}, stripeSKUPrem)
	},
)

// Returns all skus with a benefit available
export const availableBenefitSKUs$ = /*@__PURE__*/ createSelector(
	activeBenefit$,
	skuDefs$,
	(activeBenefit, skuDefs): SKUDef[] => {
		if (!(isExtendedTrialBenefit(activeBenefit) || isStripeCouponBenefit(activeBenefit))) return []

		const benefitSKUs = activeBenefit.details.limit_skus

		if (isEmpty(benefitSKUs)) return []

		// Get available SKUs for the benefit
		const stripeSKUs = skuDefs.filter((s) => isPlatformSKU(s, 'stripe'))

		// `benefitSKUs` is an array if it's not empty, which we checked above
		// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
		return stripeSKUs.filter((sku) => benefitSKUs!.includes(sku.sku))
	},
)

export const hasPremBenefitSKU$ = /*@__PURE__*/ createSelector(
	availableBenefitSKUs$,
	stripeSKUPrem$,
	(availableBenefitSKUs, premSKU) =>
		Boolean(availableBenefitSKUs.find((s) => s.tier === premSKU.tier)),
)

export const hasPremPlusBenefitSKU$ = /*@__PURE__*/ createSelector(
	availableBenefitSKUs$,
	stripeSKUPremPlus$,
	(availableBenefitSKUs, premPlusSKU) => availableBenefitSKUs.includes(premPlusSKU),
)

export const subscribingPriceBreakdown$ = /*@__PURE__*/ createSelector(
	activeBenefit$,
	subscribingSKU$,
	isSubscribingStripe$,
	getSkuPriceStrWithBenefitBreakdown,
)

export const selectedBenefitSKU$ = /*@__PURE__*/ createSelector(
	availableBenefitSKUs$,
	subscribingSKU$,
	subscribingCycle$,
	(availableBenefits, subSKU, subCycle): Maybe<SKUDef> => {
		if (isEmpty(availableBenefits) || !(subSKU && subCycle)) return null

		return availableBenefits.find((s) => s.sku === subSKU.sku)
	},
)

export const selectedSKUHasBenefit$ = /*@__PURE__*/ createSelector(selectedBenefitSKU$, (sku) =>
	Boolean(sku),
)

export const selectedBenefitSKUCycle$ = /*@__PURE__*/ createSelector(
	selectedBenefitSKU$,
	(s) => s?.billing_cycle,
)
export const selectedBenefitSKUId$ = /*@__PURE__*/ createSelector(
	selectedBenefitSKU$,
	(s) => s?.sku || '',
)
export const selectedBenefitSKUTier$ = /*@__PURE__*/ createSelector(
	selectedBenefitSKU$,
	(s) => s?.tier_def as Maybe<PaidTier>,
)
export const selectedBenefitSKUName$ = /*@__PURE__*/ createSelector(
	selectedBenefitSKUTier$,
	mapTierToName,
)
export const selectedBenefitSKUFullName$ = /*@__PURE__*/ createSelector(
	selectedBenefitSKUTier$,
	mapTierToFullName,
)

export const selectedBenefitTierHasMonthly$ = /*@__PURE__*/ createSelector(
	availableBenefitSKUs$,
	selectedBenefitSKU$,
	(availableBenefits, selectedSKU) => {
		if (!selectedSKU) return false

		if (selectedSKU.billing_cycle === 'monthly') return true

		return Boolean(
			availableBenefits.find(
				(sku) => sku.tier === selectedSKU.tier && sku.billing_cycle === 'monthly',
			),
		)
	},
)

export const selectedBenefitTierHasAnnual$ = /*@__PURE__*/ createSelector(
	availableBenefitSKUs$,
	selectedBenefitSKU$,
	(availableBenefits, selectedSKU) => {
		if (!selectedSKU) return false

		if (selectedSKU.billing_cycle === 'annual') return true

		return Boolean(
			availableBenefits.find(
				(sku) => sku.tier === selectedSKU.tier && sku.billing_cycle === 'annual',
			),
		)
	},
)

export const highestBenefitSKUCycle$ = /*@__PURE__*/ createSelector(
	highestBenefitSKU$,
	(s) => s?.billing_cycle,
)
export const highestBenefitSKUTier$ = /*@__PURE__*/ createSelector(
	highestBenefitSKU$,
	(s) => s?.tier_def as Maybe<PaidTier>,
)

export const subscribingSKUBenefit$ = /*@__PURE__*/ createSelector(
	subscribingSKU$,
	redeemedBenefit$,
	(subSKU, redeemedBenefit) => {
		if (!(subSKU && redeemedBenefit)) return

		if (
			isStripeCouponBenefit(redeemedBenefit) &&
			redeemedBenefit.details.limit_skus?.includes(subSKU.sku)
		) {
			return redeemedBenefit
		}
	},
)

export const nextSKUBenefit$ = /*@__PURE__*/ createSelector(
	nextSKU$,
	availableBenefit$,
	(nextSKU, availableBenefit) => {
		if (!(nextSKU && availableBenefit)) return

		if (
			isStripeCouponBenefit(availableBenefit) &&
			availableBenefit.details.limit_skus?.includes(nextSKU.sku)
		) {
			return availableBenefit
		}
	},
)
