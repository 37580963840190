import type { ReactNode } from 'react'
import { createPortal } from 'react-dom'

type FixedPositionElementProps = {
	readonly children: ReactNode
}

const $FIXED_POSITION_EL = document.getElementById('fixed-position-element')!

// NOTE: This component allows us to place an fixed element on top of the app. Since our app uses
//  transformZ, position fixed elements wont be positioned within the viewport but rather at the
//  bottom of the app. This places the element outside of the transform context so they can then be
//  positioned within the viewport as expected.

export const FixedPositionElement = ({ children }: FixedPositionElementProps) =>
	createPortal(children, $FIXED_POSITION_EL)
