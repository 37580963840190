import { lazy, memo } from 'react'
import { LoadPage } from '../../widgets'
import GalleryNavBar from './GalleryNavBar'

const GalleryPageContent = /*@__PURE__*/ lazy(() => import('./GalleryPageContent'))

const GalleryPage = () => (
	<div className="gallery-page">
		<GalleryNavBar />
		<LoadPage>
			<GalleryPageContent />
		</LoadPage>
	</div>
)

export default /*@__PURE__*/ memo(GalleryPage)
