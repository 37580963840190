import { useT } from '@eturi/react-i18next'
import { PREM_PLUS_NAME } from '@op/services'
import { lazy, memo, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { isAffiliate$ } from '../../compound-selectors/affiliate'
import { trialDurationInDays$ } from '../../compound-selectors/product'
import { useNavTo } from '../../hooks'
import { useSearchParams } from '../../hooks/useSearchParams'
import type { SubscriptionType } from '../../types'
import { isSubscriptionType } from '../../types'
import { LoadPage, NavBar } from '../../widgets'

// We normalize the type in case someone accesses the url directly without
// query params
const normalizeSubType = (type: Maybe<string>): SubscriptionType =>
	isSubscriptionType(type) ? type : 'options'

const SubscriptionPageContent = lazy(() => import('./SubscriptionPageContent'))

const SubscriptionPage = () => {
	const [t] = useT()
	const navTo = useNavTo()
	const trialDurationInDays = useSelector(trialDurationInDays$)
	const searchParams = useSearchParams()
	const isAffiliate = useSelector(isAffiliate$)

	// Special handle for trial, if they are an affiliate and have a trial available then we show
	// the affiliate trial page
	const [chooseSubType, goBack] = useMemo(() => {
		let navSubType = normalizeSubType(searchParams.get('chooseSubType'))

		if (isAffiliate && navSubType === 'trial') {
			navSubType = 'affiliate'
		}

		return [navSubType, searchParams.get('goBack') === '1']
	}, [isAffiliate])

	const PageTitle = (() => {
		switch (chooseSubType) {
			case 'options':
				return t('subscription.subscription_options')

			case 'prem_plus':
				return t('actions.upgrade_to_tier', { tier: PREM_PLUS_NAME })

			case 'affiliate':
			case 'prem_plus_trial':
			case 'trial':
				return t('subscription.title_trial', { trialDays: trialDurationInDays })

			case 'upgrade':
				return t('subscription.upgrade_options')
		}
	})()

	// When the page loads we clear query search params from url
	useEffect(() => {
		navTo({ search: '' }, true)
	}, [])

	return (
		<article className="page is-page-pad-touch">
			<NavBar.Default goBack={goBack}>{PageTitle}</NavBar.Default>

			<LoadPage>
				<SubscriptionPageContent type={chooseSubType} />
			</LoadPage>
		</article>
	)
}

export default /*@__PURE__*/ memo(SubscriptionPage)
