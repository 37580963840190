import { availableBenefit$, fetchCouponDetails, fetchGroupDetails, unwrapThunk } from '@op/services'
import { createResolverRedirect } from '../../common'
import { highestBenefitSKUCycle$, highestBenefitSKUTier$ } from '../../compound-selectors/benefits'
import { getEndpoints } from '../../env'
import { setSubscribingState } from '../../reducers/product.slice'
import { resolveSerialRootAuth } from '../../route-resolvers'
import type { AppStore } from '../../types'

const promoPageResolver = async (store: AppStore) => {
	await unwrapThunk(store.dispatch(fetchCouponDetails()))
	const state = store.getState()

	// User might have many skus available so we set the highest level sku
	// as the initial one
	const highestBenefitSKUTier = highestBenefitSKUTier$(state)
	const highestBenefitSKUCycle = highestBenefitSKUCycle$(state)

	// If we don't have a promo benefit after fetching coupon details, exit,
	// as this means we don't have a promo available on this account.
	if (!highestBenefitSKUTier) throw createResolverRedirect('/')

	const benefitGroupId = availableBenefit$(state)?.account?.coupon_group_id

	// If the coupon is associated with a group we pull down those benefit details as well
	if (benefitGroupId) {
		await store
			.dispatch(fetchGroupDetails({ groupId: benefitGroupId, sfsh: getEndpoints()!.sfsh }))
			.unwrap()
	}

	// Going to promo page we also set the subscribing SKU tier since the Stripe
	// payment stuff is there and that relies on subscribingSKU not benefitSKU.
	store.dispatch(
		setSubscribingState({ cycle: highestBenefitSKUCycle || 'annual', tier: highestBenefitSKUTier }),
	)
}

export const resolvePromoPage = /*@__PURE__*/ resolveSerialRootAuth(promoPageResolver)
