import './Modal.scss'

import { useWindowEvent } from '@eturi/react'
import { useT } from '@eturi/react-i18next'
import { isEscape, PageFocus, useBackButton, useHandleSynStop } from '@op/react-web'
import cls from 'classnames'
import noop from 'lodash/noop'
import { useEffect, useState } from 'react'
import { useFreezeScroll, useIsScreenMobile } from '../../hooks'
import { TestID } from '../../TestID'
import { Backdrop } from '../Backdrop'
import { ActionBtn } from '../Buttons'
import { FixedPositionElement } from '../FixedPositonElement'
import { NavBar, NavSlideBox } from '../Nav'
import { ModalFooterCtx } from './ModalFooterCtx'
import type { ModalSize, SharedModalProps } from './types'

type BaseModalSize = ModalSize | 'info'

type BaseModalProps = SharedModalProps & {
	readonly className?: string
	readonly dialogCls?: string
	readonly header?: string
	readonly hideClose?: boolean
	readonly isHybrid?: boolean
	// By default, we isolate clicks to within the modals themselves. If a modal is the child of
	// the element that opens it using a click event, the modal won't be able to close via click,
	// because any click events to close the modal will bubble up and re-open it. A viable option
	// would also be to  isolate only the backdrop and close button. However, clicks from the
	// modal content would stop propagate. This might be fine, but in general it's a bit odd.
	//
	// The reason this is an option is that we may not want to stop propagation if we want to
	// implement straightforward click-off behavior within the modal. If we stop propagation
	// we can't use a `useWindowEvent` for click-off behavior. Obviously, if you set this prop
	// to `false`, you need to make sure it's not a child of the component that opens it.
	//
	// Ultimately, we could fix this for good and remove this prop if we instead implement a
	// comprehensive click-off behavior within modals.
	readonly isolateClicks?: boolean
	readonly size?: BaseModalSize
}

export const BaseModal = ({
	children,
	className,
	dialogCls,
	disableOutsideClose = false,
	header,
	hideClose = false,
	isHybrid = false,
	isolateClicks = true,
	onClose = noop,
	size = 'default',
}: BaseModalProps) => {
	const [t] = useT()
	const freezeScrolling = useFreezeScroll()
	const isScreenMobile = useIsScreenMobile()
	const [footerHt, setFooterHt] = useState<number | null>(null)

	const shouldRenderHybridHeader = isHybrid && isScreenMobile
	const shouldRenderCloseBtn = !(hideClose || shouldRenderHybridHeader)
	const shouldRenderNormalHeader = !shouldRenderHybridHeader && header != null

	const handleBackdropClick = useHandleSynStop(() => {
		!disableOutsideClose && onClose()
	}, isolateClicks)

	const handleCloseClick = useHandleSynStop(onClose, isolateClicks)
	const handleDialogClick = useHandleSynStop(undefined, isolateClicks)

	useWindowEvent('keydown', (ev) => isEscape(ev) && onClose(), undefined, !disableOutsideClose)

	useEffect(() => {
		freezeScrolling(true)
	}, [])

	// NOTE: Careful when going from hybrid modal to hybrid modal if they
	//  both share the same display state i.e Guard Modals. In Mobile
	//  useBackButton will cause a race condition where one hook starts
	//  listening to history before the previous hook calls history.goBack
	//  (unmount) this will trigger the new hooks new to call onClose causing
	//  the new hybrid modal to immediately close
	useBackButton(onClose, !shouldRenderHybridHeader)

	return (
		<ModalFooterCtx.Provider value={setFooterHt}>
			<FixedPositionElement>
				<NavSlideBox className={cls('modal !pl-0', isHybrid && 'modal--hybrid', className)}>
					<PageFocus />

					<div className="modal__grid-center">
						<Backdrop fixed={false} onClick={handleBackdropClick} visible zIndex={0} />

						<div
							className={cls(`modal__dialog modal__dialog--${size}`, dialogCls)}
							onClick={handleDialogClick}
						>
							{shouldRenderCloseBtn && (
								<ActionBtn
									className="absolute -top-4 -right-4 z-50"
									data-testid={TestID.BTN_MODAL_CLOSE_TID$$}
									icon="x"
									onClick={handleCloseClick}
									size="sm"
									title={t('actions.close')}
								/>
							)}

							<div
								className={cls(
									// NOTE: This exists for sticky nodes and scrollbar
									'modal__rounded-box rounded-2xl overflow-hidden',
									shouldRenderHybridHeader && 'rounded-none',
								)}
							>
								<div className="modal__scroll-dingle touch-scroll-y">
									{shouldRenderHybridHeader && (
										<nav className="top-nav-wrapper bg-white sticky left-0 right-0 top-0 z-hack">
											<div className="top-nav flex h-full items-center relative">
												{!hideClose && (
													<ActionBtn
														data-testid={TestID.BTN_MODAL_CLOSE_TID$$}
														icon="chevron-left"
														invert
														onClick={onClose}
													/>
												)}
												<div className="flex-auto" />
												<NavBar.Title>{header || ''}</NavBar.Title>
												<div className="flex-auto" />
												{!hideClose && <div className="btn invisible" />}
											</div>
										</nav>
									)}

									{shouldRenderNormalHeader && (
										<header className="modal__header bg-white flex-center py-1 px-6 sm:py-2 sm:px-8 md:py-2.5 sticky top-0 z-20">
											<h2 className="break-word leading-tight pt-1.5 text-center text-xl uppercase md:text-2xl md:leading-snug">
												{header}
											</h2>
										</header>
									)}

									<div
										className={cls('modal__content')}
										style={{ paddingBottom: footerHt || undefined }}
									>
										{children}
									</div>
								</div>
							</div>
						</div>
					</div>
				</NavSlideBox>
			</FixedPositionElement>
		</ModalFooterCtx.Provider>
	)
}
