import { usePrevious } from '@eturi/react'
import { activeChildId$, hasChildren$, isAuthenticated$ } from '@op/services'
import { memo, useEffect, useLayoutEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useAnalytics } from '../analytics'
import { useIsScreenLG, useNavTo } from '../hooks'
import { setCanShowMenuView, setMobileManageTabsVisible } from '../reducers/app-misc.slice'
import { useAppDispatch } from '../types'

const NO_NAV_PATHS = ['/forgot', '/login', '/pair', '/promo', '/reset', '/signup', '/verify']
const RESET_PATH_REGEXP = /^\/reset\/?$/

type ExtraPathContext = 'Dashboard' | 'Gallery' | 'Locator' | 'Notification' | 'Ignore' | 'Unknown'

const SWITCH_CHILD_CONTEXT_MAP = {
	gallery: 'Gallery',
	locator: 'Locator',
	manage: 'Dashboard',
	notifications: 'Notification',
} satisfies { readonly [k: string]: ExtraPathContext }

const getPathInfo = (pathname: string): [string, ExtraPathContext] => {
	let normalizedPath = pathname
	let extraPathContext: ExtraPathContext = 'Unknown'

	try {
		const paths = pathname.split('/')
		const [, basePath] = paths
		// Lookup the path in map.
		const ctx = (SWITCH_CHILD_CONTEXT_MAP as any)[basePath] as Maybe<ExtraPathContext>

		if (ctx) {
			// If the path is in the map, make sure the number of parts is correct, and rewrite
			// If not correct, ignore
			if (ctx == 'Dashboard' ? paths.length == 4 : paths.length == 3) {
				paths.splice(2, 1, ctx == 'Notification' ? ':notifId' : ':userId')
				extraPathContext = ctx
				normalizedPath = paths.join('/')
			} else {
				extraPathContext = 'Ignore'
			}
			// Ignore the 'fake' logout route. We already track logout separately, and it's not a real
			// page. If we need to ignore more routes, we can create a Set and use that.
		} else if (basePath == 'logout') {
			extraPathContext = 'Ignore'
		}
		return [normalizedPath, extraPathContext]
	} catch {
		return [normalizedPath, extraPathContext]
	}
}

const RouteWatcher = () => {
	const d = useAppDispatch()
	const activeChildId = useSelector(activeChildId$)
	const hasChildren = useSelector(hasChildren$)
	const isAuthenticated = useSelector(isAuthenticated$)
	const navTo = useNavTo()
	const track = useAnalytics()
	const { pathname } = useLocation()

	const didHaveChildren = usePrevious(hasChildren)
	const prevActiveChildId = usePrevious(activeChildId) || activeChildId
	const prevPath = useRef<string | undefined>()
	const wasAuthenticated = usePrevious(isAuthenticated)

	const [normalizedPath, extraPathContext] = getPathInfo(pathname)
	const shouldIgnoreTracking = extraPathContext === 'Ignore'

	const shouldShowMobileMenuTabs = !useIsScreenLG() && extraPathContext === 'Dashboard'
	/**
	 * We want to redirect to the login page if we go from authenticated to
	 * un-authenticated. But only if we're not on the password reset page. When
	 * we do `resetPwAction`, we log in to register a key pair, then log back
	 * out so the user can confirm their credentials. This isn't necessary and
	 * may change in the future, but for now we need to have a special case for
	 * the `/reset` page.
	 *
	 * NOTE: We have to do a RegExp test for reset b/c the server currently
	 *  includes a trailing `/` which will be removed.
	 *
	 * @see resetPwAction
	 */
	const shouldRedirectToLogin =
		!isAuthenticated && wasAuthenticated && !RESET_PATH_REGEXP.test(pathname)

	// Catch logout / de-auth and send to login page
	useEffect(() => {
		shouldRedirectToLogin && navTo('/login', true)
	}, [shouldRedirectToLogin])
	// Redirect to /connect when logged in and going from having children to not
	// logging out will trigger this unless we check if we're authenticated still
	useEffect(() => {
		if (isAuthenticated && didHaveChildren && !hasChildren) navTo('/connect', true)
	}, [didHaveChildren, hasChildren, isAuthenticated])

	// Record page views and set navigation visibility based on pathname
	useLayoutEffect(() => {
		d(setCanShowMenuView(!NO_NAV_PATHS.some((p) => pathname.startsWith(p))))

		// Don't track page view if paths are the same or invalid
		if (normalizedPath == prevPath.current || shouldIgnoreTracking) return

		prevPath.current = normalizedPath
		track('PageView', { page: normalizedPath })
	}, [normalizedPath])

	useLayoutEffect(() => {
		d(setMobileManageTabsVisible(shouldShowMobileMenuTabs))
	}, [shouldShowMobileMenuTabs])

	if (!shouldIgnoreTracking && activeChildId && activeChildId !== prevActiveChildId) {
		track('Switch Child', { context: extraPathContext })
	}

	return null
}

export default /*@__PURE__*/ memo(RouteWatcher)
