import noop from 'lodash/noop'
import { createContext } from 'react'

/**
 * ModalFooterCtx is a function that, when called, registers a footer as rendered, and then
 * returns a clean-up function to unregister.
 */
export type ModalFooterCtx = (height: number | null) => void

export const ModalFooterCtx = createContext<ModalFooterCtx>(noop)
