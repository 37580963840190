import { lazy } from 'react'

export const PairPage = /*@__PURE__*/ lazy(
	() =>
		import(
			/* webpackChunkName: 'pair' */
			/* webpackPrefetch: 100 */
			'./PairPage'
		),
)
