import { duration } from '@eturi/date-util'
import { assertNotNullish, setIfNotEqual } from '@eturi/util'
import { keysToInt } from '@op/util'
import type { Draft, Selector } from '@reduxjs/toolkit'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { castDraft } from 'immer'
import forOwn from 'lodash/forOwn'
import { createSliceTransformer } from 'rtk-slice-transformer'
import { resetAction } from '../actions'
import { bindCreateAsyncThunkToState } from '../bindCreateAsyncThunkToState'
import type { HttpExtra } from '../http'
import {
	type AppDetails,
	type AppDetailsMap,
	type AppDetailsRes,
	genNotFoundAppDetails,
	type InitState,
	mapBuiltInRawAppInfoToDetails,
	mapRawAppInfoToDetails,
	mergeServerAppsData,
	type NormalizedAppsDataForDevices,
	type NormalizedDeviceApp,
	type RawAppsDataForDevices,
	SpecialApp,
} from '../types'

export type UserAppsState = InitState & {
	readonly apps: RawAppsDataForDevices
}

export const createUserAppsState = (): UserAppsState => ({
	apps: {},
	isInit: false,
})

export type AppsState = {
	readonly appDetailsMap: AppDetailsMap
	readonly userApps: { readonly [userId: string]: UserAppsState }
}

export type WithAppsState = {
	readonly apps: AppsState
}

const initialState: AppsState = {
	appDetailsMap: {},
	userApps: {},
}

const ensureUserState = (s: Draft<AppsState>, userId: string) =>
	(s.userApps[userId] ||= castDraft(createUserAppsState()))

export const appsSlice = /*@__PURE__*/ createSlice({
	name: 'apps',
	initialState,
	reducers: {},
	extraReducers: (builder) =>
		builder
			.addCase(resetAction, () => initialState)
			.addCase(fetchAppDetailsForUser.fulfilled, (s, a) => {
				Object.assign(s.appDetailsMap, a.payload)
			})
			.addCase(fetchAppsForUser.fulfilled, (s, a) => {
				const userAppsState = ensureUserState(s, a.meta.arg.userId)

				setIfNotEqual(userAppsState, 'apps', mergeServerAppsData(a.payload))
				userAppsState.isInit = true
			}),
})

export const appsSliceTransformer = /*@__PURE__*/ createSliceTransformer(appsSlice, (s) =>
	keysToInt(s, ['appDetailsMap', 'userApps']),
)

////////// Thunks //////////////////////////////////////////////////////////////

/**
 * Goes through installed apps and identifies which app bundle ids need details
 * to be downloaded.
 */
const getMissingOrExpiredBundleIds = (
	rawAppsDataForDevices: RawAppsDataForDevices,
	details: AppDetailsMap,
	locale: string,
) => {
	const missingOrExpiredBundleIds = new Set<string>()
	// We set the time we consider apps to be too old
	const ttl = Date.now() - duration(5, 'd')

	// For each device, add any bundle ids that don't have details
	forOwn(rawAppsDataForDevices, (rawAppsData) => {
		forOwn(rawAppsData?.installed_apps, (_, bundleId) => {
			if (!details[bundleId]) missingOrExpiredBundleIds.add(bundleId)
		})
	})

	forOwn(details, (app, bundleId) => {
		if (!app) return

		// NOTE: These were "new" values so cached versions previously may not have had these.
		//  We normalize so we can do the same comparison.
		const appLocale = app.locale || ''
		const fetchTs = app.fetchTs || 0

		if (fetchTs < ttl || appLocale !== locale) {
			missingOrExpiredBundleIds.add(bundleId)
		}
	})

	return missingOrExpiredBundleIds
}

const createAsyncThunk = /*@__PURE__*/ bindCreateAsyncThunkToState<WithAppsState>()

type FetchAppDetailsForUserArg = {
	readonly extraAppData?: RawAppsDataForDevices
	readonly locale: string
	readonly userId: string
}

export const fetchAppDetailsForUser = /*@__PURE__*/ createAsyncThunk(
	'apps/details/fetch',
	async ({ extraAppData, locale, userId }: FetchAppDetailsForUserArg, { getState }) => {
		const state = getState()
		const appDetailsMap = appDetailsMap$(state)
		const userAppsStateMap = userAppsState$(state)
		const { apps } = userAppsStateMap[userId] || createUserAppsState()

		// We check for apps and mock sample data, if we have it, it'll be filtered out
		const missingBundleIds = getMissingOrExpiredBundleIds(
			{ ...apps, ...extraAppData },
			appDetailsMap,
			locale,
		)
		const updatedAppDetailsMap: Writable<AppDetailsMap> = {}

		await Promise.all(
			[...missingBundleIds].map(async (bundleId) => {
				try {
					const appInfo = await fetch(
						`https://lambda.ourpact.com/api/appinfo/${bundleId}?locale=${locale}`,
					).then((res): Promise<AppDetailsRes> => res.json())

					if (appInfo.status === 'success') {
						let appDetails: AppDetails

						switch (appInfo.meta.info_type) {
							case 'not_found': {
								// NOTE: We generate an empty payload for not found apps so there is something
								//  truthy in app details and we don't fetch for this app on every reload
								appDetails = genNotFoundAppDetails(locale)
								break
							}

							case 'builtin': {
								appDetails = mapBuiltInRawAppInfoToDetails(appInfo.info, locale)
								break
							}

							default: {
								// NOTE: This default tries to future-proof. If different values are added to
								//  RawAppMetaType (i.e. we start to distinguish between different app stores),
								//  older apps will still try to parse info, and fails will be handled by try/catch
								appDetails = mapRawAppInfoToDetails(appInfo.info, locale)
							}
						}

						updatedAppDetailsMap[bundleId] = appDetails
					}
				} catch {
					updatedAppDetailsMap[bundleId] = null
				}
			}),
		)

		return updatedAppDetailsMap
	},
)

type FetchAppsForUserArg = HttpExtra & {
	readonly userId: string
}
export const fetchAppsForUser = /*@__PURE__*/ createAsyncThunk(
	'apps/user/fetch',
	async ({ userId, ...extra }: FetchAppsForUserArg, { dispatch, extra: { http } }) => {
		const rawAppsData = await dispatch(
			http.get<Maybe<RawAppsDataForDevices[]>>(`/installed_apps?user_id=${userId}`, extra),
		)

		assertNotNullish(rawAppsData)

		return rawAppsData
	},
	{
		condition: (arg, api) => {
			if (!arg.force && userAppsState$(api.getState())[arg.userId]?.isInit) return false
		},
	},
)

/**
 * Sends a command that refreshes a device's app list while the user is engaged
 * with the app.
 */
export const refreshUserApps = /*@__PURE__*/ createAsyncThunk(
	'apps/refresh',
	async (userId: string, { dispatch, extra: { http } }) => {
		await dispatch(
			http.post('/command', {
				data: {
					command: 'app_list',
					rule_type: 'query',
					user_id: userId,
				},
			}),
		)
	},
)

////////// Selectors ///////////////////////////////////////////////////////////

const state$ = <T extends WithAppsState>(s: T) => s.apps
const userAppsState$ = /*@__PURE__*/ createSelector(state$, (s) => s.userApps)

export const appDetailsMap$ = /*@__PURE__*/ createSelector(state$, (s) => s.appDetailsMap)

/**
 * NOTE: We don't export the state selector raw, because we to normalize the SpecialApp cases first.
 *  We could do this in the result we get from the server, but I don't want to have to migrate
 *  the model to a different shape / version.
 */
export const createUserAppsSelector = (userIdSelector$: Selector<any, Maybe<string>>) =>
	createSelector(
		userAppsState$,
		userIdSelector$,
		(userAppsState, userId): NormalizedAppsDataForDevices => {
			const rawAppData = (userAppsState[userId || ''] || createUserAppsState()).apps
			const normalizedAppsDataForDevices: DeepWritable<NormalizedAppsDataForDevices> = {}

			forOwn(rawAppData, (rawAppsData, deviceId) => {
				if (!rawAppsData) return

				const normalizedInstalledApps: DeepWritable<NormalizedAppsDataForDevices>[string]['installed_apps'] =
					(normalizedAppsDataForDevices[deviceId] = { ...rawAppsData, installed_apps: {} })
						.installed_apps

				const installedApps = rawAppsData?.installed_apps

				// lodash handles nullish, so we can non-null assert (!) to get correct types.
				forOwn(installedApps!, (rawDeviceApp, rawBundleId) => {
					if (!rawDeviceApp || rawBundleId === SpecialApp.APPLE_FACETIME) return

					const normalizedDeviceApp: Writable<NormalizedDeviceApp> = {
						...rawDeviceApp,
						bundleId: rawBundleId,
						detailsBundleId: rawBundleId,
					}

					if (rawBundleId === SpecialApp.APPLE_CAMERA) {
						normalizedDeviceApp.bundleId = SpecialApp.APPLE_COMBO
						normalizedDeviceApp.title = 'Camera/FaceTime'
					}

					normalizedInstalledApps[normalizedDeviceApp.bundleId] = normalizedDeviceApp
				})
			})

			return normalizedAppsDataForDevices
		},
	)
