import type { ThunkExtra } from '@op/services'
import type { Action, Store, ThunkDispatch, UnknownAction } from '@reduxjs/toolkit'
import type { TypedUseSelectorHook } from 'react-redux'
import { useDispatch, useSelector } from 'react-redux'
import type { RootState } from '../reducers'

export type AppDispatch<A extends Action = UnknownAction> = ThunkDispatch<
	RootState,
	ThunkExtra<RootState>,
	A
>

/**
 * Alias binding `AppState` to `useDispatch`
 * @see RootState
 * @see useDispatch
 */
export const useAppDispatch: () => AppDispatch = useDispatch

export type AppStore<A extends Action = UnknownAction> = Store<RootState, A> & {
	dispatch: AppDispatch<A>
}

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector
