import { useConstant, useFn } from '@eturi/react'
import type { RefCallback, RefObject } from 'react'
import { useCallback, useLayoutEffect, useRef, useState } from 'react'

type UseResizeObserver<T extends HTMLElement> = [
	// This is the ref for the element to observe, and must be set for the hook to work
	callbackRef: RefCallback<T>,
	// This is purposely not mutable, and is only used as convenience to get the current element
	$elRef: RefObject<T>,
	setObserving: (isObserving: boolean) => void,
]

type UseResizeObserverCb = (dims: { readonly height: number; readonly width: number }) => void

export const useResizeObserver = <T extends HTMLElement = HTMLElement>(
	callback: UseResizeObserverCb,
	isObservingInit = true,
): UseResizeObserver<T> => {
	const wrappedCallback = useFn((([entry]) => {
		if (entry.borderBoxSize && entry.borderBoxSize.length) {
			callback({
				height: entry.borderBoxSize[0].blockSize,
				width: entry.borderBoxSize[0].inlineSize,
			})
		} else {
			// Fallback for browsers that don't support borderBoxSize (iOS <15.4)
			const { height, width } = entry.target.getBoundingClientRect()
			callback({ height, width })
		}
	}) satisfies ResizeObserverCallback)
	const resizeObserver = useConstant(() => new ResizeObserver(wrappedCallback))
	const $elRef = useRef<T | null>(null)

	const [isObserving, setObserving] = useState(isObservingInit)

	const callbackRef = useCallback(
		((el) => {
			if ($elRef.current) resizeObserver.unobserve($elRef.current)
			if (isObserving && el) resizeObserver.observe(el, { box: 'border-box' })

			$elRef.current = el
		}) satisfies RefCallback<T>,
		[isObserving],
	)

	useLayoutEffect(() => {
		callbackRef($elRef.current)
	}, [isObserving])

	useLayoutEffect(() => () => resizeObserver.disconnect(), [])

	return [callbackRef, $elRef, setObserving]
}
