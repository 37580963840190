import type { LatLng } from '@op/services'

const { PI, atan2, cos, sin, sqrt } = Math
const EARTH_RADIUS_METERS = 6.371e6
// The threshold we use in meters to see if points are
// close enough to not be considered a new location
export const DEDUPE_LOCATION_RADIUS = 200
export const DEDUPE_GEOFENCE_RADIUS = 300

/**
 * Returns the distance between two points in meters
 * @see http://www.movable-type.co.uk/scripts/latlong.html
 */
export const distanceBetweenLatLng = (p1: Maybe<LatLng>, p2: Maybe<LatLng>) => {
	if (!p1 || !p2) return 0

	const dLat = ((p2.lat - p1.lat) * PI) / 180
	const dLon = ((p2.lng - p1.lng) * PI) / 180
	const a =
		sin(dLat / 2) * sin(dLat / 2) +
		cos((p1.lat * PI) / 180) * cos((p2.lat * PI) / 180) * sin(dLon / 2) * sin(dLon / 2)
	const c = 2 * atan2(sqrt(a), sqrt(1 - a))

	return EARTH_RADIUS_METERS * c
}
