import './AvatarImage.scss'

import { useFn } from '@eturi/react'
import { sentryError } from '@eturi/sentry'
import type { ManagedUser } from '@op/services'
import { fetchUserAvatar, unwrapThunk } from '@op/services'
import cls from 'classnames'
import type { CSSProperties, ReactNode } from 'react'
import { useEffect, useMemo } from 'react'
import { getAvatarCSSStyle } from '../../compound-selectors/avatar'
import { useAppDispatch } from '../../types'

type AvatarImageProps = {
	readonly children?: ReactNode
	readonly className?: string
	readonly colorPreviewIdx?: number
	readonly style?: CSSProperties
	readonly user: ManagedUser
}

export const AvatarImage = ({
	children,
	className,
	colorPreviewIdx,
	style,
	user,
}: AvatarImageProps) => {
	const d = useAppDispatch()
	const { avatar, img_modified, user_name } = user

	const doAvatarFetch = useFn(async () => {
		// Don't fetch if there is no image data
		if (!img_modified) return

		try {
			await unwrapThunk(d(fetchUserAvatar({ userId: user.user_id })))
		} catch (e) {
			sentryError(e, 'Fetch avatar')
		}
	})

	// If the user image is modified then we do a request to retrieve it
	useEffect(() => {
		doAvatarFetch()
	}, [img_modified])

	// Update image when the user image changes
	const AvatarImageStyle = useMemo(
		() => getAvatarCSSStyle(avatar, colorPreviewIdx),
		[avatar, colorPreviewIdx, img_modified],
	)

	const UserFirstInitial = useMemo(
		() => !img_modified && <strong className="avatar__initial">{user_name[0]}</strong>,
		[img_modified, user_name],
	)

	return (
		<figure className={cls('avatar', className)} style={style}>
			<div className="avatar__container" title={user_name}>
				<div className="avatar__image" style={AvatarImageStyle}>
					{UserFirstInitial}
					{children}
				</div>
			</div>
		</figure>
	)
}
