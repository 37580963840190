import { activeChildId$ } from '@op/services'
import { createResolverRedirect } from '../../common'
import { manageLink$ } from '../../compound-selectors/app-misc'
import { resolveSerialHasChildren } from '../../route-resolvers'

// Handles base manage route by resolving children then selecting child
export const resolveBaseManagePage = /*@__PURE__*/ resolveSerialHasChildren((store) => {
	const state = store.getState()
	const activeChildId = activeChildId$(state)
	const manageLink = manageLink$(state)

	// We do this check to make sure we can identify a bad modification to the
	// UserState reducer, which should always give us an `activeChildId` when
	// we have children.
	if (manageLink === '/manage' && activeChildId) {
		throw new Error(`User state has children, but no activeChildId!`)
	}

	// Once we have the active child id or next best child id we reroute to the
	// default manage route
	throw createResolverRedirect(manageLink)
})
