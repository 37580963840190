import { isAuthenticated$ } from '@op/services'
import type { CSSProperties, Ref } from 'react'
import { forwardRef, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { MOBILE_MENU_WIDTH } from '../../constants/sizes'
import { desktopMenuWidthLG, desktopMenuWidthMin, desktopMenuWidthXL } from '../../css-variables'
import { useIsScreenXL } from '../../hooks'
import { NAV_TRANSITION_MS } from './constants'
import { useNav } from './useNav'

type NavSlideBoxProps = JSX.IntrinsicElements['div']

export const NavSlideBoxInner = (
	{ children, className, style, ...p }: NavSlideBoxProps,
	ref: Ref<HTMLDivElement>,
) => {
	const nav = useNav()
	const isScreenXL = useIsScreenXL()
	const isAuth = useSelector(isAuthenticated$)

	const resolvedStyle = useMemo((): CSSProperties | undefined => {
		if (!isAuth) return style

		if (nav.isMobile) {
			return {
				transform: nav.isOpen ? `translate3d(${MOBILE_MENU_WIDTH}px, 0, 1px)` : `translateZ(1px)`,
				transition: `transform ${NAV_TRANSITION_MS}ms linear`,
				...style,
			}
		}

		if (nav.isDesktop) {
			return {
				transition: `padding-left ${NAV_TRANSITION_MS}ms linear`,
				paddingLeft:
					nav.isOpen ?
						isScreenXL ? desktopMenuWidthXL
						:	desktopMenuWidthLG
					:	desktopMenuWidthMin,
				...style,
			}
		}

		return style
	}, [isAuth, isScreenXL, nav, style])

	return (
		<div className={className} ref={ref} style={resolvedStyle} {...p}>
			{children}
		</div>
	)
}

export const NavSlideBox = forwardRef(NavSlideBoxInner)
