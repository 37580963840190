import type { CssGlyphName } from '@op/icons'
import { useIsScreenLG } from '../../hooks'
import { Glyph } from '../../widgets'

type TierFeatureProps = {
	readonly color: string
	readonly icon: CssGlyphName
	readonly subtitle: string
	readonly title: string
}

export const TierFeature = ({ color, icon, subtitle, title }: TierFeatureProps) => {
	const isLgScreen = useIsScreenLG()

	return (
		<div className="flex mb-2 lg:px-6 lg:mb-3">
			<div className="flex-center mr-2 lg:mr-5">
				<Glyph className={color} name={icon} size={isLgScreen ? 'lg' : 'md'} />
			</div>

			<div className="flex-shrink">
				<p className="font-bold uppercase text-xs sm:text-sm md:text-base">{title}</p>
				<p className="leading-tight text-xs md:text-sm md:leading-tight">{subtitle}</p>
			</div>
		</div>
	)
}
