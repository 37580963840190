/**
 * @fileOverview This file is a collection of random stuff that is used in different dynamic imports and chunks.
 * We collect this stuff manually together to reduce the number of requests, and acts as a "common" chunk. This
 * was a functionality provided previously by Webpack, but the Rollup build system simply does not have this
 * feature and its `experimentalMinChunkSize` option is broken and works poorly. While it will combine some
 * chunks, it doesn't create a "common" chunk, and instead puts the smaller chunks in completely random files
 * (like the Chinese translations file, LOL).
 *
 * Here, we manually find small chunks and add their contents.
 *
 * NOTE: This is a temporary solution. Because this is a really stupid way of organizing code, I'm still only
 *  using it for a few things, and this will be removed at some point, when rollup has a better chunking
 *  algorithm and interface.
 */

import { assertNotNullish } from '@eturi/util'
import PAIR_IMG from '@op/assets/connect/pair.svg'
import DISCOVER_LOCATOR from '@op/assets/graphics/discover-locator.png'
import DISCOVER_VEW from '@op/assets/graphics/discover-vew.png'
import OPJ_LOGO from '@op/assets/logos/ourpact-jr.svg'
import PAIR_OP_LOGO from '@op/assets/logos/pair-ourpact-logo.svg'
import {
	account$,
	type AccountSKU,
	activeChildId$,
	effectiveSKU$,
	fetchAppsForUser,
	fetchDevices,
	fetchPaymentSource,
	fetchPurchases,
	fetchUserBlockStatus,
	fetchUserGran,
	fetchUserRules,
	fetchVewInfo,
	nextSKU$,
	unwrapThunk,
	unwrapThunks,
} from '@op/services'
import { createSelector } from '@reduxjs/toolkit'
import moment from 'moment-timezone'
import type { OPResolver } from './common'
import { momentLocale$ } from './reducers/app-misc.slice'
import { fastPollUserBlockStatus } from './reducers/fast-poll.slice'
import { fetchBenefitsAndDetails } from './thunks/benefits'
import type { OPThunk } from './types'

export const appRulesResolver = (async (store) => {
	const userId = activeChildId$(store.getState())

	assertNotNullish(userId, 'activeChildId')

	await Promise.all(
		unwrapThunks([
			store.dispatch(fetchAppsForUser({ userId })),
			store.dispatch(fetchUserGran({ userId })),
			store.dispatch(fetchUserRules({ userId })),
		]),
	)
}) satisfies OPResolver

export const devicesResolver = (async (store) => {
	// We check to make sure that the devices are up-to-date
	await unwrapThunk(store.dispatch(fetchDevices()))
}) satisfies OPResolver

export const groupBenefitResolver = (async (store) => {
	await store.dispatch(fetchBenefitsAndDetails())
}) satisfies OPResolver

/**
 * Increases polling rate and force updates block status. Used when some other
 * change is made that effects block status.
 */
export const fastUpdateBlockStatus =
	(userId: string): OPThunk =>
	(dispatch) => {
		dispatch(fastPollUserBlockStatus(userId))

		// We make an immediate call to fetch block status because even though we have
		// faster polling we want to show intermediate pending states
		dispatch(fetchUserBlockStatus({ force: true, userId }))
	}

export const paymentSourceResolver = (async (store) => {
	await unwrapThunk(store.dispatch(fetchPaymentSource()))
}) satisfies OPResolver

export const purchasesResolver = (async (store) => {
	await unwrapThunk(store.dispatch(fetchPurchases({ nextSKU: nextSKU$(store.getState()) })))
}) satisfies OPResolver

export const vewConfigResolver = (async (store) => {
	// Makes sure we have vew configuration at the account level
	await unwrapThunk(store.dispatch(fetchVewInfo()))
}) satisfies OPResolver

const _accountSKUExpiryString = (sku: AccountSKU) => moment(sku.expiration).format('L')

// NOTE: These have `momentLocale` as a dependency, since they do
//  locale-specific formatting.
export const effectiveSKUExpiry$ = /*@__PURE__*/ createSelector(
	effectiveSKU$,
	momentLocale$,
	_accountSKUExpiryString,
)
export const nextSKUExpiry$ = /*@__PURE__*/ createSelector(
	nextSKU$,
	momentLocale$,
	_accountSKUExpiryString,
)
export const allocationResetDate$ = /*@__PURE__*/ createSelector(
	account$,
	momentLocale$,
	(account) => moment(account?.allocation_reset_ts).format('L'),
)

export const BOTTOM_SHEET_HANDLE_HT = 32

export const popperEvents = (isVisible: boolean) => ({
	name: 'eventListeners',
	options: { resize: isVisible, scroll: isVisible },
})

/**
 * Represents a key used in the GhettoCommons module. The key is a string that starts with the special
 * characters `$$_OP_`, followed by a number or string. All properties of this object must take this form
 * because we use a RegExp to mangle these properties. We can thus make them as descriptive as possible
 * without paying the penalty in bloated file size.
 *
 * We need to export these imports in an object. If we simply re-export them, the build will instead
 * create independent files for each of them, which is what we're trying to avoid.
 *
 * @example
 * const key1 = '$$_OP_42';
 * const key2 = '$$_OP_hello';
 */
type GhettoCommonsKey = `${number | string}_MANGLE$$`
type GhettoCommons<K extends GhettoCommonsKey = GhettoCommonsKey> = {
	readonly [P in K]: any
}

export const commons = {
	DISCOVER_LOCATOR_MANGLE$$: DISCOVER_LOCATOR,
	DISCOVER_VEW_MANGLE$$: DISCOVER_VEW,
	OPJ_LOGO_MANGLE$$: OPJ_LOGO,
	PAIR_IMG_MANGLE$$: PAIR_IMG,
	PAIR_OP_LOGO_MANGLE$$: PAIR_OP_LOGO,
} satisfies GhettoCommons
