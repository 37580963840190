import { assertNotNullish, notEmpty, setIfNotEqual } from '@eturi/util'
import { mapNegativeToInfinity, toParamsURL } from '@op/util'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import mapValues from 'lodash/mapValues'
import { createSliceTransformer } from 'rtk-slice-transformer'
import { resetAction } from '../actions'
import { bindCreateAsyncThunkToState, unwrapThunks } from '../bindCreateAsyncThunkToState'
import type { HttpExtra } from '../http'
import type {
	AccessLevel,
	InitState,
	PaidTier,
	PaymentSource,
	RawSKUDef,
	SKUSource,
	SThunkState,
} from '../types'
import { DEFAULT_ACCESS_LEVEL } from '../types'
import { account$, accountEmail$, fetchAccount, isAccountInit$ } from './account.slice'

export type AccessState = InitState & {
	readonly accountAccess: AccessLevel
	readonly hasAccountMismatch: boolean
	readonly paymentSource: PaymentSource[]
	readonly skuDefs: RawSKUDef[]
	readonly tempTier: Maybe<PaidTier>
}

export type WithAccessState = {
	readonly access: AccessState
}

const initialState: AccessState = {
	accountAccess: DEFAULT_ACCESS_LEVEL,
	hasAccountMismatch: false,
	isInit: false,
	paymentSource: [],
	skuDefs: [],
	tempTier: null,
}

export const accessSlice = /*@__PURE__*/ createSlice({
	name: 'access',
	initialState,
	reducers: {
		// NOTE: Currently only used for testing
		setAccountAccess(s, a: PayloadAction<AccessLevel>) {
			s.accountAccess = a.payload
		},

		setAccountMismatch(s, a: PayloadAction<boolean>) {
			s.hasAccountMismatch = a.payload
		},

		// NOTE: Currently only used for testing
		setSKUDefs(s, a: PayloadAction<RawSKUDef[]>) {
			s.skuDefs = a.payload
		},

		setTempTier(s, a: PayloadAction<Maybe<PaidTier>>) {
			s.tempTier = a.payload
		},
	},
	extraReducers: (builder) =>
		builder
			.addCase(resetAction, () => initialState)
			.addCase(fetchAccessLevel.fulfilled, (s, a) => {
				setIfNotEqual(s, 'accountAccess', a.payload)
				s.isInit = true
			})
			.addCase(fetchSKUDefs.fulfilled, (s, a) => {
				setIfNotEqual(s, 'skuDefs', a.payload)
			})
			.addCase(fetchPaymentSource.fulfilled, (s, a) => {
				s.paymentSource = a.payload
			}),
})

export const { setAccountAccess, setAccountMismatch, setSKUDefs, setTempTier } = accessSlice.actions

export const accessSliceTransformer = /*@__PURE__*/ createSliceTransformer(accessSlice, (s) => ({
	...s,
	// Don't expose payment sources, just map to number of sources
	paymentSource: s.paymentSource.length,
	// Map sku defs to the sku. These are static, so we just need to know
	// that they have them.
	skuDefs: mapValues(s.skuDefs, 'sku'),
}))

////////// Thunks //////////////////////////////////////////////////////////////

export type AccessThunkState = SThunkState & WithAccessState

const createAsyncThunk = /*@__PURE__*/ bindCreateAsyncThunkToState<AccessThunkState>()

export const cancelSub = /*@__PURE__*/ createAsyncThunk(
	'access/cancelSub',
	async (sku: string, { dispatch, extra: { http } }) => {
		await dispatch(http.delete(toParamsURL('/subscription', { sku })))
		await dispatch(fetchAccessState({ force: true })).unwrap()
	},
)

const fetchAccessLevel = /*@__PURE__*/ createAsyncThunk(
	'access/fetch_level',
	async (extra: HttpExtra = {}, { dispatch, extra: { http } }) => {
		const accessLevel = await dispatch(http.get<Maybe<AccessLevel>>('/skus?merged=true', extra))

		assertNotNullish(accessLevel, 'AccessLevel')

		return accessLevel
	},
	{
		condition: (arg, api) => {
			if (!arg?.force && isAccessInit$(api.getState())) return false
		},
	},
)

export const fetchAccessState = /*@__PURE__*/ createAsyncThunk(
	'access/state/fetch',
	async (extra: HttpExtra = {}, { dispatch }) => {
		await Promise.all(
			unwrapThunks([
				dispatch(fetchAccount(extra)),
				dispatch(fetchAccessLevel(extra)),
				dispatch(fetchSKUDefs()),
			]),
		)
	},
)

export const fetchPaymentSource = /*@__PURE__*/ createAsyncThunk(
	'access/paymentSource/fetch',
	async (extra: HttpExtra = {}, { dispatch, extra: { http } }) => {
		const sources = await dispatch(http.get<Maybe<PaymentSource[]>>('/payment_sources', extra))

		assertNotNullish(sources, 'PaymentSource[]')

		return sources
	},
	{
		condition: (arg, api) => {
			if (!arg?.force && paymentSource$(api.getState()) != null) return false
		},
	},
)

export const fetchSKUDefs = /*@__PURE__*/ createAsyncThunk(
	'access/skuDefs/fetch',
	async (extra: HttpExtra = {}, { dispatch, extra: { http } }) => {
		// NOTE: New endpoint (Vew) commented out
		// const res = await dispatch(http.get('/sku_defs', {isUnauthenticated: true}));
		// const {sku_defs} = await res.json();
		const skuDefs = await dispatch(http.get<Maybe<RawSKUDef[]>>('/skus?defs=true', extra))

		assertNotNullish(skuDefs, 'SKUDefs')

		return skuDefs.map(mapNegativeToInfinity)
	},
	{
		condition: (arg, api) => {
			if (!arg?.force && hasSKUDefs$(api.getState())) return false
		},
	},
)

// NOTE: This is an action, not a selector, b/c we need to get the new state
//  as soon as it comes back (it's a check, not a state).
export const isMaxBlocksReached = /*@__PURE__*/ createAsyncThunk(
	'access/isMaxBlocksReached',
	async (_: void, { dispatch, getState }) => {
		try {
			await dispatch(fetchAccessState({ force: 'soft' })).unwrap()

			const state = getState()
			const account = account$(state)
			const { blocks_per_month } = accountAccess$(state)

			if (!(account && isAccountInit$(state)) || !Number.isFinite(blocks_per_month)) return false

			return (account.block_count || 0) >= blocks_per_month
		} catch {
			return false
		}
	},
)

type PurchaseArgs = {
	readonly sku: string
	readonly source?: SKUSource
	readonly token: string
}

const postPurchase = /*@__PURE__*/ createAsyncThunk(
	'access/purchase',
	async (
		{ sku, source = 'WEB_PARENT', token }: Partial<PurchaseArgs>,
		{ dispatch, getState, extra: { http } },
	) => {
		const data = [{ email: accountEmail$(getState()), sku, source, token }]

		// NOTE: we cannot retry on a post purchase because 'token' can only be used
		//  one time regardless of success. If the call fails and we retry,
		//  subsequent calls will fail as Stripe will reject the token
		await dispatch(http.post('/purchase', { data, retry: { shouldRetry: false } }))
		await Promise.all(
			unwrapThunks([
				dispatch(fetchPaymentSource({ force: true })),
				dispatch(fetchAccessState({ force: true })),
			]),
		)
	},
)

export const subscribeToSKU = /*@__PURE__*/ createAsyncThunk(
	'access/subscribeToSKU',
	async (arg: PurchaseArgs, { dispatch }) => {
		await dispatch(postPurchase(arg)).unwrap()
	},
)

export const updatePayment = /*@__PURE__*/ createAsyncThunk(
	'access/updatePayment',
	async (arg: Omit<PurchaseArgs, 'sku'>, { dispatch }) => {
		await dispatch(postPurchase(arg)).unwrap()
	},
)

export const updateSub = /*@__PURE__*/ createAsyncThunk(
	'access/updateSub',
	async (arg: Omit<PurchaseArgs, 'token'>, { dispatch }) => {
		await dispatch(postPurchase(arg)).unwrap()
	},
)

////////// Selectors ///////////////////////////////////////////////////////////

const state$ = <T extends WithAccessState>(s: T) => s.access
const accountAccessRaw$ = /*@__PURE__*/ createSelector(state$, (s) => s.accountAccess)

export const accountAccess$ = /*@__PURE__*/ createSelector(accountAccessRaw$, (a) =>
	mapNegativeToInfinity(a),
)
export const hasAccountMismatch$ = /*@__PURE__*/ createSelector(state$, (s) => s.hasAccountMismatch)
export const paymentSource$ = /*@__PURE__*/ createSelector(
	state$,
	(s): Maybe<PaymentSource> => s.paymentSource[0],
)
export const rawSKUDefs$ = /*@__PURE__*/ createSelector(state$, (s) => s.skuDefs)
export const hasSKUDefs$ = /*@__PURE__*/ createSelector(rawSKUDefs$, notEmpty)
export const hasVewCompatibleSKU$ = /*@__PURE__*/ createSelector(accountAccessRaw$, (s) => s.vew)
export const isAccessInit$ = /*@__PURE__*/ createSelector(state$, (s) => s.isInit)
export const isVewEnabled$ = /*@__PURE__*/ createSelector(accountAccessRaw$, (s) =>
	Boolean(s.vew_active),
)
export const isVewUpgradeAvailable$ = /*@__PURE__*/ createSelector(
	accountAccessRaw$,
	(s) => s.vew_available,
)
export const tempTier$ = /*@__PURE__*/ createSelector(state$, (s) => s.tempTier)
