import {
	canCancelSub$,
	canDowngradeSub$,
	nextSubCycle$,
	type PaidTier,
	resetAction,
	type SubCycle,
} from '@op/services'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import { createSliceTransformer } from 'rtk-slice-transformer'
import { createAsyncThunk } from '../createAsyncThunk'
import type { CycleAndTier, ProductModalType } from '../types'

export type ProductState = {
	readonly isUISelectedAnnual: boolean
	readonly modalType: Maybe<ProductModalType>
	readonly subscribingCycle: Maybe<SubCycle>
	readonly subscribingTier: Maybe<PaidTier>
}

export type WithProductState = {
	readonly product: ProductState
}

const initialState: ProductState = {
	isUISelectedAnnual: false,
	modalType: null,
	subscribingCycle: null,
	subscribingTier: null,
}

export const productSlice = /*@__PURE__*/ createSlice({
	name: 'product',
	initialState,
	reducers: {
		closeProductModal(s) {
			s.modalType = s.subscribingCycle = s.subscribingTier = null
		},

		setSubscribingState(s, { payload: { cycle, tier } }: PayloadAction<CycleAndTier>) {
			s.subscribingCycle = cycle
			s.subscribingTier = tier
		},

		showExternalPaymentModal(s) {
			s.modalType = 'external'
		},

		setUISelectedAnnual(s, a: PayloadAction<boolean>) {
			s.isUISelectedAnnual = a.payload
		},

		showUpdatePaymentModal(s) {
			s.modalType = 'update_pmt'
		},
	},

	extraReducers: (builder) =>
		builder
			.addCase(resetAction, () => initialState)

			.addCase(showCancellationModal.fulfilled, (s) => {
				s.modalType = 'cancel'
			})

			.addCase(showDowngradeModal.fulfilled, (s, { meta: { arg } }) => {
				s.modalType = 'downgrade'
				s.subscribingCycle = arg.cycle
				s.subscribingTier = arg.tier
			})

			.addCase(showPurchaseModal.fulfilled, (s, { meta: { arg } }) => {
				s.modalType = 'purchase'
				s.subscribingCycle = arg.cycle
				s.subscribingTier = arg.tier
			}),
})

export const {
	closeProductModal,
	setSubscribingState,
	setUISelectedAnnual,
	showExternalPaymentModal,
	showUpdatePaymentModal,
} = productSlice.actions

export const productSliceTransformer = /*@__PURE__*/ createSliceTransformer(
	productSlice,
	(s: ProductState) => s,
)

////////// Thunks //////////////////////////////////////////////////////////////

const voidFn = (_: void) => {
	/**/
}

// Just does a pre-check before allowing thunk to fulfill and be handled by
//  extraReducers effects.
export const showCancellationModal = /*@__PURE__*/ createAsyncThunk(
	'product/showCancellationModal',
	voidFn,
	{
		condition: (_, api) => {
			if (!canCancelSub$(api.getState())) return false
		},
	},
)

export const showDowngradeModal = /*@__PURE__*/ createAsyncThunk(
	'product/showDowngradeModal',
	(_: CycleAndTier) => {
		/**/
	},
	{
		condition: (_, api) => {
			if (!canDowngradeSub$(api.getState())) return false
		},
	},
)

export const showPurchaseModal = /*@__PURE__*/ createAsyncThunk(
	'product/showPurchaseModal',
	({ cycle, tier }: WithOptional<CycleAndTier, 'cycle'>, { getState }) => ({
		cycle: cycle || nextSubCycle$(getState()),
		tier,
	}),
)

////////// Selectors ///////////////////////////////////////////////////////////

const state$ = <T extends WithProductState>(s: T) => s.product

export const productModal$ = /*@__PURE__*/ createSelector(state$, (s) => s.modalType)
export const isUISelectedAnnual$ = /*@__PURE__*/ createSelector(state$, (s) => s.isUISelectedAnnual)
export const subscribingCycle$ = /*@__PURE__*/ createSelector(state$, (s) => s.subscribingCycle)
export const subscribingTier$ = /*@__PURE__*/ createSelector(state$, (s) => s.subscribingTier)
export const hasSubscribingTier$ = /*@__PURE__*/ createSelector(subscribingTier$, Boolean)
export const isSubscribingCycleAnnual$ = /*@__PURE__*/ createSelector(
	subscribingCycle$,
	(s) => s === 'annual',
)

export const isProductModalVisible$ = /*@__PURE__*/ createSelector(productModal$, Boolean)
