import { useRefGetter, useTimeout } from '@eturi/react'
import { useDebounceDisabled } from '@op/app-shared'
import { useKeyboardClick } from '@op/react-web'
import cls from 'classnames'
import noop from 'lodash/noop'
import type { ButtonHTMLAttributes } from 'react'
import { useEffect } from 'react'
import { createStyles, useStyle } from '../../styles/createStyles'
import type { TestIDValue } from '../../TestID'

type SwitchProps = ButtonHTMLAttributes<HTMLButtonElement> & {
	readonly 'data-testid'?: TestIDValue
	readonly enabled: boolean
	onToggle(enabled: boolean): void
}

export const Switch = ({
	children,
	className,
	disabled = false,
	enabled,
	onToggle,
	style,
	title,
	...p
}: SwitchProps) => {
	const [isReady, setReady] = useRefGetter(false)
	const [setReadyTimeout] = useTimeout()
	const isStyleDisabled = useDebounceDisabled(disabled)

	const handleKeyboardToggle = useKeyboardClick((ev) => {
		ev.stopPropagation()
		!disabled && onToggle(!enabled)
	})

	// NOTE: We use this method, rather than `useTimeoutReady`, because we don't need
	//  to trigger a render when the timeout is ready. We need to prevent the toggle from
	//  transitioning when it first loads, and adding the transition can wait until the
	//  next time the toggle renders (e.g. when it is toggled).
	useEffect(() => {
		setReadyTimeout(() => setReady(true), TRANSITION_MS)
	}, [])

	const outerStyle = useStyle(() => [styles.outer, style], [style])

	const toggleStyle = useStyle(
		() => [styles.toggle, enabled && styles.toggleEnabled, isReady() && styles.toggleReady],
		[enabled, isReady()],
	)

	const btnStateProps: ButtonHTMLAttributes<HTMLButtonElement> = {
		...handleKeyboardToggle,
		className: cls('st rounded-full', enabled ? 'bg-[#ABE9F1]' : 'bg-[#7D7D7D]', className),
	}

	if (disabled) {
		btnStateProps['aria-disabled'] = true
		btnStateProps.tabIndex = -1
		btnStateProps.onClick = p.onKeyDown = p.onKeyUp = noop
		btnStateProps.className += ' !pointer-events-none'
	}

	if (isStyleDisabled) {
		btnStateProps.className += ' disabled'
	}

	return (
		<button {...btnStateProps} style={outerStyle} title={title} type="button" {...p}>
			<span className="flex h-6 w-12">
				<span
					className={cls('stt h-full w-1/2 rounded-full', enabled ? 'bg-teal' : 'bg-[#CACACA]')}
					style={toggleStyle}
				/>
				{children}
			</span>
		</button>
	)
}

const TRANSITION_MS = 300
const styles = createStyles({
	outer: { padding: 3 },
	toggle: { willChange: 'transform' },
	toggleEnabled: { transform: 'translateX(100%)' },
	toggleReady: { transition: `transform ${TRANSITION_MS}ms` },
})
