import {
	IOS_DEBOUNCE_SCROLL,
	PASSIVE_CAPTURE,
	useDebounce,
	useFn,
	useWindowEvent,
} from '@eturi/react'
import { loadImg } from '@op/react-web'
import { useLayoutEffect, useRef, useState } from 'react'
import { getBgContainSize } from '../../util/getBgContainSize'
import type { SimpleDims } from './types'

/** Calculates the image dimensions based on the source when using carousel */
export const useCarouselImgDims = (url?: string) => {
	const [dims, setDims] = useState<SimpleDims | undefined>(undefined)
	const [img, setImg] = useState<HTMLImageElement | null>(null)
	const activeImgPromiseUrl = useRef(url)

	// Fetch the new image when url changes, then set the dimensions
	const fetchImg = useFn(async () => {
		if (!url) {
			setImg(null)
			setDims(undefined)

			return
		}

		// Set the image to null before we fetch the new one, so we don't end up using the wrong
		// image if resize happens.
		setImg(null)

		// Create a reference to the url we're loading. If the url changes while
		// we're fetching, we shouldn't set anything b/c we'll be setting dims for
		// the wrong image.
		activeImgPromiseUrl.current = url

		try {
			const img = await loadImg(url)

			if (activeImgPromiseUrl.current !== url) return

			setImg(img)
			setDims(getBgContainSize(img, window.innerHeight, window.innerWidth))
		} catch (e) {
			console.error(e)
		}
	})

	useWindowEvent(
		'resize',
		useDebounce(() => {
			// On resize, we don't need to create a new img. Just recalculate based on the current.
			if (img) setDims(getBgContainSize(img, window.innerHeight, window.innerWidth))
		}, IOS_DEBOUNCE_SCROLL),
		PASSIVE_CAPTURE,
	)

	useLayoutEffect(() => {
		fetchImg()
	}, [url])

	return dims
}
