import cls from 'classnames'
import type { ReactNode, Ref } from 'react'
import { forwardRef } from 'react'
import { useLocation } from 'react-router-dom'
import { useNavTo } from '../hooks'

type OPLinkProps = MOmit<JSX.IntrinsicElements['a'], 'children' | 'href'> & {
	readonly activeClassName?: string
	readonly children?: ReactNode | ((active: boolean, external: boolean) => ReactNode)
	readonly 'data-testid'?: string
	readonly exact?: boolean
	readonly replace?: boolean
	readonly to: string
}

const OPLinkInner = (
	{
		activeClassName = 'active',
		children,
		className,
		exact = false,
		onClick,
		replace = false,
		to,
		...p
	}: OPLinkProps,
	ref: Ref<HTMLAnchorElement>,
) => {
	const { pathname } = useLocation()
	const navTo = useNavTo()

	// We use very simple logic here, unlike React Router. If the match should be
	// exact, we use strict equality, otherwise, the route matches if the pathname
	// starts with the link
	const isActive = exact ? pathname === to : pathname.startsWith(to)
	// All router links need to start with '/', so, any that don't are external
	const isExternal = !to.startsWith('/')
	// Add some extra props by default to external links
	const extra = isExternal ? EXTERNAL_EXTRA : undefined

	return (
		<a
			className={cls(className, isActive && activeClassName)}
			href={to}
			onClick={(ev) => {
				onClick?.(ev)

				// If onClick handler already prevented default
				// If the link is external, we also return, thus following default behavior
				if (ev.defaultPrevented || isExternal) return

				// If we have a route link, prevent default
				ev.preventDefault()

				// If the link path and current path are the same, return without navigating
				if (ev.currentTarget?.pathname === pathname) return

				// Otherwise, perform history navigation
				navTo(to, replace)
			}}
			ref={ref}
			{...extra}
			{...p}
		>
			{typeof children === 'function' ? children(isActive, isExternal) : children}
		</a>
	)
}

export const OPLink = forwardRef(OPLinkInner)

const EXTERNAL_EXTRA = { rel: 'noreferrer', target: '_blank' } satisfies JSX.IntrinsicElements['a']
