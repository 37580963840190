import { hasChildren$ } from '@op/services'
import { createResolverRedirect } from '../common'
import { resolveSerialRootAuth } from './resolveSerialRootAuth'
import { usersResolver } from './usersResolver'

export const resolveSerialHasChildren = /*@__PURE__*/ resolveSerialRootAuth.bind(
	null,
	usersResolver,
	(store) => {
		if (!hasChildren$(store.getState())) {
			throw createResolverRedirect('/add-child')
		}
	},
)
