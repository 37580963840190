import type { CssGlyphName } from '@op/icons'
import cls from 'classnames'
import type { CSSProperties } from 'react'

type GlyphSize = 'sm' | 'md' | 'lg' | 'xl' | '2xl'

type GlyphProps = {
	readonly className?: string
	readonly name: CssGlyphName
	// If size is specifically `null`, we don't add the size class, and so allow
	// manual overriding of sizes.
	readonly size?: Maybe<GlyphSize>
	readonly style?: CSSProperties
}

const SizeMap = {
	sm: 'icon-sm',
	md: 'icon-md',
	lg: 'icon-lg',
	xl: 'icon-xl',
	'2xl': 'icon-2xl',
} satisfies { [P in GlyphSize]: string }

export const Glyph = ({ className, name, size = 'md', style }: GlyphProps) => (
	<i className={cls(`pakicon-${name}`, size != null && SizeMap[size], className)} style={style} />
)
