import { useBoolState, useFn, useMaybeState } from '@eturi/react'
import { useMemo, useRef, useState } from 'react'
import { Btn, LoadComponent, Switch } from '../../widgets'
import type { BottomSheetInstance, SnapPoint } from '../../widgets/BottomSheet'
import { BottomSheet } from '../../widgets/BottomSheet/lazy'

export const DemoBottomSheetView = () => {
	const SELECTABLE_SNAP_POINTS = useMemo(
		(): SnapPoint[][] => [
			[36, 250, '80%'],
			['25%', '50%', '100%'],
		],
		[],
	)

	const bottomSheetRef = useRef<BottomSheetInstance>(null)
	const [contentIdx, setContentIdx] = useState(0)
	const [hasBackdrop, setBackdrop] = useState(false)
	const [isSheetVisible, showSheet, hideSheet] = useBoolState(true)
	const [panDownToClose, setPanDownToClose] = useState(true)
	const [snapPoints, setSnapPoints, clearSnapPoints] = useMaybeState<SnapPoint[]>(
		SELECTABLE_SNAP_POINTS[0],
	)
	const [snapIdx, setSnapIdx] = useState(1)
	const [showOverflow, setOverflow] = useState(false)

	const handleToggleContent = useFn(() => {
		setContentIdx((contentIdx + 1) % BottomSheetContent.length)
	})

	const Settings = (
		<>
			<h4 className="font-bold mb-3">Options</h4>

			<div className="flex flex-wrap gap-x-8 gap-y-3">
				<label className="flex items-center">
					Backdrop:
					<Switch className="ml-4" enabled={hasBackdrop} onToggle={setBackdrop} />
				</label>

				<label className="flex items-center">
					Pan Down to Close:
					<Switch className="ml-4" enabled={panDownToClose} onToggle={setPanDownToClose} />
				</label>

				<label className="flex items-center">
					Show Overflow:
					<Switch className="ml-4" enabled={showOverflow} onToggle={setOverflow} />
				</label>

				<label className="flex items-center">
					Snap Points:
					<select
						className="select ml-4"
						onChange={(ev) => {
							const value = Number.parseInt(ev.currentTarget.value)
							const nextSnapPoints = SELECTABLE_SNAP_POINTS[value]

							if (nextSnapPoints == null) clearSnapPoints()
							else setSnapPoints(nextSnapPoints)
						}}
						value={SELECTABLE_SNAP_POINTS.indexOf(snapPoints as any)}
					>
						<option value={-1}>Disabled</option>
						{SELECTABLE_SNAP_POINTS.map((s, i) => (
							<option key={i} value={i}>
								{s.join(', ')}
							</option>
						))}
					</select>
				</label>

				{snapPoints && (
					<label className="flex items-center">
						Snap Point:
						<select
							className="select ml-4"
							onChange={(ev) => {
								const value = Number.parseInt(ev.currentTarget.value)
								bottomSheetRef.current?.snapToIdx(value)
							}}
							value={snapIdx}
						>
							{snapPoints.map((v, i) => (
								<option key={v} value={i}>
									{v}
								</option>
							))}
						</select>
					</label>
				)}

				<Btn onClick={handleToggleContent}>Toggle Content</Btn>
			</div>
		</>
	)

	const BottomSheetContent = [
		// eslint-disable-next-line react/jsx-key
		<div className="p-5 pt-0">
			{Settings}
			<h4 className="font-bold my-5">Some Content</h4>
			<p className="mb-5">
				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus sollicitudin accumsan
				consequat. Integer sit amet ultrices justo. Sed sagittis ipsum lectus, et finibus eros
				posuere a. Nam et massa sit amet eros sollicitudin vehicula sed sit amet tortor. Nunc
				molestie erat risus, sodales pellentesque sapien pulvinar vel. Integer sit amet malesuada
				sem. Vivamus semper fringilla rhoncus. Aliquam in massa ut purus congue lacinia eu ac est.
				Etiam pulvinar malesuada nisl, non hendrerit nunc scelerisque et. Nunc posuere nisi
				lobortis, facilisis ante vitae, pretium ex. Sed tincidunt arcu sed ante ornare mattis.
				Aliquam eget orci non elit mollis pulvinar. Nam a quam lacinia, auctor libero fringilla,
				interdum ex. Sed efficitur vel sapien vitae ornare.
			</p>

			<p>
				Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis
				egestas. Nulla facilisi. Sed porttitor blandit vehicula. Proin et orci tincidunt, auctor
				tortor sed, mattis urna. Donec sed nunc vel orci faucibus mollis. Mauris maximus ante at
				eros semper vulputate. Praesent scelerisque lacinia accumsan. Proin pulvinar velit ac
				viverra efficitur. Nunc sit amet eros sed arcu euismod condimentum in vitae dolor. Praesent
				congue dignissim suscipit.
			</p>
		</div>,

		// eslint-disable-next-line react/jsx-key
		<div>
			<div className="p-5 pt-0">{Settings}</div>
			<div className="flex justify-evenly flex-wrap">
				<div className="bg-blue h-48 w-48 max-w-1/3" />
				<div className="bg-red h-48 w-48 max-w-1/3" />
				<div className="bg-orange h-48 w-48 max-w-1/3" />
				<div className="bg-yellow h-48 w-48 max-w-1/3" />
				<div className="bg-green h-48 w-48 max-w-1/3" />
				<div className="bg-teal h-48 w-48 max-w-1/3" />
			</div>
		</div>,

		// eslint-disable-next-line react/jsx-key
		<div className="p-5 pt-0">
			{Settings}
			<h4 className="font-bold my-5">Lots of content (overflow)</h4>
			<p className="mb-5">
				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras tincidunt sed lectus aliquam
				sodales. Nulla lectus mi, ultricies nec sapien vel, ullamcorper viverra arcu. Quisque nec
				nunc velit. Nulla congue dui eget mauris fermentum varius. Vivamus sit amet nunc sit amet
				arcu gravida lobortis. Proin ullamcorper enim vel nibh congue, nec dignissim leo sagittis.
				Phasellus id magna malesuada, finibus turpis quis, lacinia dui. Nulla aliquet scelerisque
				rutrum.
			</p>

			<p className="mb-5">
				Quisque nisi orci, tristique sit amet elit sit amet, fringilla dictum purus. Suspendisse
				posuere erat pulvinar ligula hendrerit, in egestas lacus convallis. Proin non felis
				condimentum tellus egestas vulputate. Maecenas diam sem, consectetur rutrum sagittis sed,
				ultricies sed quam. Etiam mattis lorem id enim sollicitudin, non tristique est vehicula.
				Etiam a turpis tellus. Nunc urna dui, tristique vitae lectus non, interdum imperdiet urna.
				Sed rutrum ante ornare leo egestas, id ultrices odio ornare. Mauris pharetra blandit libero
				in dictum. Proin magna ex, molestie non lobortis venenatis, semper id ex. Nullam arcu est,
				tincidunt ut leo nec, imperdiet efficitur enim. Integer tincidunt dolor sed arcu aliquam, ut
				vestibulum ligula maximus. Mauris semper pulvinar ligula quis tincidunt.
			</p>

			<p className="mb-5">
				Vivamus ornare at arcu id volutpat. Morbi euismod risus eget velit laoreet, sit amet
				faucibus eros eleifend. In aliquet augue risus, ac commodo risus semper sit amet. Phasellus
				aliquam a libero id interdum. Sed congue consectetur augue. Vestibulum ante ipsum primis in
				faucibus orci luctus et ultrices posuere cubilia curae; Cras et pharetra felis. Sed
				consectetur, nibh non finibus semper, nibh urna suscipit lorem, eu porttitor purus quam ut
				felis. Mauris finibus lectus leo, nec eleifend lacus facilisis vel. Nullam aliquet dapibus
				pharetra. Aenean fringilla augue neque, nec blandit felis bibendum ut. Nam eu sollicitudin
				diam, id accumsan sem. Proin faucibus ex ut tortor laoreet consequat.
			</p>

			<p>
				Aenean tempor velit odio, sit amet sodales nibh consectetur eget. Cras eu ultricies lacus.
				Maecenas tempus dignissim ligula interdum porttitor. Nunc suscipit ex quis tellus ultrices
				pharetra. Nam id metus sit amet lacus mattis suscipit. Fusce nisi odio, volutpat in aliquam
				in, lacinia non elit. Sed ac blandit ante, sed blandit risus. Morbi sed tortor rutrum sapien
				aliquam rutrum a eget nisl. Fusce libero nisi, vehicula non sollicitudin vel, pulvinar eget
				nisl. Vivamus in iaculis enim. Nullam vulputate blandit turpis. Suspendisse placerat, orci
				vel auctor laoreet, odio sem convallis lectus, a sollicitudin erat risus a tellus. Sed
				placerat, dolor sit amet iaculis pretium, arcu risus interdum lorem, eu sodales nunc neque
				ac justo. Vivamus at fermentum lacus. In hac habitasse platea dictumst.
			</p>
		</div>,
	]

	const Content = BottomSheetContent[contentIdx]

	// NOTE: Snap points aren't meant to be changed, so we force a re-render when toggling these.
	const bottomSheetKey = snapPoints?.join('') || 0

	return (
		<div className="is-page-pad-touch">
			<h2 className="mb-5 text-3xl text-center">Bottom Sheet</h2>
			{!isSheetVisible && (
				<div className="flex-center">
					<Btn fat onClick={showSheet}>
						Open
					</Btn>
				</div>
			)}

			{isSheetVisible && (
				<LoadComponent>
					<BottomSheet
						key={bottomSheetKey}
						ref={bottomSheetRef}
						backdrop={hasBackdrop}
						index={snapIdx}
						onChange={setSnapIdx}
						onClose={hideSheet}
						panDownToClose={panDownToClose}
						showOverflow={showOverflow}
						snapPoints={snapPoints || undefined}
					>
						{Content}
					</BottomSheet>
				</LoadComponent>
			)}
		</div>
	)
}
