import { durationAs } from '@eturi/date-util'
import { omit } from '@eturi/util'
import type { AllowanceState } from './Allowance'

export type UsageAppliedState = 'all' | 'none' | 'some' | 'unknown'
export type UsageState = 'expired' | 'pause' | 'play'
export type UsageDisplayState = 'blocked' | 'expired' | 'none' | 'paused' | 'playing'

export type RawUsageDeviceState = {
	readonly state: AllowanceState
	readonly state_ts: number
}

export type RawUserDeviceStates = {
	readonly [deviceId: string]: RawUsageDeviceState
}

export type RawUsage = {
	readonly applied_state: UsageAppliedState
	readonly devices_in_use: Maybe<string[]>
	readonly device_state: RawUserDeviceStates
	readonly request_ts: Maybe<number>
	readonly requested_state: Maybe<UsageState>
	readonly time_limit: Maybe<number>
	readonly time_remaining: Maybe<number>
	readonly time_used: Maybe<number>
	readonly usage_date: Maybe<string>
	readonly user_id: string
}

const PRUNED_RAW_USAGE_KEYS = [
	'device_state',
	'request_ts',
	'usage_date',
] satisfies (keyof RawUsage)[]
export type PrunedRawUsage = Omit<RawUsage, (typeof PRUNED_RAW_USAGE_KEYS)[number]>

export type Usage = PrunedRawUsage & {
	readonly devices_in_use: string[]
	readonly displayState: UsageDisplayState
	readonly time_limit: number
	readonly time_remaining: number
	readonly time_used: number
}

export const mapRawToUsage = (
	raw: RawUsage,
	isAllowOverride: boolean,
	isAllowanceBlocked: boolean,
): Usage => {
	const time_limit = raw.time_limit || 0
	const time_remaining = Math.max(0, raw.time_remaining || 0)
	// iOS Child doesn't always automatically set allowance to 'paused' when limit is reached. This
	// results in `time_used > time_limit`, which screws up calculations. Hopefully the child clients
	// and / or server fix this.
	const time_used = Math.min(time_limit, raw.time_used || 0)

	return {
		...omit(raw, PRUNED_RAW_USAGE_KEYS),
		devices_in_use: raw.devices_in_use || [],
		displayState: _getUsageDisplayState(raw, isAllowOverride, isAllowanceBlocked),
		time_limit,
		time_remaining,
		time_used,
	}
}

const _getUsageDisplayState = (
	raw: RawUsage,
	isAllowOverride: boolean,
	isAllowanceBlocked: boolean,
): UsageDisplayState => {
	// Allowance overrides other states
	if (isAllowanceBlocked) return 'blocked'

	if (raw.time_remaining === 0) return 'expired'

	const requested = raw.requested_state

	if (isAllowOverride) {
		return requested === 'expired' ? requested : 'paused'
	}

	const applied = raw.applied_state

	// We're optimistic about play states
	if (requested === 'play') {
		return applied === 'all' || applied === 'some' ? 'playing' : 'paused'
	}

	// We're pessimistic about pause states
	if (requested === 'pause') {
		return applied === 'all' ? 'paused' : 'playing'
	}

	return 'none'
}

const ALL_DAY_SECS = durationAs(1, 'd', 's')

export const canShowUsageRemaining = (u: Maybe<Usage>) =>
	(u?.time_limit ?? ALL_DAY_SECS) < ALL_DAY_SECS
