import { useFn } from '@eturi/react'
import { useT } from '@eturi/react-i18next'
import { useHandleSynStop } from '@op/react-web'
import cls from 'classnames'
import noop from 'lodash/noop'
import type { ReactNode } from 'react'
import { useEffect } from 'react'
import { LineClamp } from '../../common'
import { TestID } from '../../TestID'
import { Btn } from '../Buttons'

export type ToastType = 'confirm' | 'error' | 'info'

export type ToastProps = {
	readonly maxLines?: number
	readonly msg: string
	readonly persistent?: boolean
	readonly type?: ToastType
	close(): void
	onClose?(): void
}

const ToastTestIds = {
	confirm: TestID.TOAST_CONFIRM_TID$$,
	error: TestID.TOAST_ERROR_TID$$,
	info: TestID.TOAST_INFO_TID$$,
} satisfies { readonly [K in ToastType]: string }

export const Toast = ({
	children,
	close,
	maxLines = 3,
	msg,
	onClose = noop,
	persistent = false,
	type = 'error',
}: ToastProps & { readonly children?: ReactNode }) => {
	const handleClose = useFn(() => !persistent && close())

	// Trigger close when unmounted
	useEffect(
		() => () => {
			onClose()
		},
		[],
	)

	return (
		<div
			className={cls('toast-content', type === 'error' && 'error')}
			data-testid={ToastTestIds[type]}
			onClick={handleClose}
			title={msg}
		>
			<LineClamp maxLines={maxLines} text={msg} />
			{children}
		</div>
	)
}

export type ConfirmToastProps = Omit<ToastProps, 'type'> & {
	onCancel(): void
	onConfirm(): void
}

export const ConfirmToast = ({ onCancel, onConfirm, ...p }: ConfirmToastProps) => {
	const [t] = useT()

	const handleCancel = useHandleSynStop(() => {
		onCancel()
		p.close()
	})

	const handleConfirm = useHandleSynStop(() => {
		onConfirm()
		p.close()
	})

	return (
		<Toast {...p} close={noop} type="confirm">
			<div className="form-actions-horizontal">
				<Btn data-testid={TestID.BTN_TOAST_CANCEL_TID$$} onClick={handleCancel} autoFocus={true}>
					{t('actions.cancel')}
				</Btn>

				<Btn
					className="border border-solid border-red"
					color="red"
					data-testid={TestID.BTN_TOAST_CONFIRM_TID$$}
					invert
					onClick={handleConfirm}
				>
					{t('actions.delete')}
				</Btn>
			</div>
		</Toast>
	)
}
