import type { TierLevel } from '@op/services'
import {
	benefitGroup$,
	benefitGroupId$,
	isStripeCouponBenefit,
	nextSKU$,
	redeemedBenefit$,
	skuDefs$,
} from '@op/services'
import { createSelector } from '@reduxjs/toolkit'
import CSC_LOGO from '../../../assets/logos/csc-logo.png'
import SCREEN_SANITY_LOGO from '../../../assets/logos/screen-sanity-logo.png'

type AffiliateInfo = {
	readonly logo: Maybe<string>
	readonly name: string
	readonly quote: Maybe<string>
	readonly title: string
}

const AFFILIATE_INFO_MAP: Record<string, AffiliateInfo> = {
	cybersafetycop: {
		logo: CSC_LOGO,
		name: 'Clayton Cranford, Cyber Safety Cop',
		quote: `The most important thing a parent can do is install OurPact on their child's device.`,
		title: 'Cyber Safety Cop',
	},
	fragosomelissa: {
		logo: null,
		name: 'Melissa Fragoso',
		quote:
			'OurPact is the app that every single parent needs if their kid has a device. I can’t say enough good things about it.',
		title: '@fragosomelissa',
	},
	jessimeeks: {
		logo: null,
		name: 'Jessi Meeks',
		quote: 'OurPact is the peace of mind we all need when it comes to screen time and our kids!',
		title: '@jessimeeks',
	},
	kendraworth: {
		logo: null,
		name: 'Kendra Worth',
		quote:
			'OurPact has been an incredible way to allow my kids freedom to explore technology while keeping them safe and limiting their screen time!',
		title: 'Kendra Worth',
	},
	parentcoach: {
		logo: null,
		name: 'Therese Connolly',
		quote: null,
		title: '@parentcoach.tweensteens',
	},
	screensanity: {
		logo: SCREEN_SANITY_LOGO,
		name: 'Screen Sanity',
		quote:
			'OurPact and Screen Sanity are united in the mission of empowering parents to raise healthy, happy kids by balancing technology use and fostering a life where children are captivated by life, not screens.',
		title: 'Screen Sanity',
	},
	tianasharifi: {
		logo: null,
		name: 'Tiana Sharifi',
		quote: 'OurPact is one of my favorite parenting tools. They’ve thought about everything!',
		title: '@tianasharifi',
	},
	thevarneyfamily: {
		logo: null,
		name: 'The Varney Family',
		quote:
			"OurPact gives us peace of mind knowing we're building healthy relationships with screens!",
		title: 'The Varney Family',
	},
}

export const affiliateInfo$ = /*@__PURE__*/ createSelector(benefitGroupId$, (groupId) =>
	groupId ? AFFILIATE_INFO_MAP[groupId] : null,
)

export const isAffiliate$ = /*@__PURE__*/ createSelector(benefitGroupId$, (groupId) =>
	Boolean(groupId && AFFILIATE_INFO_MAP[groupId]),
)

export const isPaidAffiliate$ = /*@__PURE__*/ createSelector(
	benefitGroup$,
	nextSKU$,
	redeemedBenefit$,
	(benefitGroup, nextSku, redeemedBenefit) => {
		// If they don't have a benefit or the redeemed benefit is not the benefit group
		if (
			!(benefitGroup && redeemedBenefit) ||
			benefitGroup.group_id !== redeemedBenefit.account.coupon_group_id
		)
			return false

		// They're paid affiliate if they've purchased the sku associated with this group
		return Boolean(
			AFFILIATE_INFO_MAP[redeemedBenefit.account?.coupon_group_id] &&
				isStripeCouponBenefit(redeemedBenefit) &&
				redeemedBenefit.details.limit_skus?.includes(nextSku.sku),
		)
	},
)

// Get the tiers that the affiliate group has access to
export const affiliateTiers$ = /*@__PURE__*/ createSelector(
	benefitGroup$,
	isAffiliate$,
	skuDefs$,
	(benefitGroup, isAffiliate, skuDef): string[] => {
		if (!(isAffiliate && benefitGroup?.coupon_benefits)) return []

		const tiers: TierLevel[] = []

		for (const group_sku of benefitGroup.coupon_benefits.limit_skus) {
			const tier = skuDef.find((sku) => sku.sku === group_sku)?.tier
			// If the tier is already included then don't add it. This happens
			// because prem/prem_plus(tier) monthly and annual are different skus
			tier && !tiers.includes(tier) && tiers.push(tier)
		}

		return tiers
	},
)
