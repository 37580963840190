import { lazy, memo } from 'react'
import { LoadPage } from '../../widgets'
import { MyFamilyNavBar } from './MyFamilyNavBar'

const MyFamilyContent = /*@__PURE__*/ lazy(() => import('./MyFamilyPageContent'))

const MyFamilyPage = () => (
	<section className="is-page-pad-touch page">
		<MyFamilyNavBar />

		<LoadPage>
			<MyFamilyContent />
		</LoadPage>
	</section>
)

export default /*@__PURE__*/ memo(MyFamilyPage)
