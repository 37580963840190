import { useFn } from '@eturi/react'
import type { History } from 'history'
import { useHistory } from 'react-router-dom'

export const useNavTo = () => {
	const history = useHistory()

	return useFn(<T>(to: string | History.LocationDescriptor, replace?: boolean, state?: T) => {
		const normalizedState = { ...state, from: history.location.pathname }

		replace ? history.replace(to, normalizedState) : history.push(to, normalizedState)
	})
}
