import type { Lang } from '@op/services'
import { SUPPORTED_LANG_LIST } from '@op/services'
import i18next from 'i18next'
import { LangBackend } from './LangBackend'
import { LangDetector } from './LangDetector'

const fallbackLng: Lang = 'en'

i18next
	.use(LangBackend)
	.use(LangDetector)
	.init({
		// Don't use Intl.PluralRules or the new JSON plural format conventions
		// for now.
		compatibilityJSON: 'v3',
		debug: process.env.APP_ENV === 'dev',
		// NOTE: i18next v21+ doesn't support empty string ('') namespace. Someone
		//  probably changed a logic check from `if (ns)` to `if (ns === undefined)`
		//  or similar. We don't currently use namespaces, and our LangBackend
		//  ignores this anyways. In the future I may split the translation JSON
		//  into different namespaces using the Lokalise CLI. This would require
		//  reorganising the translations into logical top-level sections, e.g.
		//  "common", "unauthorized", "manage", "devices", etc. For the purposes
		//  of translation maintenance, the CLI would combine these files back into
		//  a single file for server-sync.
		defaultNS: 'DEFAULT',
		fallbackLng,
		keySeparator: '.',
		load: 'currentOnly',
		lowerCaseLng: true,
		ns: 'DEFAULT',
		supportedLngs: SUPPORTED_LANG_LIST,
		interpolation: {
			defaultVariables: {
				color: 'inherit',
			},
			escapeValue: false,
		},
		react: { transKeepBasicHtmlNodesFor: ['i', 'strong', 'text'] },
	})

export default i18next
