import type { ButtonTags } from '../../types'
import type { ActionBtnProps } from './ActionBtn'
import { getActionBtnProps } from './ActionBtn'
import { useBtnLoader } from './useBtnLoader'

type ActionBtnLoaderProps<T extends ButtonTags = 'button'> = ActionBtnProps<T> & {
	readonly isLoading: boolean
}

export const LoadingActionBtn = <T extends ButtonTags = 'button'>(p: ActionBtnLoaderProps<T>) => {
	const [baseBtnCls, content, Wrapper, type, { isLoading, ...restProps }] = getActionBtnProps(p)
	const [btnCls, BtnChildLoader] = useBtnLoader(isLoading, baseBtnCls)

	return (
		<Wrapper {...restProps} className={btnCls} type={type}>
			<BtnChildLoader>{content}</BtnChildLoader>
		</Wrapper>
	)
}
