import './ConnectList.scss'

import cls from 'classnames'
import type { ReactNode } from 'react'

type ConnectListProps = {
	readonly children?: ReactNode
	readonly className?: string
}

export const ConnectList = ({ children, className }: ConnectListProps) => (
	<ol className={cls('list-none', className)}>{children}</ol>
)

type ConnectListItemProps = {
	readonly children?: ReactNode
	readonly className?: string
}

const ConnectListItem = ({ children, className }: ConnectListItemProps) => (
	<li className={cls('connect-list-item flex items-center mb-6 pl-10 relative', className)}>
		{children}
	</li>
)

ConnectList.Item = ConnectListItem
