import { useT } from '@eturi/react-i18next'
import { useHandleSynStop } from '@op/react-web'
import type { PremTier } from '@op/services'
import { TestID } from '../../TestID'
import { Btn } from '../../widgets'

type SeeMoreBtnProps = {
	readonly tier: PremTier
	onClick(tier: PremTier): void
}

export const SeeMoreBtn = ({ onClick, tier }: SeeMoreBtnProps) => {
	const [t] = useT()
	const handleClick = useHandleSynStop(() => {
		onClick(tier)
	})

	return (
		<Btn
			data-testid={TestID.BTN_SUBSCRIPTION_SEE_MORE_TID$$}
			className="!normal-case mx-auto -mt-1 -mb-1.5 !min-w-3/4"
			invert
			onClick={handleClick}
			size="sm"
		>
			{t('actions.see_more')}
		</Btn>
	)
}
